import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    chart: {},
    error: '',
  };

  handleBack = () => {
    this.props.next(10);
  };

  handleNext = () => {
    this.props.next(12);
  };

  graphic = (id, img) => {
    if (this.props.graphic) {
      this.props.graphic(id, img);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo2/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        let labels = [];
        let data = [];
        response.data.data.data.forEach((element) => {
          labels.push(element.role);
          data.push(element.score);
        });
        let series = [];
        series.push({ name: '', data: data });
        this.setState(
          {
            chart: { labels, series, id: 'M2chart1' },
          },
          () => {
            this.props.load();
          },
        );
      } else {
        this.setState(
          {
            error: response.data.error,
          },
          () => {
            this.props.load();
          },
        );
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>2. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Eco System Radar Map
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the intensity to fix the root problem
                  </p>
                  <RadarChart val={this.state.chart} graphic={this.graphic} />
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
