import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question7Enterprise extends Component {
  state = {
    edit: false,
    senior: 1,
    middle: 1,
    operational: 1,
    ok: true,
    nextloading: false,
    loading: true,
    chart: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  makeRadar = () => {
    let labels = ['Senior Management', 'Middle Management', 'Operational'];
    let series = [
      {
        name: '',
        data: [this.state.senior, this.state.middle, this.state.operational],
      },
    ];
    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: parseInt(e.target.value),
        edit: true,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        scores: {
          senior: this.state.senior,
          middle: this.state.middle,
          operational: this.state.operational,
        },
      };
      Axios.post('/api/app/quick_look/modulo1/question7Enterprise', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908482748?h=0c7b8f12fc');
    Axios.get(
      '/api/app/quick_look/modulo1/question7Enterprise?id=' + this.props.id,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    ).then((response) => {
      if (response.data.ok) {
        this.setState(
          {
            loading: false,
            senior: response.data.senior,
            middle: response.data.middle,
            operational: response.data.operational,
          },
          () => {
            this.makeRadar();
          },
        );
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.7</b>
              </p>
              <p className="mb-4">
                Would you get the support required to fix the root problem in
                your established enterprise? Rate their reaction.
                <br />
                Each would be rated on a score of 1-10, where 1 = strongly
                disagree and 10 = strongly agree.
                <br />
                <br />
                Does everyone care enough to support you? Are some people
                reluctant about risk? Are the people with power willing to risk
                supporting something new?
              </p>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <div className="" style={{ width: '80%' }}>
                  <span className="font-bold">Role</span>
                </div>
                <div className="text-center" style={{ width: '20%' }}>
                  <span className="font-bold">Score</span>
                </div>
              </div>
              <div className="superCenter mx-auto" style={{ width: '95%' }}>
                <div className="" style={{ width: '80%' }}>
                  Senior Management
                </div>
                <div className="" style={{ width: '20%' }}>
                  <select
                    className="form-control w-75 mx-auto"
                    value={this.state.senior}
                    name="senior"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="superCenter mx-auto" style={{ width: '95%' }}>
                <div className="" style={{ width: '80%' }}>
                  Middle Management
                </div>
                <div className="" style={{ width: '20%' }}>
                  <select
                    className="form-control w-75 mx-auto"
                    value={this.state.middle}
                    name="middle"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="superCenter mx-auto" style={{ width: '95%' }}>
                <div className="" style={{ width: '80%' }}>
                  Operational
                </div>
                <div className="" style={{ width: '20%' }}>
                  <select
                    className="form-control w-75 mx-auto"
                    value={this.state.operational}
                    name="operational"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Problem Chart
                  </p>
                  {/* <p style={{fontSize:"13px", textAlign:"center"}}>Snap shot of the intensity to fix each problem</p> */}
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question7Enterprise;
