import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    problems: [],
    q2: [],
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo5/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          problems: response.data.data,
          q2: response.data.dataq2,
          loading: false,
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>5. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Clients Solution Set</b>
                  </p>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Characteristic</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Capabilities</b>
                      </p>
                    </div>
                  </div>
                  {this.state.problems.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-6">
                        <p className="my-1">{p.value}</p>
                      </div>
                      <div className="border col-6">
                        <p className="my-1">{p.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b> “in principle” business characteristics</b>
                  </p>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Characteristic</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Description of the requirement’s contribution</b>
                      </p>
                    </div>
                  </div>
                  {this.state.q2.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-6">
                        <p className="my-1">{p.value}</p>
                      </div>
                      <div className="border col-6">
                        <p className="my-1">{p.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
