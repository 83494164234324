import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import './ScatterChart.css';
import Axios from 'axios';
import { getToken } from '../../config/index';
import Spinner from 'react-bootstrap/Spinner';

export class ScatterChart extends Component {
  state = {
    loading: true,
    allProjects: [],
    myProjects: [],
    series: [],

    options: {
      colors: ['#cea977', '#002ba2', '#2fb1a5', '#6b42b3'],
      tooltip: {
        enabled: true,
        intersect: true,
        // eslint-disable-next-line no-unused-vars
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return `
            <div class="ScatterChart-cuadro row text-center">
              <div class='col-12 superCenter'>
                ${
                  seriesIndex !== 0
                    ? `User: ${this.state.allProjects[dataPointIndex].user}`
                    : ''
                }
              </div> 
              <div class='col-12 mt-2 superCenter'>
                <div class="ScatterChart-circle mr-2"></div>
                <div class='ScatterChart-title'>
                  The Root Problem:
                </div>
              </div> 
              <div class="col-10 text-center mx-auto">
                <span>
                  ${
                    seriesIndex === 0
                      ? this.state.myProjects[dataPointIndex].root
                      : this.state.allProjects[dataPointIndex].root
                  }
                </span>
              </div>
              <div class='col-12 superCenter mt-4'>
                <div class="ScatterChart-circle2 mr-2"></div>
                <div class='ScatterChart-title'>
                  Single Problems:
                </div>
              </div> 
              ${
                seriesIndex === 0
                  ? this.state.myProjects[dataPointIndex].problems.map(
                      (arg) =>
                        `<div class="col-10 text-center mx-auto">
                    <span>${arg}</span>
                  </div>`,
                    )
                  : this.state.allProjects[dataPointIndex].problems.map(
                      (arg) =>
                        `<div class="col-10 text-center mx-auto">
                    <span>${arg}</span>
                  </div>`,
                    )
              }
            </div>
          `;
        },
      },
      grid: {
        show: true,
        borderColor: '#aaa',
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        height: 350,
        type: 'scatter',
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'right',
        horizontalAlign: 'right',
        fontSize: '16px',
        fontFamily: 'Lato-Light',
        fontWeight: 800,
        markers: {
          width: 16,
          height: 16,
        },
        itemMargin: {
          horizontal: 50,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      xaxis: {
        tickAmount: 2,
        min: 0,
        max: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
        },
        labels: {
          align: 'center',
          formatter: function (val) {
            if (val === 0) return 'Low';
            if (val === 5) return 'Your confidence';
            if (val === 10) return 'High';
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
        min: 0,
        max: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
        },
        labels: {
          align: 'right',
          minWidth: 100,
          formatter: function (val) {
            if (val === 0) return 'Low';
            if (val === 5) return 'Their willingness';
            if (val === 10) return 'High';
          },
        },
      },
    },
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/scatterChart', {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.ok) {
          let data = [];

          response.data.myProjects.forEach((project) => {
            data.push([project.confidence, project.willing]);
          });

          let series = [
            {
              name: 'Root Cause Problem',
              data,
            },
            // {
            //   name: "Single Problems",
            //   data: []
            // },
            // {
            //   name: "Pain Reduction Types",
            //   data: []
            // },
            // {
            //   name: "Aspirational Value Types",
            //   data: []
            // },
          ];
          if (response.data.allProjects.length) {
            let data2 = [];
            response.data.allProjects.forEach((project) => {
              data2.push([project.confidence, project.willing]);
            });
            series.push({ name: 'Others Projects', data: data2 });
          }

          this.setState({
            allProjects: response.data.allProjects,
            myProjects: response.data.myProjects,
            series,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log('err.response', err.response);
      });
  }

  render() {
    return (
      <div
        className="row border py-3"
        style={{ borderRadius: '20px', marginLeft: '1vw' }}
      >
        {this.state.loading ? (
          <div className="col-2 text-center mx-auto">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <div className="col-10 mx-auto">
            <p
              className="my-4 mx-auto text-center w-75 font-bold"
              style={{ fontSize: '0.9rem' }}
            >
              To invest their effort, money and reputation to develop and adopt
              my good idea to solve the root cause problem
            </p>
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="scatter"
              height={350}
              width={'100%'}
            />
            <p className="my-4 text-center">
              Hover over each dot to see the root cause problem type, each
              problem and the associated pain types
            </p>
            <p
              className="mt-5"
              style={{ fontSize: '0.9rem', color: '#78909C' }}
            >
              <span className="font-bold text-sm">Note</span>: you must fill out
              all sections of the project to see its statistics.
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default ScatterChart;
