import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    problems: [],
    q42: [],
    q43: [],
    q62: [],
    chart: {},
    loading: true,
    money: [],
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo7/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          money: response.data.money,
          loading: false,
          problems: response.data.data,
        });

        response.data.q42.forEach((element) => {
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q42: [
              ...this.state.q42,
              { role: element.role, pain: element.pain, score: x },
            ],
          });
        });

        response.data.q43.forEach((element) => {
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q43: [
              ...this.state.q43,
              { role: element.role, pain: element.pain, score: x },
            ],
          });
        });

        response.data.q62.forEach((element) => {
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q62: [
              ...this.state.q62,
              { role: element.role, pain: element.pain, score: x },
            ],
          });
        });

        let labels = [];
        let data = [];
        let data1 = [];
        let data2 = [];
        let data3 = [];
        this.state.problems.forEach((element) => {
          labels.push(element.role + ' - ' + element.p1);
          data.push(element.score);
          this.state.q42.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain)
              data1.push(e.score);
          });
          this.state.q43.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain)
              data2.push(e.score);
          });
          this.state.q62.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain)
              data3.push(e.score);
          });
        });
        let series = [];

        series.push({ name: 'As is', data: data1 });
        series.push({ name: 'Needs to Be', data: data2 });
        series.push({ name: 'To Be', data: data3 });
        series.push({ name: 'Could Be', data: data });

        this.setState({
          chart: {
            labels,
            series,
            colors: ['#ed0000', '#00e676', '#0091ea', '#ffd600'],
            id: 'M7chart',
          },
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>7. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Estimate will be saved and made</b>
                  </p>
                  <div className="row">
                    <div className="border col-4">
                      <p className="my-1">
                        <b>Role</b>
                      </p>
                    </div>
                    <div className="border col-4">
                      <p className="my-1">
                        <b>Saved</b>
                      </p>
                    </div>
                    <div className="border col-4">
                      <p className="my-1">
                        <b>Made</b>
                      </p>
                    </div>
                  </div>
                  {this.state.money.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-4">
                        <p className="my-1">{p.role}</p>
                      </div>
                      <div className="border col-4">
                        <p className="my-1">{p.saved}</p>
                      </div>
                      <div className="border col-4">
                        <p className="my-1">{p.made}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Could Be Radar Map
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the value experienced by the key actors in the
                    scenario if the good idea is adopted
                  </p>
                  <RadarChart val={this.state.chart} graphic={this.graphic} />
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
