import {
  HeadingLevel,
  AlignmentType,
  UnderlineType,
  TextRun,
  Paragraph,
} from 'docx';

export const styles = {
  report: (texto) => {
    return {
      text: texto,
      heading: HeadingLevel.HEADING_1,
      alignment: AlignmentType.CENTER,
    };
  },
  titulo: (texto) => {
    return {
      text: texto,
      alignment: AlignmentType.CENTER,
      heading: HeadingLevel.HEADING_3,
      spacing: { before: 200, after: 200 },
      border: {
        top: {
          color: 'auto',
          space: 10,
          value: 'dotted',
          size: 3,
        },
      },
    };
  },
  modulo: (texto) => {
    return {
      text: '     ' + texto,
      heading: HeadingLevel.HEADING_2,
      alignment: AlignmentType.LEFT,
      spacing: { before: 300, after: 300 },
    };
  },
  imagen: (img) => {
    return {
      alignment: AlignmentType.CENTER,
      children: [img],
    };
  },
  headerLogo: (img) => {
    return {
      alignment: AlignmentType.CENTER,
      children: [img],
    };
  },
  footerLogo: (img, texto, texto2) => {
    return [
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: texto,
            bold: true,
            underline: {
              type: UnderlineType.Single,
              color: '000000',
            },
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          // img,
          new TextRun(texto2),
        ],
      }),
    ];
  },
  list: (texto) => {
    return {
      text: '  - ' + texto,
      spacing: { before: 0, after: 300 },
    };
  },
  tableHead2: (texto1, texto2) => {
    return {
      children: [
        new TextRun({
          text: texto1 + ' / ',
          bold: true,
          underline: {
            type: UnderlineType.Single,
            color: '000000',
          },
        }),
        new TextRun({
          text: texto2,
          bold: true,
          underline: {
            type: UnderlineType.Single,
            color: '000000',
          },
        }),
      ],
      alignment: AlignmentType.CENTER,
      spacing: { before: 0, after: 300 },
    };
  },
  tableText2: (texto1, texto2) => {
    return {
      children: [
        new TextRun({
          text: texto1 + ' / ',
        }),
        new TextRun({
          text: texto2,
        }),
      ],
      alignment: AlignmentType.CENTER,
      spacing: { before: 0, after: 200 },
    };
  },
  textCenter: (texto) => {
    return {
      children: [
        new TextRun({
          text: texto,
        }),
      ],
      alignment: AlignmentType.CENTER,
      spacing: { before: 0, after: 200 },
    };
  },
  chartTitle: (texto) => {
    return {
      children: [
        new TextRun({
          text: texto,
          bold: true,
        }),
      ],
      alignment: AlignmentType.CENTER,
    };
  },
  list2: (name, value) => {
    return {
      children: [
        new TextRun({
          text: name,
          bold: true,
          underline: {
            type: UnderlineType.Single,
            color: '000000',
          },
        }),
        new TextRun({
          text: ': ' + value,
        }),
      ],
    };
  },
};
