import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import NavBar from '../../../components/NavBar/NavBar';
import './projects.css';
import ScatterChart from '../../../components/ScatterChart/ScatterChart';

export class Projects extends Component {
  render() {
    return (
      <>
        <NavBar auth={true} quicklook={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="problem-projects">
          <div className="problem-sidebar">
            <div className="font-bold superCenter problem-title">
              Quick Look
            </div>
            <div className="problem-options">
              <button className="accordion-btn">
                <Link
                  to="/quick_look/projects"
                  className="problem-options-font"
                >
                  <i className="fas fa-tasks mr-2"></i>Projects
                </Link>
              </button>
              <button className="accordion-btn" style={{ color: 'black' }}>
                <Link
                  to="/quick_look/statistics"
                  className="problem-options-font"
                  style={{ color: 'black' }}
                >
                  <i className="far fa-chart-bar mr-2"></i> Statistic
                </Link>
              </button>
              {/* <button className="accordion-btn problem-options-font">
                <Link to="" className="problem-options-font">
                  <i className="fas fa-user-alt mr-2"></i> My Profile
                </Link>
              </button> */}
            </div>
          </div>

          <div className="problem-main">
            <Row className="mb-3 ml-0">
              <Col md={8}>
                <p className="mb-1">
                  <b>Statistics</b>
                </p>
              </Col>
            </Row>

            <ScatterChart />
          </div>
        </div>
      </>
    );
  }
}

export default Projects;
