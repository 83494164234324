import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  componentDidMount() {
    this.props.changeVideo('932331715?h=63023cd75c');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION THREE = PAIN CRITERIA TYPES</b>
        </p>

        <p className="mb-4">
          If role-playing the part of a typical consumer does not produce
          sufficient empathy to comprehend the types and magnitude of pain
          experienced, then try to converse with actors familiar with the
          scenario and the pain impact. It would be best if you were very clear
          about the types of pain points experienced by whom, and by how much to
          answer these questions:
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            What are the types of operational day-to-day system pain and the
            human pain experienced by the critical actors in terms of what they
            typically endure? In particular, the pain that cascades into the
            enterprise (start-up) and the interconnected world as direct
            consequence of the root problem.
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Then I will better understand the nature of and comprehend the
            magnitude of the pain impact on every enterprise, actor or role type
            to establish a pain criteria list adopting each actor’s narrative to
            describe the pain impact. This can then be used to define and
            measure any value outcomes during the next stage, where we compare
            this perspective with an actual day in the life of a
            customer-centric scenario.
          </span>
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
