import React from 'react';

export function TermsAndConditions() {
  return (
    <div className="px-4 py-2">
      <p className="font-bold" style={{ fontSize: '20px' }}>
        Digital Value Capture Application: Terms And Conditions Of Use
      </p>
      <ol>
        <li className="font-bold">
          About the application
          <p className="font-normal pt-2 mb-0">
            Welcome to the Digital Value Capture application (the
            'Application'). The Application supports users of the Digital Value
            Capture framework and its various methodologies (the 'Services ').
          </p>
          <p className="font-normal pt-3 mb-0">
            The Application is operated by Annexus Pty Ltd. (ACN 658 448 545)
            (‘Annexus’). Access to and use of the Application, or any of its
            associated Products or Services, is provided by Annexus. Please read
            these terms and conditions (the 'Terms ') carefully. By using,
            browsing and/or reading the Application, this signifies that you
            have read, understood and agree to be bound by the Terms. If you do
            not agree with the Terms, you must cease usage of the Application,
            or any of its Services, immediately.
          </p>
          <p className="font-normal pt-3">
            Annexus reserves the right to review and change any of the Terms by
            updating this page at its sole discretion. When Annexus updates the
            Terms, it will use reasonable endeavours to provide you with notice
            of updates to the Terms. Any changes to the Terms take immediate
            effect from the date of their publication. Before you continue, we
            recommend you keep a copy of the Terms for your records.
          </p>
        </li>
        <li className="font-bold">
          Acceptance of the terms
          <p className="font-normal pt-2">
            You accept the Terms by using or browsing the Application. You may
            also accept the Terms by clicking to accept or agree to the Terms
            where this option is made available to you by Annexus in the user
            interface.
          </p>
        </li>
        <li className="font-bold">
          Subscription to use the services
          <p className="font-normal pt-2 mb-0">
            In order to access the Services, you must first:
          </p>
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                Purchase a subscription through the Application (the
                'Subscription') and pay the applicable fee for the selected
                Subscription (the 'Subscription Fee'); or
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                Receive the Subscription via a third-party payment of the
                Subscription Fee on your behalf.
              </span>
            </li>
          </ol>
          <p className="font-normal mb-0">
            In purchasing the Subscription, or receiving the Subscription via a
            third party payment of the Subscription Fee on your behalf, you
            acknowledge and agree that it is your responsibility to ensure that
            the Subscription you elect to purchase, or receive, is suitable for
            your use.
          </p>
          <p className="font-normal pt-2 mb-0">
            Once you have purchased, or received the Subscription, you will then
            be required to register for an account through the Application
            before you can access the Services (the 'Account').{' '}
          </p>
          <p className="font-normal pt-2 mb-0">
            As part of the registration process, or as part of your continued
            use of the Services, you may be required to provide personal
            information about yourself (such as identification or contact
            details), including:
          </p>
          <ul className="py-3" style={{ listStyleType: 'disc' }}>
            <li>Email address</li>
            <li className="pt-2">Preferred username</li>
            <li className="pt-2">Mailing address</li>
            <li className="pt-2">Telephone number (optional)</li>
            <li className="pt-2">Password</li>
            <li className="pt-2">Other personal information required</li>
          </ul>
          <p className="font-normal mb-0">
            You warrant that any information you give to Annexus in the course
            of completing the registration process will always be accurate,
            correct and up to date.
          </p>
          <p className="font-normal pt-2 mb-0">
            Once you have completed the registration process, you will be a
            registered member of the Application ('Member') and agree to be
            bound by the Terms. As a Member you will be granted immediate access
            to the Services from the time you have completed the registration
            process until the subscription period expires (the 'Subscription
            Period').
          </p>
          <p className="font-normal pt-2">
            You may not use the Services and may not accept the Terms if: (i)
            you are not of legal age to form a binding contract with Annexus; or
            (ii) you are a person barred from receiving the Services under the
            laws of Australia or other countries including the country in which
            you are resident or from which you use the Services.
          </p>
        </li>
        <li className="font-bold">
          Your obligations as a member
          <p className="font-normal pt-2 mb-0">
            As a Member, you agree to comply with the following:
          </p>
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                you will use the Services only for purposes that are permitted
                by: (i) the Terms; and (ii) any applicable law, regulation or
                generally accepted practices or guidelines in the relevant
                jurisdictions;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                you have the sole responsibility for protecting the
                confidentiality of your password and/or email address. Use of
                your password by any other person may result in the immediate
                cancellation of the Services;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                any use of your registration information by any other person, or
                third parties, is strictly prohibited. You agree to immediately
                notify Annexus of any unauthorised use of your password or email
                address or any breach of security of which you have become
                aware;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                access and use of the Application is limited, non-transferable
                and allows for the sole use of the Application by you for the
                purposes of Annexus providing the Services;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                you will not use the Services or the Application in connection
                with any commercial endeavours except those that are
                specifically endorsed or approved by the management of Annexus.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                you will not use the Services or Application for any illegal
                and/or unauthorised use which includes collecting email
                addresses of Members by electronic or other means for the
                purpose of sending unsolicited email or unauthorised framing of
                or linking to the Application.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                you agree that commercial advertisements, affiliate links, and
                other forms of solicitation may be removed from the Application
                without notice and may result in termination of the Services.
                Appropriate legal action will be taken for any illegal or
                unauthorised use of the Application; and
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                you acknowledge and agree that any automated use of the
                Application or its Services is prohibited.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Payment
          <p className="font-normal pt-2 mb-0">
            Where the option is given to you, you may make payment of the
            Subscription Fee by way of:
          </p>
          <p className="font-normal pt-2 mb-0">
            Electronic funds transfer ('EFT ') into our nominated bank account
          </p>
          <p className="font-normal pt-2 mb-0">
            Credit Card Payment ('Credit Card')
          </p>
          <p className="font-normal pt-2 mb-0">PayPal ('PayPal')</p>
          <p className="font-normal pt-2 mb-0">Cash ('Cash')</p>
          <p className="font-normal pt-2 mb-0">Crypto tokens</p>
          <p className="font-normal pt-2 mb-0">Barter</p>
          <ol type="a" className="py-2">
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                All payments made in the course of your use of the Services are
                made using [Stripe payment gateway]. In using the Application,
                the Services or when making any payment in relation to your use
                of the Services, you warrant that you have read, understood and
                agree to be bound by the payment gateway providers used terms
                and conditions which are available on their application.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                You acknowledge and agree that where a request for the payment
                of the Subscription Fee is returned or denied, for whatever
                reason, by your financial institution or is unpaid by you for
                any other reason, then you are liable for any costs, including
                banking fees and charges, associated with the Subscription Fee.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                You agree and acknowledge that Annexus can vary the Subscription
                Fee at any time and that the varied Subscription Fee will come
                into effect following the conclusion of the existing
                Subscription Period.
              </p>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Refund Policy{' '}
          <p className="font-normal pt-2">
            Annexus will only provide you with a refund of the Subscription Fee
            in the event they are unable to continue to provide the Services or
            if the manager of Annexus makes a decision, at its absolute
            discretion, that it is reasonable to do so under the circumstances.
            Where this occurs, the refund will be in the proportional amount of
            the Subscription Fee that remains unused by the Member (the
            'Refund').
          </p>
        </li>

        <li className="font-bold">
          Copyright and Intellectual Property
          <p className="font-normal pt-2 mb-0">
            Annexus will only provide you with a refund of the Subscription Fee
            in the event they are unable to continue to provide the Services or
            if the manager of Annexus makes a decision, at its absolute
            discretion, that it is reasonable to do so under the circumstances.
            Where this occurs, the refund will be in the proportional amount of
            the Subscription Fee that remains unused by the Member (the
            'Refund').
          </p>
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                All trademarks, service marks and trade names are owned,
                registered and/or licensed by Annexus, who grants to you a
                worldwide, non-exclusive, royalty-free, revocable license whilst
                you are a Member to:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                use the Application pursuant to the Terms;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                copy and store the Application and the material contained in the
                Application in your device's cache memory; and
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                print pages from the Application for your own personal and
                non-commercial use.
                <br />
                Annexus does not grant you any other rights whatsoever in
                relation to the Application or the Services. All other rights
                are expressly reserved by Annexus.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus retains all rights, title and interest in and to the
                Application and all related Services. Nothing you do on or in
                relation to the Application will transfer any:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                business name, trading name, domain name, trade mark, industrial
                design, patent, registered design or copyright, or
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                a right to use or exploit a business name, trading name, domain
                name, trademark or industrial design, or
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                a thing, system or process that is the subject of a patent,
                registered design or copyright (or an adaptation or modification
                of such a thing, system or process), to you.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                You may not, without the prior written permission of Annexus and
                the permission of any other relevant rights owners: broadcast,
                republish, upload to a third party, transmit, post, distribute,
                show or play in public, adapt or change in any way the Services
                or third-party Services for any purpose, unless otherwise
                provided by these Terms. This prohibition does not extend to
                materials on the Application, which are freely available for
                re-use or are in the public domain.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Collecting your information
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus only collects information about you that you consent to,
                and that is reasonably necessary to help you and others use the
                Application or participate in any of our healthcare research
                studies (each a ‘Study’ and collectively ‘Studies’)
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus only collects information by lawful and fair means and
                generally only directly from you.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                Annexus will take reasonable steps to notify you of certain
                matters when we collect your information. This Terms &
                Conditions document serves as part of that notice.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Privacy
          <p className="pt-2 font-normal">
            Annexus takes your privacy seriously and any information provided
            through your use of the Application and/or Services are subject to
            our Privacy Policy, which is available at
            www.digitalvaluecapture.com
          </p>
        </li>
        <li className="font-bold">
          General Disclaimer
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Nothing in the Terms limits or excludes any guarantees,
                warranties, representations or conditions implied or imposed by
                law, including the Australian Consumer Law (or any liability
                under them) which by law may not be limited or excluded.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Subject to this clause, and to the extent permitted by law:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                all terms, guarantees, warranties, representations or conditions
                which are not expressly stated in the Terms are excluded; and
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus will not be liable for any special, indirect or
                consequential loss or damage (unless such loss or damage is
                reasonably foreseeable resulting from our failure to meet an
                applicable Consumer Guarantee), loss of profit or opportunity,
                or damage to goodwill arising out of or in connection with the
                Services or these Terms (including as a result of not being able
                to use the Services or the late supply of the Services), whether
                at common law, under contract, tort (including negligence), in
                equity, pursuant to statute or otherwise.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Use of the Application and the Services is at your own risk.
                Everything on the Application and the Services is provided to
                you ‘as is’ and ‘as available’ without warranty or condition of
                any kind. None of the affiliates, directors, officers,
                employees, agents, contributors and licensors of Annexus make
                any express or implied representation or warranty about the
                Services or any products or Services (including the products or
                Services of Annexus) referred to on the Application. This
                includes (but is not restricted to) loss or damage you might
                suffer as a result of any of the following:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                failure of performance, error, omission, interruption, deletion,
                defect, failure to correct defects, delay in operation or
                transmission, computer virus or other harmful component, loss of
                data, communication line failure, unlawful third party conduct,
                or theft, destruction, alteration or unauthorised access to
                records;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                the accuracy, suitability or currency of any information on the
                Application, the Services, or any of its Services related
                products (including third party material and advertisements on
                the Application);
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                costs incurred as a result of you using the Application, the
                Services or any of the products of Annexus Pty Ltd; and
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                the Services or operation in respect to links which are provided
                for your convenience.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Competitors
          <p className="pt-2 font-normal">
            If you are in the business of providing similar Services for the
            purpose of providing them to users for a commercial gain, whether
            business users or domestic users, then you are a competitor of
            Annexus. Competitors are not permitted to use or access any
            information or content on our Application. If you breach this
            provision, Annexus will hold you fully responsible for any loss that
            we may sustain and hold you accountable for all profits that you
            might make from such a breach.
          </p>
        </li>
        <li className="font-bold">
          Limitation of liability
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus’ total liability arising out of or in connection with
                the Services or these Terms, however arising, including under
                contract, tort (including negligence), in equity, under statute
                or otherwise, will not exceed the resupply of the Services to
                you.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                You expressly understand and agree that Annexus, its affiliates,
                employees, agents, contributors, and licensors shall not be
                liable to you for any direct, indirect, incidental, special
                consequential or exemplary damages which may be incurred by you,
                however caused and under any theory of liability. This shall
                include, but is not limited to, any loss of profit (whether
                incurred directly or indirectly), any loss of goodwill or
                business reputation and any other intangible loss
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Termination of Contract
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                The Terms will continue to apply until terminated by either you
                or by Annexus Pty Ltd as set out below.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                If you want to terminate the Terms, you may do so by:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                not renewing the Subscription prior to the end of the
                Subscription Period;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                providing Annexus with 20 days notice of your intention to
                terminate; and
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                closing your accounts for all of the services which you use,
                where Annexus has made this option available to you. <br />
                Your notice should be sent, in writing, to Annexus via the
                'Contact Us' link on our homepage.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus may at any time, terminate the Terms with you if:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                you do not renew the Subscription at the end of the Subscription
                Period;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                you have breached any provision of the Terms or intend to breach
                any provision;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus is required to do so by law;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                the provision of the Services to you is, in the opinion of
                Annexus, no longer commercially viable.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                Subject to local applicable laws, Annexus reserves the right to
                discontinue or cancel your membership at any time and may
                suspend or deny, in its sole discretion, your access to all or
                any portion of the Application or the Services without notice if
                you breach any provision of the Terms or any applicable law or
                if your conduct impacts Annexus’ name or reputation or violates
                the rights of those of another party.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Indemnity
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                You agree to indemnify Annexus, its affiliates, employees,
                agents, contributors, third party content providers and
                licensors from and against:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                all actions, suits, claims, demands, liabilities, costs,
                expenses, loss and damage (including legal fees on a full
                indemnity basis) incurred, suffered or arising out of or in
                connection with Your Content;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                any direct or indirect consequences of you accessing, using or
                transacting on the Application or attempts to do so; and/or
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">any breach of the Terms.</span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Dispute Resolution
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Compulsory: If a dispute arises out of or relates to the Terms,
                either party may not commence any Tribunal or Court proceedings
                in relation to the dispute, unless the following clauses have
                been complied with (except where urgent interlocutory relief is
                sought).
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Notice: A party to the Terms claiming a dispute ('Dispute') has
                arisen under the Terms, must give written notice to the other
                party detailing the nature of the dispute, the desired outcome
                and the action required to settle the Dispute.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Resolution: On receipt of that notice ('Notice') by that other
                party, the parties to the Terms ('Parties') must:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Within 30 days of the Notice endeavour in good faith to resolve
                the Dispute expeditiously by negotiation or such other means
                upon which they may mutually agree;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                If for any reason whatsoever, 20 days after the date of the
                Notice, the Dispute has not been resolved, the Parties must
                either agree upon selection of a mediator or request that an
                appropriate mediator be appointed by the President of the
                mediation body or his or her nominee;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                The Parties are equally liable for the fees and reasonable
                expenses of a mediator and the cost of the venue of the
                mediation and without limiting the foregoing undertake to pay
                any amounts requested by the mediator as a pre-condition to the
                mediation commencing. The Parties must each pay their own costs
                associated with the mediation;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                The mediation will be held in Queensland, Australia.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Confidential: All communications concerning negotiations made by
                the Parties arising out of and in connection with this dispute
                resolution clause are confidential and to the extent possible,
                must be treated as ‘without prejudice’ negotiations for the
                purpose of applicable laws of evidence.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                Termination of Mediation: If 10 days have elapsed after the
                start of a mediation of the Dispute and the Dispute has not been
                resolved, either Party may ask the mediator to terminate the
                mediation and the mediator must do so.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Venue and Jurisdiction
          <p className="pt-2 font-normal">
            The Services offered by Annexus is intended to be viewed by
            residents of Australia. In the event of any dispute arising out of
            or in relation to the Application, you agree that the exclusive
            venue for resolving any dispute shall be in the courts of
            Queensland, Australia.
          </p>
        </li>
        <li className="font-bold">
          Governing Law
          <p className="pt-2 font-normal">
            The Terms are governed by the laws of Queensland, Australia. Any
            dispute, controversy, proceeding or claim of whatever nature arising
            out of or in any way relating to the Terms and the rights created
            hereby shall be governed, interpreted and construed by, under and
            pursuant to the laws of Queensland, Australia, without reference to
            conflict of law principles, notwithstanding mandatory rules. The
            validity of this governing law clause is not contested. The Terms
            shall be binding to the benefit of the parties hereto and their
            successors and assigns.
          </p>
        </li>
        <li className="font-bold">
          Severance
          <p className="pt-2 font-normal">
            If any part of these Terms is found to be void or unenforceable by a
            Court of competent jurisdiction, that part shall be severed, and the
            rest of the Terms shall remain in force.
          </p>
        </li>
      </ol>
    </div>
  );
}
