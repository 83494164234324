import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import Logo from '../../assets/img/landing/vectores-07.png';
import { getToken } from '../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Dropzone from 'react-dropzone';
import './Quote.css';

export class SendQuote extends Component {
  state = {
    loading: false,
    description: '',
    name: '',
    file: '',
    fileName: '',
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    if (
      this.state.description === '' &&
      this.state.name === '' &&
      this.state.file === '' &&
      this.state.fileName === ''
    ) {
      toastr.remove();
      toastr.warning('Please complete the form');
      this.setState({ loading: false });
    } else {
      const data = new FormData();
      data.append('file', this.state.file);
      data.append('fileName', this.state.fileName);
      data.append('name', this.state.name);
      data.append('description', this.state.description);
      Axios.post('/api/app/getQuote', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          this.setState({ loading: false });
          toastr.remove();
          toastr.success('Quote sent!');
        } else {
          this.setState({ loading: false });
          toastr.remove();
          toastr.warning('Error while getting projects');
        }
      });
    }
  };

  onDropDoc = (files) => {
    if (files.length === 1) {
      if (
        files[0].name.split('.')[1] &&
        (files[0].name.split('.')[1] === 'DOCX' ||
          files[0].name.split('.')[1] === 'docx')
      ) {
        this.setState({
          file: files[0],
          fileName: files[0].name,
        });
      } else {
        toastr.warning('File type unknown');
      }
    }
  };

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter"></div>
        </div>
        <div className="create2fa-section text-center">
          <h3 className="font-bold pt-5" style={{ color: '#cea977' }}>
            Get a Qoute
          </h3>
          <br />
          <Form
            className="quote-form mt-4 mx-auto"
            onSubmit={this.handleSubmit}
          >
            <input
              className="form-control my-3"
              type="text"
              name="name"
              onChange={this.handleChange}
              placeholder="Full Name"
            />
            <textarea
              className="form-control my-3"
              style={{ height: '30vh' }}
              name="description"
              onChange={this.handleChange}
              placeholder="Description"
            ></textarea>
            <Dropzone onDrop={(acceptedFiles) => this.onDropDoc(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section className="text-center dropzone-section my-3">
                  <div
                    className="h-100 row dropzone-div m-0"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="my-auto row w-100 m-0">
                      <div className="col-12 superCenter">
                        {this.state.fileName ? (
                          <span className="ml-auto">{this.state.fileName}</span>
                        ) : (
                          <span className="ml-auto">Upload project report</span>
                        )}
                        <i
                          className="fas fa-plus-square mr-auto ml-2"
                          style={{ fontSize: '2vh', color: '#cea977' }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
            <div className="submit-button text-center">
              <button
                type="submit"
                className="btn quote-button w-50"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Send'
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default SendQuote;
