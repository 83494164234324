import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    problems: [],
    ok: true,
    q2: [],
    q3: [],
    chart: {},
    idea: '',
    loading: true,
    error: '',
  };
  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo6/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          problems: response.data.data,
          idea: response.data.idea.description,
          loading: false,
        });

        response.data.q2.forEach((element) => {
          let esta = 0;
          this.setState({
            q2: [...this.state.q2, element],
          });
          this.state.problems.forEach((e) => {
            if (element.role === e.role) esta = 1;
          });

          if (esta === 0) {
            this.setState({
              problems: [
                ...this.state.problems,
                { role: element.role, pain: element.pain, score: 1 },
              ],
            });
          }
        });
        response.data.q3.forEach((element) => {
          this.setState({
            q3: [...this.state.q3, element],
          });
        });

        let labels = [];
        let data = [];
        let datai = [];
        let dataj = [];
        this.state.problems.forEach((element) => {
          labels.push(element.role + ' - ' + element.pain);
          data.push(element.score);
          this.state.q2.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain) {
              datai.push(e.score);
            }
          });
          this.state.q3.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain) {
              dataj.push(e.score);
            }
          });
        });
        let series = [];
        series.push({ name: 'As is', data: datai });
        series.push({ name: 'Needs to be', data: dataj });
        series.push({ name: 'To be', data: data });

        this.setState({
          chart: {
            labels,
            series,
            colors: ['#ed0000', '#00e676', '#0091ea'],
            id: 'M6chart',
          },
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>6. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    The good idea description
                  </p>
                  {this.state.idea}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>To Be Radar Map</b>
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the pain experienced by the key actors in the
                    scenario and where they feel their pain would be (to be) if
                    the good idea was adopted.
                  </p>
                  <RadarChart val={this.state.chart} graphic={this.graphic} />
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
