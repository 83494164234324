import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Logo from '../../assets/img/logos/SA_logo.png';
import LogoForm from '../../assets/img/landing/vectores-28.png';
import {
  getToken,
  setOrg,
  getOrg,
  removeOrg,
  setSignup,
} from '../../config/index';
import axios from 'axios';
import toastr from 'toastr';
import './signup.css';

export class Signup extends Component {
  state = {
    token: '',
    username: '',
    email: '',
    password: '',
    rpassword: '',
    loading: false,
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let data = {};
    if (this.state.password !== this.state.rpassword) {
      toastr.remove();
      toastr.warning('Passwords does not match');
      this.setState({ loading: false });
    } else {
      if (this.state.username.indexOf(' ') !== -1) {
        toastr.remove();
        toastr.warning('Username has an invalid character');
        this.setState({ loading: false });
      } else {
        data = {
          name: this.state.username,
          email: this.state.email,
          password: this.state.password,
          rpassword: this.state.rpassword,
          organization: getOrg(),
        };
        axios
          .post('/api/signup', data, {
            headers: { Authorization: 'Bearer ' + getToken() },
          })
          .then((response) => {
            if (response.data.ok) {
              removeOrg();
              setSignup(response.data.token);
              this.setState({ loading: false, token: response.data.token });
            } else {
              toastr.remove();
              toastr.warning(response.data.error);
              this.setState({ loading: false });
            }
          });
      }
    }
  };

  render() {
    if (this.props.organization) {
      setOrg(window.location.pathname.split('signup/')[1]);
    }
    return this.state.token === '' ? (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row signup-section">
          <div className="col-6 p-0 signup-banner">
            <div
              className="text-light w-75 mx-auto"
              style={{ marginTop: '65vh' }}
            >
              <h3 className="font-bold">Welcome</h3>
              <p>
                Please sign-up to buy the <b>Quick Look module</b> for USD$475
              </p>
            </div>
          </div>
          <div className="col-6 p-0 superCenter">
            <div className="text-center">
              <img src={LogoForm} alt="" className="signup-form-icon" />
              <h3 className="font-bold my-4">Sign up</h3>
              <form action="/" onSubmit={this.handleSubmit}>
                <input
                  className="form-control signup-input mx-auto"
                  type="text"
                  name="username"
                  placeholder="Username"
                  onChange={this.handleChange}
                />
                <input
                  className="form-control signup-input mx-auto"
                  type="text"
                  name="email"
                  placeholder="E-mail"
                  onChange={this.handleChange}
                />
                <input
                  className="form-control signup-input mx-auto"
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={this.handleChange}
                />
                <input
                  className="form-control signup-input mx-auto"
                  type="password"
                  name="rpassword"
                  placeholder="Repeat Password"
                  onChange={this.handleChange}
                />
                <button
                  className="btn signup-button mx-auto"
                  type="submit"
                  disabled={this.state.loading}
                >
                  {this.state.loading ? (
                    <Spinner size="sm" animation="border" variant="light" />
                  ) : (
                    'Create Account'
                  )}
                </button>
              </form>
              <p className="w-75 mx-auto">
                Do you already have an account?{' '}
                <Link to="/home" className="signup-link font-bold">
                  {' '}
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <Redirect
        to={{
          pathname: '/agreements',
          state: { token: this.state.token },
        }}
      />
    );
  }
}

export default Signup;
