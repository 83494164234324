import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import Swal from 'sweetalert2';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import { getToken } from '../../config/index';
import './BuyModule.css';

export class PaySuccess extends Component {
  state = {
    mensaje: 'Validating Payment...',
    moduleId: '',
  };

  successPayStripe = (moduleId) => {
    let url = window.location.search.split('=');
    if (url[0] === '?session_id') {
      let data = {
        id: url[1],
        moduleId,
      };
      Axios.post('/api/pay-stripe-success', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      })
        .then((response) => {
          if (response.data.ok) {
            Swal.fire({
              icon: 'success',
              title: 'Congratulations!',
              text: 'You are now an Upgraded User',
              showConfirmButton: false,
              timer: 4000,
            }).then(() => {
              window.location = `/${moduleId}/projects`;
            });
          } else {
            Swal.fire({
              icon: 'question',
              title: 'Payment Error',
              text: 'Contact support if payment was send, or try again',
              showConfirmButton: false,
              timer: 4000,
            }).then(() => {
              window.location = `/${moduleId}/projects`;
            });
          }
        })
        .catch(() => {
          Swal.fire({
            icon: 'question',
            title: 'Payment Error',
            text: 'Contact support if payment was send, or try again',
            showConfirmButton: false,
            timer: 4000,
          }).then(() => {
            window.location = `/${moduleId}/projects`;
          });
        });
    } else {
      Swal.fire({
        icon: 'question',
        title: 'Payment Error',
        text: 'Contact support if payment was send, or try again',
        showConfirmButton: false,
        timer: 4000,
      }).then(() => {
        window.location = `/${moduleId}/projects`;
      });
    }
  };

  componentDidMount() {
    if (this.props.valid) {
      let moduleId = window.location.pathname.split('/pay-stripe-success/')[1];
      this.setState({ moduleId });
      this.successPayStripe(moduleId);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'The proccess was interrupted',
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        let moduleId = window.location.pathname.split(
          '/pay-stripe-success/',
        )[1];
        window.location = `/${moduleId}/projects`;
      });
    }
  }

  render() {
    return (
      <>
        <NavBar
          auth={true}
          deepdive={this.state.moduleId === 'deep_dive'}
          quicklook={this.state.moduleId === 'quick_look'}
        />
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="row p-0 mx-auto buy-container superCenter">
          <Spinner animation="border" variant="secondary" className="mr-2" />
          {this.state.mensaje}
        </div>
      </>
    );
  }
}

export default PaySuccess;
