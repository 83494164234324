import React, { Component } from 'react';
import { Nav, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getToken } from '../../config/index';
import Logo from '../../assets/img/landing/vectores-07.png';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import './newPass.css';

export class NewPass extends Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    Axios.post(
      '/api/newPass',
      {
        password: e.target.password.value,
        repassword: e.target.confirm_password.value,
        email: this.props.location.state.email,
      },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    ).then((response) => {
      if (response.data.ok) {
        toastr.remove();
        toastr.success('Password reset completed');
        return (window.location = '/login');
      } else {
        this.setState({ loading: false });
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  };

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="create2fa-section text-center">
          <h3 className="font-bold my-5" style={{ color: '#cea977' }}>
            Enter new password
          </h3>
          <Form className="form" onSubmit={this.handleSubmit}>
            <Form.Group>
              <input
                className="form-control newPass-input mx-auto"
                type="password"
                id="password"
                placeholder="Password"
              />
              <input
                className="form-control newPass-input mx-auto"
                type="password"
                id="confirm_password"
                placeholder="Confirm Password"
              />
            </Form.Group>
            <div className="submit-button">
              <button className="btn newPass-button" type="submit">
                {this.state.loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Set Password'
                )}
              </button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default NewPass;
