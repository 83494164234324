import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question5 extends Component {
  state = {
    description: '',
    edit: false,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(4);
  };

  handleNext = () => {
    this.props.next(6);
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
      [e.target.id]: e.target.value,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        description: this.state.description,
      };
      Axios.post('/api/app/deep_dive/modulo1/question5', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(6);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(6);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo1/question5?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          description: response.data.data,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.5</b>
              </p>
              <p className="mb-4">
                Can you identify a pattern within the characteristics of the
                problems that indicates a single root cause to most of the
                problems? Please describe the root problem in short sentence
                below.
                <br />
                Start with each problem and ask WHY four times: “WHY does this
                happen” and “WHY does that happen” and “WHY does that happen”
                and “WHY” does that happen. When you can’t go any deeper, you’ve
                probably found your root cause for most (if not all) the
                problems.
              </p>
              <textarea
                id="description"
                onChange={this.handleInputChange}
                value={this.state.description}
                className="description"
                placeholder="Enter root problem here"
              ></textarea>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question5;
