import React, { Component } from 'react';
// MODULO 1
import M1Question1 from './Module1/Question1';
import M1Question2 from './Module1/Question2';
import M1Question3 from './Module1/Question3';
import M1Question4 from './Module1/Question4';
import M1Question5 from './Module1/Question5';
import M1Question6 from './Module1/Question6';
import M1Question7 from './Module1/Question7';
import M1Report from './Module1/Report';
// MODULO 2
import M2Question1 from './Module2/Question1';
import M2Question2 from './Module2/Question2';
import M2Report from './Module2/Report';
// MODULO 3
import M3Question1 from './Module3/Question1';
import M3Report from './Module3/Report';
// MODULO 4
import M4Question1 from './Module4/Question1';
import M4Question2 from './Module4/Question2';
import M4Question3 from './Module4/Question3';
import M4Report from './Module4/Report';
// MODULO 5
import M5Question1 from './Module5/Question1';
import M5Question2 from './Module5/Question2';
import M5Report from './Module5/Report';
// MODULO 6
import M6Question1 from './Module6/Question1';
import M6Question2 from './Module6/Question2';
import M6Report from './Module6/Report';
// MODULO 7
import M7Question1 from './Module7/Question1';
import M7Question2 from './Module7/Question2';
import M7Report from './Module7/Report';
// MODULO 8
import M8Question1 from './Module8/Question1';
import M8Question2 from './Module8/Question2';
import M8Report from './Module8/Report';
// FINAL
import FinalReport from './Report';

export class Modules extends Component {
  nextModule = (val) => {
    this.props.changeOption(val);
  };

  handleLoad = () => {};

  render() {
    return (
      <>
        {/* MODULO 1 */}

        {this.props.option === 1 ? (
          <M1Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 2 ? (
          <M1Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 3 ? (
          <M1Question3 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 4 ? (
          <M1Question4 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 5 ? (
          <M1Question5 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 6 ? (
          <M1Question6 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 7 ? (
          <M1Question7 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 8 ? (
          <M1Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 2 */}

        {this.props.option === 9 ? (
          <M2Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 10 ? (
          <M2Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 11 ? (
          <M2Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 3 */}

        {this.props.option === 12 ? (
          <M3Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 13 ? (
          <M3Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 4 */}

        {this.props.option === 14 ? (
          <M4Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 15 ? (
          <M4Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 16 ? (
          <M4Question3 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 17 ? (
          <M4Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 5 */}

        {this.props.option === 18 ? (
          <M5Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 19 ? (
          <M5Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 20 ? (
          <M5Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 6 */}

        {this.props.option === 21 ? (
          <M6Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 22 ? (
          <M6Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 23 ? (
          <M6Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 7 */}

        {this.props.option === 24 ? (
          <M7Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 25 ? (
          <M7Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 26 ? (
          <M7Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 8 */}

        {this.props.option === 27 ? (
          <M8Question1 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 28 ? (
          <M8Question2 id={this.props.id} next={this.nextModule} />
        ) : null}
        {this.props.option === 29 ? (
          <M8Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* FIN */}

        {this.props.option === 30 ? (
          <FinalReport id={this.props.id} next={this.nextModule} />
        ) : null}
      </>
    );
  }
}

export default Modules;
