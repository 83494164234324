import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './DeepDive.css';
import Footer from '../../components/Footer/Footer';
import toastr from 'toastr';

export class DeepDive extends Component {
  handleLink = () => {
    if (window.innerWidth < 767) {
      toastr.remove();
      toastr.warning(
        'Dashboard is not meant to be used in phones, please use your desktop or tablet',
      );
    } else {
      window.location = this.redirectUrl();
    }
  };

  redirectUrl = () => {
    if (this.props.isPaid) {
      return '/deep_dive/projects';
    } else return '/purchase_module/deep_dive';
  };

  render() {
    return (
      <>
        <NavBar auth={this.props.auth} deepdive={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">Deep Dive Module</h3>
          </div>
        </div>
        <div className="pl-3 deepdive-section">
          <div className="row deepdive-text">
            <div className="col-sm-12 col-md-7">
              <p>
                We all have ideas... but are they any good? To us, a GOOD IDEA
                means an idea for solving a problem real people care about
                enough that they’ll adopt and use a solution.
              </p>
              <p>
                When evaluating ideas, traditional market research and ROI
                calculations fall short largely because they don't account for
                the human dimension of problem-solving: the experience of pain.
                Put simply, if your solution doesn't reduce their pain, they
                won't buy it.
              </p>
              <p>
                It's easy to spend a lot of time and money testing ideas. Many
                new projects run out of resources before getting clarity on the
                problem they solve. And now the world has changed due to COVID.
                The system used to reward risk-taking and there was investment
                money to burn, so your track record of failure didn’t matter.
                Now, money for new ventures has dried up. You, and your
                investors, can no longer afford an 80-90% project failure rate…
                or to be marked as a serial failure.
              </p>
              <p>
                We've developed a framework that helps you arrive at a qualified
                GOOD IDEA that has a high probability of success BEFORE you
                spend real time and money developing the new thing.
              </p>
              <p>
                When you can build solutions on solid foundations and high
                probability, you mitigate business risk by creating real value
                for real people. And you create a strong reputation for success,
                which is essential in the risk-averse economic environment we’re
                all living in now.
              </p>
              <p>
                That's why we've developed a step-by-step formula for evaluating
                the odds of a business idea working from both the customer and
                business requirements perspective in a risk-averse post COVID
                economy.
              </p>
              <p>
                So if you’re interested in decreasing the risk that your Good
                Idea won’t work and increasing your chances of success, then
                upgrade to the Deep Dive program
              </p>
              {this.props.auth ? (
                <button
                  className="btn mx-3 deepdive-buttons"
                  onClick={this.handleLink}
                >
                  {this.props.isPaid ? 'Go to Dashboard' : 'Get it now'}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default DeepDive;
