import React, { Component } from 'react';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import icon from '../../../../assets/img/icons/icon-21.png';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { selectStyle, getToken } from '../../../../config/index';

export class Question1 extends Component {
  state = {
    problems: [],
    options: [{ value: 'Add one', label: 'Add one' }],
    edit: false,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleRemove = (role, Pindex, index) => {
    this.state.problems[Pindex].data.splice(index, 1);
    this.setState(
      {
        edit: true,
        problems: this.state.problems,
      },
      () => {
        this.makeRadar(role, Pindex);
      },
    );
  };

  makeRadar = (role, index) => {
    let labels = [];
    let data = [];
    this.state.problems[index].data.forEach((element) => {
      if (element.value === 'Add one') {
        labels.push(element.nuevo);
      } else {
        labels.push(element.value);
      }
      data.push(element.score);
    });
    let series = [];
    series.push({ name: '', data: data });
    this.setState({
      [role]: { labels, series },
    });
  };

  handleBack = () => {
    this.props.next(11);
  };

  handleNext = () => {
    this.props.next(13);
  };

  handleChangeSelect = (selectedOption, Pindex, Dindex) => {
    let v = this.state.problems;
    let c = v[Pindex];
    let j = c.data;
    if (selectedOption.value) j[Dindex].value = selectedOption.value;
    if (selectedOption.label) j[Dindex].label = selectedOption.label;
    if (selectedOption.nuevo) j[Dindex].nuevo = selectedOption.nuevo;
    let add = false;
    if (selectedOption.value !== 'Add one') add = true;
    this.setState({
      problems: v,
      add,
      edit: true,
    });
  };

  handleChange = (e, Pindex, Dindex, valor) => {
    let v = this.state.problems;
    let c = v[Pindex];
    let j = c.data;
    if (valor === 'score') {
      j[Dindex]['score'] = parseInt(e.target.value);
      c.data = j;
      v[Pindex] = c;
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar(c.role, Pindex);
        },
      );
    } else {
      if (
        e.target.value &&
        e.target.value[0] &&
        !e.target.value[0].match(/[0-9]/)
      ) {
        j[Dindex][e.target.name] = e.target.value;
      } else {
        j[Dindex][e.target.name] = '';
      }
      c.data = j;
      v[Pindex] = c;
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar(c.role, Pindex);
        },
      );
    }
  };

  getOccurrence = (array, value) => {
    return array.filter(
      (v) =>
        (v.value === 'Add one' && v.nuevo && v.nuevo.toLowerCase() === value) ||
        v.value.toLowerCase() === value,
    ).length;
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      let valid = true;
      let vacio = false;
      let repetidos = '';
      this.state.problems.forEach((element) => {
        if (element.data.length < 3) {
          valid = false;
        }
        element.data.forEach((e) => {
          if (
            (e.value === 'Add one' && (!e.nuevo || e.nuevo === '')) ||
            e.value === ''
          ) {
            vacio = true;
          } else {
            if (e.value === 'Add one') {
              if (this.getOccurrence(element.data, e.nuevo.toLowerCase()) > 1) {
                repetidos = element.role;
              }
            } else {
              if (this.getOccurrence(element.data, e.value.toLowerCase()) > 1) {
                repetidos = element.role;
              }
            }
          }
        });
      });
      if (repetidos === '') {
        if (valid) {
          if (!vacio) {
            const data = {
              id: this.props.id,
              problems: this.state.problems,
            };
            Axios.post('/api/app/deep_dive/modulo3/question1', data, {
              headers: { Authorization: 'Bearer ' + getToken() },
            }).then((response) => {
              if (response.data.ok) {
                toastr.remove();
                toastr.success('Saved!');
                this.props.next(13);
              } else {
                toastr.remove();
                toastr.warning(response.data.error);
                this.setState({
                  nextloading: false,
                });
              }
            });
          } else {
            toastr.remove();
            toastr.warning("Pain types can't be empty");
            this.setState({
              nextloading: false,
            });
          }
        } else {
          toastr.remove();
          toastr.warning('3 Pain types per role minimum');
          this.setState({
            nextloading: false,
          });
        }
      } else {
        toastr.remove();
        toastr.warning(`${repetidos} has duplicated pain types`);
        this.setState({
          nextloading: false,
        });
      }
    } else {
      this.props.next(13);
    }
  };

  handleAdd = (Pindex) => {
    let v = this.state.problems;
    if (v[Pindex].data.length <= 4) {
      v[Pindex].data.push({ label: '', value: '', score: 1 });
      this.setState({
        edit: true,
        problems: v,
      });
    } else {
      toastr.warning('Max 5 problems');
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo3/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        console.log('response.data', response.data);
        this.setState({
          loading: false,
          options: this.state.options.concat(response.data.options),
        });
        let v = [];

        let esta = 0;
        response.data.data.forEach((element) => {
          if (element.role === 'Me or my client') {
            esta = 1;
          }
        });
        if (esta === 1) {
          this.setState({
            problems: response.data.data,
          });
        } else {
          v.push({
            role: 'Me or my client',
            data: [
              { label: '', value: '', score: 1 },
              { label: '', value: '', score: 1 },
              { label: '', value: '', score: 1 },
            ],
          });
          response.data.data.forEach((element) => {
            if (element.data.length < 3) {
              v.push({
                role: element.role,
                data: [
                  { label: '', value: '', score: 1 },
                  { label: '', value: '', score: 1 },
                  { label: '', value: '', score: 1 },
                ],
              });
            } else {
              v.push(element);
            }
          });
          this.setState({
            problems: v,
          });
        }
        this.state.problems.forEach((element) => {
          let labels = [];
          let data = [];
          element.data.forEach((e) => {
            if (e.value === 'Add one') {
              labels.push(e.nuevo);
            } else {
              labels.push(e.value);
            }
            data.push(e.score);
          });
          let series = [{ name: '', data: data }];
          this.setState({
            [element.role]: { labels, series },
          });
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 3.1</b>
              </p>
              <p className="mb-4">
                Can you define the nature, type or characteristic of the pain
                experienced by the role types as a consequence of the identified
                root problem? For example: compliance; loss of revenue; cost or
                efficiency of operations; ability to be agile and responsive to
                change; contestability. Please list between 3 and 5 pain points
                per role type and rate the magnitude of pain on a scale of 1 to
                10 where 1 = low pain and 10 = high pain.
              </p>
              {this.state.problems.map((problem, i) => (
                <div className="mb-1" key={i}>
                  <hr />
                  <h5>
                    <b>{problem.role}</b>
                  </h5>
                  <div className="row">
                    <div className="col-8">
                      <b>Type of pain</b>
                    </div>
                    <div className="col-2 text-center">
                      <b>Score 1 - 10</b>
                    </div>
                  </div>
                  {problem.data.map((arg, index) => (
                    <div key={index} className="row">
                      {arg.value !== 'Add one' ? (
                        <div className="col-8 superCenter">
                          <Select
                            value={arg.value === '' ? '' : arg}
                            onChange={(e) =>
                              this.handleChangeSelect(e, i, index)
                            }
                            options={this.state.options}
                            styles={selectStyle}
                            placeholder="Choose title or add new"
                          />
                        </div>
                      ) : (
                        <>
                          <div className="col-2 superCenter">
                            <Select
                              value={arg.value === '' ? '' : arg}
                              onChange={(e) =>
                                this.handleChangeSelect(e, i, index)
                              }
                              options={this.state.options}
                              styles={selectStyle}
                              placeholder="Choose title or add new"
                            />
                          </div>
                          <div className="col-6 superCenter">
                            <input
                              className="form-control"
                              name="nuevo"
                              value={arg.nuevo}
                              onChange={(e) =>
                                this.handleChange(e, i, index, 'type')
                              }
                            ></input>
                            <small
                              style={{
                                position: 'absolute',
                                top: '80%',
                                left: '3%',
                              }}
                            >
                              3 words max
                            </small>
                          </div>
                        </>
                      )}
                      <div className="col-2 superCenter">
                        <select
                          className="form-control w-75 mx-auto"
                          value={arg.score}
                          name="score"
                          onChange={(e) =>
                            this.handleChange(e, i, index, 'score')
                          }
                          style={{ width: '30%' }}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </div>
                      <div className="col-2 superCenter">
                        <span className="m-auto">
                          <img
                            style={{ width: '30px', cursor: 'pointer' }}
                            src={icon}
                            alt="delete"
                            onClick={() =>
                              this.handleRemove(problem.role, i, index)
                            }
                          />
                        </span>
                      </div>
                    </div>
                  ))}
                  {problem.data.length <= 4 ? (
                    <button
                      className="btn ml-3 mt-4"
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleAdd(i);
                      }}
                    >
                      <i
                        className="fas fa-plus mr-1"
                        style={{ fontSize: '13px' }}
                      ></i>
                      Add
                    </button>
                  ) : null}
                  {this.state[this.state.problems[i].role] &&
                  this.state[this.state.problems[i].role].labels.length > 2 ? (
                    <>
                      <hr />
                      <p
                        style={{
                          fontSize: '20px',
                          textAlign: 'center',
                          marginBottom: '0px',
                        }}
                      >
                        {this.state.problems[i].role}
                      </p>
                      <p style={{ fontSize: '13px', textAlign: 'center' }}>
                        Snap shot of the pain experienced as a consequence of
                        the identified root problem
                      </p>
                      <RadarChart
                        val={this.state[this.state.problems[i].role]}
                      />
                    </>
                  ) : null}
                </div>
              ))}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
