import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question3 extends Component {
  state = {
    problems: [],
    prev: [],
    ok: true,
    loading: true,
    edit: false,
    nextloading: false,
    chart: {},
    error: '',
  };

  handleBack = () => {
    this.props.next(15);
  };

  handleNext = () => {
    this.props.next(17);
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    let dataprev = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role + ' - ' + element.pain);
      data.push(element.score);
      this.state.prev.forEach((e) => {
        if (element.role === e.role && element.pain === e.pain) {
          dataprev.push(e.score);
        }
      });
    });
    let series = [];
    series.push({ name: 'As is', data: dataprev });
    series.push({ name: 'Needs to be', data: data });

    this.setState({
      chart: { labels, series, colors: ['#ed0000', '#00e676'] },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    v[key]['score'] = parseInt(e.target.value);
    this.setState(
      {
        edit: true,
        problems: v,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo4/question3', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(17);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(17);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo4/question3?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        response.data.data.forEach((element) => {
          this.setState({
            problems: [
              ...this.state.problems,
              { role: element.role, pain: element.pain, score: element.score },
            ],
          });
        });
        response.data.prev.forEach((element) => {
          let esta = 0;
          this.setState({
            prev: [...this.state.prev, element],
          });
          this.state.problems.forEach((e) => {
            if (element.role === e.role) esta = 1;
          });

          if (esta === 0) {
            this.setState({
              problems: [
                ...this.state.problems,
                { role: element.role, pain: element.pain, score: 1 },
              ],
            });
          }
        });
        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 4.3</b>
              </p>
              <p className="mb-4">
                If you were to ask each of the named actors of your scenario
                what the realistic level of pain they exerience needs to be
                reduced to, what would that be on a scale of 1 to 10 where 1 =
                low pain and 10 = high pain.
              </p>
              <table style={{ width: '100%', margin: '2% 0' }}>
                <thead>
                  <tr>
                    <td>
                      <strong>Role</strong>
                    </td>
                    <td>
                      <strong>Pain type</strong>
                    </td>
                    <td>
                      <strong>Score 1-10</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="role"
                          value={arg.role}
                          disabled
                          style={{ background: 'white' }}
                        />
                      </td>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="pain"
                          value={arg.pain}
                          disabled
                          style={{ background: 'white' }}
                        />
                      </td>
                      <td>
                        <select
                          className="form-control w-75 mx-auto text-center"
                          value={arg.score}
                          name="score"
                          id="score"
                          onChange={(e) => this.handleChange(e, index)}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Scenario Pain Points
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of the pain experienced by the key actors in the
                scenario and what they need it to be at a minimum in order to
                cope.
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                  float: 'bottom',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question3;
