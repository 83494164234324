import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Redirect } from 'react-router-dom';
import './projects.css';

export class Projects extends Component {
  state = {
    redirect: false,
  };

  handleSend = () => {
    this.setState({
      redirect: true,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: '/deep_dive/project/' + this.props.id,
            state: { name: this.props.name },
          }}
        />
      );
    } else {
      return (
        <Row
          className="border mb-4 mx-0"
          style={{ borderRadius: '30px', height: '110px' }}
        >
          <Col md={2} lg={1} className="my-auto text-left p-0">
            <div className="deepdive-task-icon mx-auto">
              <i
                className="fas fa-tasks text-light"
                style={{ fontSize: '30px' }}
              ></i>
            </div>
          </Col>
          <Col md={7} lg={8} className="my-auto p-0">
            <div className="p-0">
              <p className="m-0">
                <b>{this.props.name}</b>
              </p>
              <p className="m-0">{this.props.description}</p>
              {this.props.user ? (
                <small className="m-0">
                  {this.props.user.username} / {this.props.user.email}
                </small>
              ) : null}
            </div>
          </Col>
          <Col md={3} lg={3} className="my-auto text-right superCenter">
            <button
              onClick={this.handleSend}
              className="btn mr-4 deepdive-btn-project"
            >
              View Project <i className="fas fa-arrow-right"></i>
            </button>
            {!this.props.user ? (
              <div
                className="mb-0"
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#cea977',
                  borderRadius: '100px',
                }}
                onClick={() => this.props.remove(this.props.id)}
              >
                <i
                  className="fas fa-trash"
                  style={{ margin: '7px', color: 'white' }}
                ></i>
              </div>
            ) : null}
          </Col>
        </Row>
      );
    }
  }
}

export default Projects;
