import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setToken } from '../../config/index';
import { Form, Button, Spinner } from 'react-bootstrap';
import './use.css';
import { getToken } from '../../config/index';
import Axios from 'axios';
import Logo from '../../assets/img/landing/vectores-07.png';
import toastr from 'toastr';

export class Use extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      token: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({
      token: e.target.value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const data = {
      token: this.state.token,
      id: this.props.location.state.id,
    };

    Axios.post('/api/2fa', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        toastr.remove();
        toastr.success('Successfully logged!');
        this.setState({ loading: false });
        setToken(response.data.token);
        setTimeout(() => {
          window.location = '/projects';
        }, 1000);
      } else {
        toastr.remove();
        toastr.warning('Invalid token');
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="create2fa-section text-center">
          <h3 className="font-bold my-5" style={{ color: '#cea977' }}>
            2-FACTOR AUTHENTICATION
          </h3>

          <Form className="form mt-5 w-25 mx-auto" onSubmit={this.handleSubmit}>
            <Form.Group>
              <span>Enter the 6-digits code from the app</span>
              <input
                className="form-control"
                type="text"
                id="token"
                onChange={this.handleInputChange}
                placeholder="2FA"
              />
            </Form.Group>
            <div className="submit-button text-center">
              <Button type="submit" className="btn create2fa-button w-50">
                {this.state.loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Use;
