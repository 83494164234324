import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './JourneyRoadmap.css';
import Footer from '../../components/Footer/Footer';
import Divisor from '../../assets/img/landing/vectores-11.png';

export class JourneyRoadmap extends Component {
  render() {
    return (
      <>
        <NavBar auth={this.props.auth} journey={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">Jorney Roadmap</h3>
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-10 mx-auto text-left pt-5 mb-4">
            <p>
              Are you responsible for finding safe passage though the post COVID
              fallout and then navigating the journey to a better position than
              where you’re currently heading?
            </p>
            <p>
              Or are you figuring out how to remain viable and relevant in the
              fast-approaching automated world, where humans compete against
              software and robots who never tire?
            </p>
            <p>
              Maybe you’re scaling up, diversifying and embracing digital
              transformation, looking to develop new market offerings for
              emerging decentralised markets that are value-centric and consumer
              led.
            </p>
            <p>
              Or perhaps you do all these things, and you’re probably challenged
              by lack of funding and grappling with so much complexity that the
              problems appear insurmountable.
            </p>
          </div>
        </div>
        <img src={Divisor} alt="barra-divisora" className="w-50 d-block" />
        <div className="row p-0 m-0">
          <div className="col-10 mx-auto text-left pt-5 pb-4">
            <p>
              I’m <b>Stephen Alexander</b>, creator of{' '}
              <b>Digital Value Capture®</b>, and I help people like you fix
              wicked problems.
            </p>
            <p>
              I’ve created strategic plans, I call them journey roadmaps, with
              people like you in just about every market sector, across multiple
              geographic regions around the world. I’ve worked with start-ups
              and scale-ups, as well as all types of government agencies,
              including planning for smart cities.
            </p>
            <p>
              I can help you determine what direction you need to go and how to
              get your business or project to where it needs to be. And I can do
              that fast, using reliable evidence-based knowledge of what works,
              that is, what new technology capabilities can help you solve real
              problems and generate verifiable value. Using Blockchain
              solutions, you can capture that value and convert it to a tradable
              asset-based token.
            </p>
            <p>
              In 30 years I’ve seen the patterns in why projects fail. Most
              often it’s because they don’t solve the right problem and
              therefore don’t deliver value for people who are experiencing real
              pain.
            </p>
            <p>
              The most common message from business, social and economic
              commentators today is that both recovery and also future success
              will depend on your ability to create meaningful value, which is
              defined from a consumer perspective, not from yours.
            </p>
            <p>
              With my experience, I probably already know how to address most of
              the problems, reduce much of the operational and human pain AND
              realise value from the solution sets available in your market
              sector.
            </p>
            <p>
              That’s why I can offer a short cut to clarity and confidence by
              mentoring entrepreneurs and executives through a process of
              comprehending the value capture potential of your business or
              project, within the context of an interconnected digital world and
              then applying that value to fuel your journey to your new desired
              end point.
            </p>
            <p>
              I’m now offering a handful of spaces for 1:1 mentoring over the
              next 3-6 months. Together, we’ll get clear on where you could go.
              How far you dare to go is up to you.{' '}
            </p>
            <p>
              If you’re serious about 1:1 mentoring, I invite you to book an
              exploratory session. We’ll take a look at your project or idea and
              do a “first look” assessment of its value capture potential. Then
              we can decide how to work together to see your good idea become
              reality.
            </p>
          </div>
        </div>
        <div className="row journey-banner text-light">
          <div className="col-12 mt-auto text-center">
            <h3 className="font-bold">
              For more information or to book a session <br /> head over to the
              academy
            </h3>
          </div>
          <a
            href="https://digital-value-capture.teachable.com/p/executive-value-capture-mentoring2"
            className="btn journey-button d-block mb-auto mx-auto mt-3"
          >
            Go to Academy now
          </a>
        </div>
        <Footer />
      </>
    );
  }
}

export default JourneyRoadmap;
