import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    startup: 'select',
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleChange = (e) => {
    this.setState({
      startup: e.target.value,
    });
  };

  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleSave = () => {
    this.setState({
      nextloading: true,
    });

    const data = {
      id: this.props.id,
      startup: this.state.startup === 'yes' ? true : false,
    };
    Axios.post('/api/app/quick_look/modulo1/question1', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        toastr.remove();
        toastr.success('Saved!');
        this.props.changeStartUp(data.startup);
        this.props.next(1);
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
      }
      this.setState({
        nextloading: false,
      });
    });
  };

  componentDidMount() {
    this.props.changeVideo('908477805?h=676feda29d');
    Axios.get('/api/app/quick_look/modulo1/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        let value = 'no';
        if (response.data.data === undefined) value = 'select';
        else if (response.data.data) value = 'yes';

        this.setState({
          loading: false,
          startup: value,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.1</b>
              </p>
              <p className="mb-4">
                Let’s get started. So, you have a good idea. Is your good idea
                for an established enterprise or a new start-up?
              </p>
              <div className="row">
                <select
                  className="form-control col-4 mx-auto"
                  defaultValue={this.state.startup}
                  onChange={this.handleChange}
                >
                  <option value="select" selected disabled>
                    Please select an option...
                  </option>
                  <option value="no">Established Enterprise</option>
                  <option value="yes">New Start-Up</option>
                </select>
              </div>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  disabled={this.state.startup === 'select'}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      Save & Next<i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
