import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import './navbar.css';
import Logo from '../../assets/img/landing/vectores-07.png';
import icon from '../../assets/img/landing/vectores-25.png';
import { logout } from '../../config/index';

export class NavBar extends Component {
  render() {
    return (
      <Nav
        className="justify-content-end align-items-center"
        style={{ height: '75px' }}
      >
        <Nav.Item className="ml-4 mr-auto">
          <img src={Logo} alt="" className="navbar-logo" />
        </Nav.Item>
        {window.innerWidth < 992 ? (
          <Nav.Item>
            <div>
              <button
                className="dropdown-toggle nav-dropdown"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Menu
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <Link className={`superCenter text-center nav-link`} to="/">
                  Home
                </Link>
                <Link
                  className={`superCenter text-center nav-link`}
                  to="/academy"
                >
                  I-Future Proof Community
                </Link>
                {/* <Link
                  className={`superCenter text-center nav-link`}
                  to="/journey_roadmap"
                >
                  Will It Be
                  <br />
                  Worth It
                </Link> */}
                <div className={`superCenter text-center nav-link`}>
                  Will It Be
                  <br />
                  Worth It
                </div>
                {/* <Link
                  className={`superCenter text-center nav-link`}
                  to="/deep_dive"
                >
                  Deep Dive
                  <br />
                  Module
                </Link> */}
                <div className={`superCenter text-center nav-link`}>
                  Deep Dive
                  <br />
                  Module
                </div>
                <Link
                  className={`superCenter text-center nav-link`}
                  to="/quick_look"
                >
                  Quick Look <br /> Will it meet the needs?
                </Link>
                <Link
                  className={`superCenter text-center nav-link`}
                  to="/about"
                >
                  About + Bio
                </Link>
                <Link
                  className={`superCenter text-center nav-link`}
                  to="/quote"
                >
                  Get a Quote
                </Link>
                {!this.props.auth ? (
                  <Link
                    to="/signup"
                    className="superCenter text-center nav-link"
                  >
                    Sign Up Now
                  </Link>
                ) : (
                  <span
                    className="superCenter text-center nav-link"
                    onClick={logout}
                    style={{ cursor: 'pointer' }}
                  >
                    Log Out{' '}
                    <img
                      src={icon}
                      alt="logout"
                      style={{ width: '10%', marginLeft: '8px' }}
                    />
                  </span>
                )}
              </div>
            </div>
          </Nav.Item>
        ) : (
          <>
            <Nav.Item>
              <Link
                className={`superCenter text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '75px' }}
              >
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter text-center nav-link ${
                  this.props.academy ? 'active' : ''
                }`}
                to="/academy"
                style={{ height: '75px' }}
              >
                I-Future Proof Community
              </Link>
            </Nav.Item>
            <Nav.Item>
              {/* <Link
                className={`superCenter text-center nav-link ${
                  this.props.journey ? 'active' : ''
                }`}
                to="/journey_roadmap"
                style={{ height: '75px' }}
              >
                Will It Be
                <br />
                Worth It
              </Link> */}
              <div className={`superCenter text-center nav-link`}>
                Will It Be
                <br />
                Worth It
              </div>
            </Nav.Item>
            <Nav.Item>
              <div className={`superCenter text-center nav-link`}>
                Deep Dive
                <br />
                Module
              </div>
              {/* <Link
                className={`superCenter text-center nav-link ${
                  this.props.deepdive ? 'active' : ''
                }`}
                to="/deep_dive"
                style={{ height: '75px' }}
              >
                Deep Dive
                <br />
                Module
              </Link> */}
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter text-center nav-link ${
                  this.props.quicklook ? 'active' : ''
                }`}
                to="/quick_look"
                style={{ height: '75px' }}
              >
                Quick Look <br /> Will it meet the needs?
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter text-center nav-link ${
                  this.props.about ? 'active' : ''
                }`}
                to="/about"
                style={{ height: '75px' }}
              >
                About + Bio
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter text-center nav-link ${
                  this.props.quote ? 'active' : ''
                }`}
                to="/quote"
                style={{ height: '75px' }}
              >
                Get a Quote
              </Link>
            </Nav.Item>
            <Nav.Item>
              {!this.props.auth ? (
                <Link to="/signup" className="btn mx-3 nav-button">
                  Sign Up Now
                </Link>
              ) : (
                <span
                  className="superCenter text-center nav-link"
                  onClick={logout}
                  style={{ cursor: 'pointer' }}
                >
                  Log Out{' '}
                  <img
                    src={icon}
                    alt="logout"
                    style={{ width: '18%', marginLeft: '8px' }}
                  />
                </span>
              )}
            </Nav.Item>
          </>
        )}
      </Nav>
    );
  }
}

export default NavBar;
