import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Row, Col } from 'react-grid-system';
import img from '../../assets/img/logos/SA_logo.png';
import './footer.css';
import linke from '../../assets/img/landing/vectores-10.png';

export class Footer extends Component {
  render() {
    return (
      <>
        <div className="footer">
          <Row>
            <Col md={6} className="ml-auto">
              <div className="footer1">
                <img src={img} alt="Stephen Alexander" />
                <p>
                  Stephen Alexander, Adjunct Professor, Hult International
                  Business School, London Campus.
                </p>
                <p>stephen@stephenalexander.com.au</p>
                <p>
                  Creating value in an interconnected world <br /> © Stephen
                  Alexander | All Rights Reserved{' '}
                </p>
              </div>
            </Col>

            <Col md={3} className="mr-auto">
              <div className="footer2">
                <b>Stephen Alexander</b>
                {/* <p> <Link to="/conditions">Conditions</Link> </p> 
                        <p> <Link to="">Contact</Link> </p> */}
                <p>
                  {' '}
                  <a href="/">Sign In</a>{' '}
                </p>
                <br />
                <a
                  className="icon-linked"
                  href="https://www.linkedin.com/in/stephen-alexander-value-capture/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linke} alt="linke" style={{ width: '35px' }} />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Footer;
