import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  componentDidMount() {
    this.props.changeVideo('932327812?h=afd040ec60');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION ONE = ENTERPRISE PROBLEMS</b>
        </p>

        <p className="mb-4">
          Start your informal conversations with others to find out whether they
          agree with your identified root problem and whether you would get the
          level of support from each of the three tiers of the organisation or
          the co-founders of a start-up that you need to move forward.
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            What are the problems you want to address, who experiences them and
            at what level do the actors operate within the existing enterprise,
            start—up, scale-up or an organised crowd?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            What problems do all the actors that I need support from care about
            and will they support addressing the problems I care about; what are
            these problems and how are they perceived and experienced by each
            level of the enterprise?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can establish if sufficient demand exists by all the parties
            to fix the identified root problem to as many of the identified
            problems as possible, including my own problem to fund a solution
            set, assuming at this point that I can define one that works!
          </span>
        </div>
        <hr />
        <div style={{ width: '100%', display: 'flex' }}>
          <button
            className="btn my-4 p-3 ml-auto"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
