import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    pain: [],
    loading: true,
    error: '',
    entra: true,
    problems: [],
  };

  handleBack = () => {
    this.props.next(12);
  };

  handleNext = () => {
    this.props.next(14);
  };

  graphic = (id, img) => {
    if (this.props.graphic) {
      this.props.graphic(id, img);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo3/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        let pains = [];
        let problems = [];
        response.data.data.forEach((element) => {
          let labels = [];
          let data = [];
          element.data.forEach((e) => {
            if (e.value === 'Add one') {
              pains.push(e.nuevo);
              labels.push(e.nuevo);
            } else {
              if (pains.indexOf(e.value) === -1) pains.push(e.value);
              labels.push(e.value);
            }
            data.push(e.score);
          });
          let series = [];
          series.push({ name: '', data: data });
          problems.push({
            name: element.role,
            graphic: {
              labels,
              series,
              id: element.role.split(/[^A-Za-z0-9]/).join(''),
            },
          });
        });
        this.setState(
          {
            pain: pains,
            problems: problems,
          },
          () => {
            this.props.load();
          },
        );
      } else {
        this.setState(
          {
            error: response.data.error,
          },
          () => {
            this.props.load();
          },
        );
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>3. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center mb-4">
                    <b>Pain Criteria List</b>
                  </p>
                  {this.state.pain.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-12">
                        <p className="my-1">{p}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center mb-4">
                    <b>Pain Points Radar Maps</b>
                  </p>
                  {this.state.problems.map((p, index) => (
                    <div key={index}>
                      <p
                        style={{
                          fontSize: '20px',
                          textAlign: 'center',
                          marginBottom: '0px',
                        }}
                      >
                        {p.name}
                      </p>
                      <p style={{ fontSize: '13px', textAlign: 'center' }}>
                        Snap shot of the pain experienced as a consequence of
                        the identified root problem
                      </p>
                      <RadarChart val={p.graphic} graphic={this.graphic} />
                      <hr />
                    </div>
                  ))}
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
