import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import RadarChart from '../../../RadarChart/RadarChart';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    savings: 1,
    income: 1,
    impact: 1,
    edit: false,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
    chart: '',
  };

  handleScore = (e) => {
    this.setState({
      [e.target.name]: parseInt(e.target.value),
      edit: true,
    });
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        savings: this.state.savings,
        income: this.state.income,
        impact: this.state.impact,
      };
      Axios.post('/api/app/quick_look/modulo7/question1', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908898652?h=8d35a51413');
    Axios.get('/api/app/quick_look/modulo7/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.ok) {
          this.setState({
            savings: response.data.data.savings,
            income: response.data.data.income,
            impact: response.data.data.impact,
          });
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 7.1</b>
              </p>
              <p className="mb-4">
                Give a quick approximation of the value outcomes of your good
                idea. Rate the value impact on these three systems.
              </p>
              <p className="mb-4">
                Please can you indicate on a scale of 1 to 10
                <br />
                1. The enterprise 1 = Adversely negative; 5 = same as now; 10 =
                Mind blowing
                <br />
                2. The eco-system 1 = Adversely negative; 5 = same as now; 10 =
                Mind blowing
                <br />
                3. Impact on Humans (actors) 1 = Adversely negative; 5 = same as
                now; 10 = Mind blowing
              </p>
              <p className="mb-4">
                What changes when your idea becomes real? What is different for
                your critical actors? Who saves money or increases their income?
                How does their experience change?
              </p>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  <span className="font-bold">System Type</span>
                </div>
                <div className="text-center" style={{ width: '30%' }}>
                  <span className="font-bold">Score</span>
                </div>
              </div>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  Enterprise
                </div>
                <div className="superCenter" style={{ width: '30%' }}>
                  <select
                    className="form-control w-50 mx-auto"
                    value={this.state.savings}
                    name="savings"
                    onChange={(e) => this.handleScore(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  Eco-system
                </div>
                <div className="superCenter" style={{ width: '30%' }}>
                  <select
                    className="form-control w-50 mx-auto"
                    value={this.state.income}
                    name="income"
                    onChange={(e) => this.handleScore(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  Impact on Humans
                </div>
                <div className="superCenter" style={{ width: '30%' }}>
                  <select
                    className="form-control w-50 mx-auto"
                    value={this.state.impact}
                    name="impact"
                    onChange={(e) => this.handleScore(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Agreement Chart
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the intensity to fix the root problem
                  </p>
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
