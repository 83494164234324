import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import RadarChart from '../../../../RadarChart/RadarChart';

export class Question6Startup extends Component {
  state = {
    problems: [],
    edit: false,
    ok: true,
    nextloading: false,
    loading: true,
    chart: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  makeRadar = () => {
    let labels = [];
    let serie1 = [];
    let serie2 = [];
    let serie3 = [];
    this.state.problems.forEach((element) => {
      labels.push(element.problem);
      serie1.push(element.data[0].score);
      serie2.push(element.data[1].score);
      serie3.push(element.data[2].score);
    });

    let series = [
      { name: this.state.problems[0].data[0].actor, data: serie1 },
      { name: this.state.problems[0].data[1].actor, data: serie2 },
      { name: this.state.problems[0].data[2].actor, data: serie3 },
    ];

    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e, key, index) => {
    let v = this.state.problems;
    v[key].data[index].score = parseInt(e.target.value);
    this.setState(
      {
        problems: v,
        edit: true,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/quick_look/modulo1/question6Startup', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('198c6681oz');
    Axios.get(
      '/api/app/quick_look/modulo1/question6Startup?id=' + this.props.id,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    ).then((response) => {
      if (response.data.ok) {
        this.setState(
          {
            loading: false,
            problems: response.data.problems,
          },
          () => {
            this.makeRadar();
          },
        );
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.6</b>
              </p>
              <p className="mb-4">
                In your new start-up, would each actor want to solve these
                problems? Rate their reaction.
                <br /> <br />
                Each would be rated on a score of 1-10, where 1 = strongly
                disagree and 10 = strongly agree that they want to solve these
                problems.
                <br /> <br />
                Does everyone see it the same? Do some people see it
                differently? Who has power? Do the people with power care?
              </p>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <div style={{ width: '40%' }}>
                  <span className="font-bold">Problem</span>
                </div>
                {this.state.problems[0].data.map((arg, index) => (
                  <div
                    key={`${index}${arg.actor}`}
                    className="text-center"
                    style={{ width: '20%' }}
                  >
                    <span className="font-bold">{arg.actor}</span>
                  </div>
                ))}
              </div>
              {this.state.problems.map((arg, index) => (
                <div
                  key={`${index}${arg.problem}`}
                  className="superCenter mx-auto"
                  style={{ width: '95%' }}
                >
                  <div style={{ width: '40%' }}>{arg.problem}</div>
                  {arg.data.map((arg2, i) => (
                    <div key={`${index}${arg2.actor}`} style={{ width: '20%' }}>
                      <select
                        className="form-control w-75 mx-auto"
                        value={arg2.score}
                        name={arg2.actor}
                        onChange={(e) => this.handleChange(e, index, i)}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </div>
                  ))}
                </div>
              ))}
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Problem Chart
                  </p>
                  {/* <p style={{fontSize:"13px", textAlign:"center"}}>Snap shot of the intensity to fix each problem</p> */}
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question6Startup;
