import React, { Component } from 'react';
import ApexCharts from 'apexcharts';

export class RadarChart extends Component {
  state = {
    options: {},
    series: [],
    id: '',
  };

  componentDidMount() {
    if (this.props.val.id && this.props.val.id !== '') {
      let labels = [];
      let max = 0;
      if (this.props.val.labels) {
        this.props.val.labels.forEach((element) => {
          if (element.length > max) max = element.length;
          if (element.length > 30)
            labels.push(element.substring(0, 30) + '...');
          else {
            labels.push(element);
          }
        });
      }

      let series = [];
      if (this.props.val.series) {
        series = this.props.val.series;
      }

      let colors = '';
      if (!this.props.val.colors) {
        colors = [
          '#0091ea',
          '#e50000',
          '#00e676',
          '#7c4dff',
          '#ffd600',
          '#ff6d00',
          '#c5e1a5',
          '#ea80fc',
          '#4dd0e1',
          '#795548',
        ];
      } else {
        colors = this.props.val.colors;
      }

      let id = '';
      if (this.props.val.id) {
        this.setState({ id: this.props.val.id });
        id = this.props.val.id;
      }

      let options = {
        colors,
        series,
        legend: {
          position: 'bottom',
          offsetY: 0,
        },
        chart: {
          id,
          type: 'radar',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          width: '100%',
          height: 600,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.1,
            opacityTo: 0,
            stops: [0, 0, 100],
          },
        },
        labels,
        yaxis: [
          {
            tickAmount: 10,
            min: 0,
            max: 10,
            forceNiceScale: true,
          },
        ],
      };
      this.setState(
        {
          options,
        },
        () => {
          var chart = new ApexCharts(
            document.querySelector(`#${this.state.id}`),
            options,
          );
          chart.render();
          chart.dataURI().then(({ imgURI }) => {
            if (this.props.graphic) {
              this.props.graphic(this.state.id, imgURI);
            }
          });
        },
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.val !== prevProps.val) {
      let labels = [];
      let max = 0;
      if (this.props.val.labels) {
        this.props.val.labels.forEach((element) => {
          if (element.length > max) max = element.length;
          if (element.length > 30)
            labels.push(element.substring(0, 30) + '...');
          else {
            labels.push(element);
          }
        });
      }

      let series = [];
      if (this.props.val.series) {
        series = this.props.val.series;
      }

      let colors = '';
      if (!this.props.val.colors) {
        colors = [
          '#0091ea',
          '#e50000',
          '#00e676',
          '#7c4dff',
          '#ffd600',
          '#ff6d00',
          '#c5e1a5',
          '#ea80fc',
          '#4dd0e1',
          '#795548',
        ];
      } else {
        colors = this.props.val.colors;
      }

      let id = '';
      if (this.props.val.id) {
        this.setState({ id: this.props.val.id });
        id = this.props.val.id;
      }

      let options = {
        colors,
        series,
        legend: {
          position: 'bottom',
          offsetY: 0,
        },
        chart: {
          id,
          type: 'radar',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
          width: '100%',
          height: 600,
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.1,
            opacityTo: 0,
            stops: [0, 0, 100],
          },
        },
        labels,
        yaxis: [
          {
            tickAmount: 10,
            min: 0,
            max: 10,
            forceNiceScale: true,
          },
        ],
      };
      this.setState(
        {
          options,
        },
        () => {
          var chart = new ApexCharts(
            document.querySelector(`#${this.state.id}`),
            options,
          );
          chart.render();
          chart.dataURI().then(({ imgURI }) => {
            if (this.props.graphic) {
              this.props.graphic(this.state.id, imgURI);
            }
          });
        },
      );
    }
  }

  render() {
    return <div id={this.state.id}></div>;
  }
}

export default RadarChart;
