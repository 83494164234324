import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question7 extends Component {
  state = {
    problems: [],
    ok: true,
    edit: false,
    loading: true,
    nextloading: false,
    chart: {},
    error: '',
  };

  handleBack = () => {
    this.props.next(6);
  };

  handleNext = () => {
    this.props.next(8);
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role);
      data.push(element.score);
    });
    let series = [];
    series.push({ name: '', data: data });

    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e, index) => {
    let v = this.state.problems;
    v[index].score = parseInt(e.target.value);
    this.setState(
      {
        edit: true,
        problems: v,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo1/question7', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(8);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(8);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo1/question7?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          prev: response.data.data.prev,
          problems: response.data.data.problems,
        });
        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.7</b>
              </p>
              <p className="mb-4">
                Would you or your client get meaningful support from each of the
                roles to fix the root problem? Please rate on a scale of 1 - 10
                where 1 = no support and 10 = full support.
              </p>
              <p className="mb-1">
                <b>The Root Problem</b>
              </p>

              <b>
                <blockquote>"{this.state.prev}"</blockquote>
              </b>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '85%' }}>
                  <span>
                    <b>Roles</b>
                  </span>
                </div>
                <div style={{ width: '15%' }}>
                  <span>
                    <b>Score 1 - 10</b>
                  </span>
                </div>
              </div>
              <hr style={{ width: '100%' }} />

              {this.state.problems.map((p, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '8px',
                  }}
                >
                  <div style={{ width: '80%' }}>
                    <span>{p.role}</span>
                  </div>
                  <div style={{ width: '10%', textAlign: 'center' }}>
                    <select
                      className="form-control m-0"
                      value={p.score}
                      name={p.role}
                      id={p.role}
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              ))}
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Who will support you to fix the problem?
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of who in the organisation will support the good idea
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question7;
