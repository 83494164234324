import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  componentDidMount() {
    this.props.changeVideo('932381368?h=8873e55556');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION SEVEN = VALUE PROPOSITION</b>
        </p>

        <p className="mb-4">
          By getting into conversation with others, you need to answer this
          question:
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            What would the likely value proposition be to the customer,
            enterprise or your start-up, and the interconnected world (or market
            sector) that my good idea will operate within to adopt a good idea?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now once the critical actors comprehend that the good idea can
            reduce the pain and, in turn, generate meaningful value for their
            customers and stakeholders (as well as themselves), they will then
            start to drive the process of discovery. This provides me with the
            first goal: to acquire sufficient practical support to move forward.
            This would involve undertaking the DEEP DIVE App to map out the
            opportunity, identifying the value-generation, capture, validation,
            tokenization and trading opportunities to justify the stakeholder
            investment to design, develop and launch my good idea.
          </span>
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
