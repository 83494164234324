import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import RadarChart from '../../../RadarChart/RadarChart';
import Spinner from 'react-bootstrap/Spinner';

export class Question4 extends Component {
  state = {
    problems: [],
    edit: false,
    roles: [],
    ok: true,
    chart: {},
    nextloading: false,
    loading: true,
    chartI: {},
    error: '',
  };

  handleBack = () => {
    this.props.next(3);
  };

  handleNext = () => {
    this.props.next(5);
  };

  makeRadar = () => {
    let labels = [];
    this.state.problems.forEach((element) => {
      labels.push(element.problem);
    });

    let series = [];
    this.state.roles.forEach((element) => {
      if (this.state[element].length > 0) {
        series.push({ name: element, data: this.state[element] });
      }
    });

    let labelsI = [];
    series.forEach((element) => {
      labelsI.push(element.name);
    });
    let seriesI = [];
    labels.forEach((element, index) => {
      let nuevo = [];
      series.forEach((e) => {
        nuevo.push(e.data[index]);
      });
      seriesI.push({ name: element, data: nuevo });
    });
    this.setState({
      chart: { labels, series },
      chartI: { labels: labelsI, series: seriesI },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    console.log('e.target.name', e.target.name);

    v[key].roles.forEach((element) => {
      if (element.role === e.target.name) {
        element.score = parseInt(e.target.value);
      }
    });
    let nuevo = this.state[e.target.name];
    nuevo[key] = parseInt(e.target.value);
    this.setState(
      {
        problems: v,
        edit: true,
        [e.target.name]: nuevo,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problem: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo1/question4', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(5);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(5);
    }
  };

  getValue = (role, problem) => {
    let x = 1;
    problem.roles.forEach((element) => {
      if (element.role === role) {
        x = element.score;
      }
    });
    return x;
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo1/question4?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        console.log('response.data', response.data);
        this.setState({ loading: false });
        response.data.roles.forEach((element) => {
          let role = element.value;
          this.setState({
            roles: [...this.state.roles, role],
            [role]: [],
          });
        });
        this.setState({
          roles: [...this.state.roles, 'Client'],
          Client: [],
        });
        response.data.data.forEach((element) => {
          element.roles.forEach((e) => {
            let role = e.role;
            this.setState({
              [role]: [...this.state[role], e.score],
            });
          });
          this.setState({
            problems: [...this.state.problems, element],
          });
          response.data.client.forEach((c) => {
            if (c.problem === element.problem) {
              this.setState({
                Client: [...this.state.Client, c.score],
              });
            }
          });
        });
        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.4</b>
              </p>
              <p className="mb-4">
                How would the actors who hold the following roles rate the need
                to fix each of the stated problems above their existing
                commitments? Please rate the intensity of the need to fix each
                problem on a scale of 1 to 10 where 1 = not urgent and 10 =
                urgent.
              </p>
              <table style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th
                      key={0}
                      className="font-bold"
                      style={{
                        padding: '0',
                        textAlign: 'left',
                        fontSize: '16px',
                      }}
                    >
                      Problem
                    </th>
                    {this.state.roles.map((e, i) =>
                      e !== 'Client' ? (
                        <th
                          key={i + 1}
                          className="font-bold"
                          style={{
                            fontSize: '16px',
                            textAlign: 'left',
                            padding: '0 10px',
                          }}
                        >
                          {e}
                        </th>
                      ) : null,
                    )}
                  </tr>
                </thead>
                <tbody>
                  {this.state.problems.map((p, Pindex) => (
                    <tr key={Pindex}>
                      <td className="text-left">{p.problem}</td>
                      {this.state.roles.map((r, Rindex) =>
                        r !== 'Client' ? (
                          <td key={Rindex}>
                            <select
                              className="form-control w-50 mx-auto"
                              value={this.getValue(r, p)}
                              name={r}
                              onChange={(e) => this.handleChange(e, Pindex)}
                            >
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                            </select>
                          </td>
                        ) : null,
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                The Enterprise Actors experience of each problem
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of which problems effect the most actors
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                The Enterprise Actors experience of each problem
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of which actors experience the most amount of pain
              </p>
              <RadarChart val={this.state.chartI} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question4;
