import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import './question6.css';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question6 extends Component {
  state = {
    problems: [],
    edit: false,
    prev: '',
    nextloading: false,
    loading: true,
    ok: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(5);
  };

  handleNext = () => {
    this.props.next(7);
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    v[key] = { problem: v[key].problem, option: e.target.value };
    this.setState({
      edit: true,
      problems: v,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problem: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo1/question6', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(7);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(7);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo1/question6?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          prev: response.data.data.prev,
          problems: response.data.data.problems,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <div className="Q6">
              <p className="question-title">
                <b>Question 1.6</b>
              </p>
              <p className="mb-4">
                Which problems are caused or exacerbated by the root problem?
              </p>
              <p className="mb-4">
                <b>The Root Problem</b>
              </p>

              <b>
                <blockquote>"{this.state.prev}"</blockquote>
              </b>

              <table style={{ width: '100%' }}>
                <thead></thead>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <tr key={index}>
                      <td style={{ width: '60%' }}>{arg.problem}</td>
                      <td style={{ width: '8%' }}>
                        <div className="radio-div">
                          {arg.option === 'yes' ? (
                            <input
                              className="m-auto form-check-input position-static"
                              type="radio"
                              name={index}
                              id={index}
                              value="yes"
                              onChange={(e) => this.handleChange(e, index)}
                              defaultChecked
                            />
                          ) : (
                            <input
                              className="m-auto form-check-input position-static"
                              type="radio"
                              name={index}
                              id={index}
                              value="yes"
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          )}
                          <label className="form-check-label" htmlFor={index}>
                            Yes
                          </label>
                        </div>
                      </td>
                      <td style={{ width: '8%' }}>
                        <div className="radio-div">
                          {arg.option === 'no' ? (
                            <input
                              className="m-auto form-check-input position-static"
                              type="radio"
                              name={index}
                              id={`${index}_`}
                              value="no"
                              onChange={(e) => this.handleChange(e, index)}
                              defaultChecked
                            />
                          ) : (
                            <input
                              className="m-auto form-check-input position-static"
                              type="radio"
                              name={index}
                              id={`${index}_`}
                              value="no"
                              onChange={(e) => this.handleChange(e, index)}
                            />
                          )}
                          <label
                            className="form-check-label"
                            htmlFor={`${index}_`}
                          >
                            No
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </div>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question6;
