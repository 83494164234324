import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  componentDidMount() {
    this.props.changeVideo('932374689?h=e4f24043be');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION FIVE = WHAT IF? (CAPABILITY)</b>
        </p>

        <p className="mb-4">
          Try to role-play or have conversations with others to answer this
          question:
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            What sort of capability (solution) would have to be adopted to
            eliminate this pain, and what would the value be?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can discover the characteristics of any solution and start to
            interpret what elements would be required in the solution set.
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can see the most intelligent, innovative, cost-effective,
            risk-reducing solution to remove this pain and generate value.
          </span>
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
