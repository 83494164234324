import React, { Component } from 'react';
import M1Report from './Module1/Report';
import M2Report from './Module2/Report';
import M3Report from './Module3/Report';
import M4Report from './Module4/Report';
import M5Report from './Module5/Report';
import M6Report from './Module6/Report';
import M7Report from './Module7/Report';
import M8Report from './Module8/Report';
import { saveAs } from 'file-saver';
import { Document, Media, Packer, Paragraph, Header, Footer } from 'docx';
import { styles } from './DocxStyles';
import { getToken } from '../../../config/index';
import Axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
// import stephenLogo from '../../../assets/img/landing/vectores-07.png';
import { stephenLogo, stephenLogo2 } from '../img';

export class Report extends Component {
  state = {
    modulo1: false,
    modulo2: false,
    modulo3: false,
    modulo4: false,
    modulo5: false,
    modulo6: false,
    modulo7: false,
    modulo8: false,
    modulo1r: false,
    modulo2r: false,
    modulo3r: false,
    modulo4r: false,
    modulo5r: false,
    modulo6r: false,
    modulo7r: false,
    modulo8r: false,
    listo: false,
    graphics: {},
  };

  graphic = (id, img) => {
    let x = this.state.graphics;
    x[id] = img;
    this.setState({
      graphics: x,
    });
  };

  handleLoad = (modulo) => {
    this.setState({
      [modulo]: true,
    });
  };

  handleBack = () => {
    this.props.next(27);
  };

  handleNext = () => {
    this.props.next(29);
  };

  generateDOCX = () => {
    Axios.get('/api/app/deep_dive/getProjectName?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      this.setState(
        {
          username: response.data.username,
          email: response.data.email,
          name: response.data.name,
          listo: true,
        },
        () => {
          // MODULO 1
          Axios.get('/api/app/deep_dive/modulo1/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo1: response.data,
                  fill1: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo1: {},
                fill1: false,
              });
            }
          });
          // MODULO 2
          Axios.get('/api/app/deep_dive/modulo2/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo2: response.data,
                  fill2: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo2: {},
                fill2: false,
              });
            }
          });
          // MODULO 3
          Axios.get('/api/app/deep_dive/modulo3/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (!response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo3: {},
                  fill3: false,
                });
              }, 3000);
            } else {
              let x = response.data;
              let pains = [];
              response.data.data.forEach((element) => {
                element.data.forEach((e) => {
                  if (pains.indexOf(e.value) === -1) pains.push(e.value);
                });
              });
              x.pains = pains;
              setTimeout(() => {
                this.setState({
                  modulo3: x,
                  fill3: true,
                });
              }, 3000);
            }
          });
          // MODULO 4
          Axios.get('/api/app/deep_dive/modulo4/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo4: response.data,
                  fill4: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo4: {},
                fill4: false,
              });
            }
          });

          // MODULO 5
          Axios.get('/api/app/deep_dive/modulo5/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo5: response.data,
                  fill5: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo5: {},
                fill5: false,
              });
            }
          });

          // MODULO 6
          Axios.get('/api/app/deep_dive/modulo6/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo6: response.data,
                  fill6: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo6: {},
                fill6: false,
              });
            }
          });

          // MODULO 7
          Axios.get('/api/app/deep_dive/modulo7/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo7: response.data,
                  fill7: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo7: {},
                fill7: false,
              });
            }
          });

          // MODULO 8
          Axios.get('/api/app/deep_dive/modulo8/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo8: response.data,
                  fill8: true,
                });
              }, 3000);
            } else {
              this.setState({
                modulo8: {},
                fill8: false,
              });
            }
          });
        },
      );
    });
  };

  download = () => {
    const doc = new Document({
      creator: 'Digital value Capture',
      description: 'Final report',
      title: this.state.name,
    });

    let headerLogo = Media.addImage(
      doc,
      Uint8Array.from(atob(stephenLogo), (c) => c.charCodeAt(0)),
      200,
      50,
    );
    let footerLogo = Media.addImage(
      doc,
      Uint8Array.from(atob(stephenLogo2), (c) => c.charCodeAt(0)),
      50,
      50,
    );

    let hoja = {
      headers: {
        default: new Header({
          children: [new Paragraph(styles.headerLogo(headerLogo))],
        }),
      },
      footers: {
        default: new Footer({
          children: styles.footerLogo(
            footerLogo,
            'https://digital-value-capture.teachable.com',
            'admin@stephenalexander.com.au',
          ),
        }),
      },
      children: [],
    };

    let M1chart1 = '';
    if (this.state.graphics.M1chart1) {
      M1chart1 = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M1chart1.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M1chart2 = '';
    if (this.state.graphics.M1chart2) {
      M1chart2 = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M1chart2.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M1SupportChart = '';
    if (this.state.graphics.M1SupportChart) {
      M1SupportChart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1SupportChart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M2chart1 = '';
    if (this.state.graphics.M2chart1) {
      M2chart1 = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M2chart1.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M4chart = '';
    if (this.state.graphics.M4chart) {
      M4chart = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M4chart.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M6chart = '';
    if (this.state.graphics.M6chart) {
      M6chart = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M6chart.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M7chart = '';
    if (this.state.graphics.M7chart) {
      M7chart = Media.addImage(
        doc,
        Uint8Array.from(atob(this.state.graphics.M7chart.split(',')[1]), (c) =>
          c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let pagina = [new Paragraph(''), new Paragraph('')];

    pagina.push(
      new Paragraph(
        styles.textCenter(
          this.state.username +
            ' - ' +
            this.state.email +
            ' - ' +
            this.state.name,
        ),
      ),
    );
    pagina.push(new Paragraph(styles.report('Final Report')));

    //MODULO 1
    if (this.state.fill1) {
      pagina.push(new Paragraph(styles.modulo('1. Organisational Problems:')));

      // Q1.1
      pagina.push(new Paragraph(styles.titulo('Problem Index')));
      this.state.modulo1.data.problems.forEach((p) => {
        pagina.push(new Paragraph(styles.list2('Problem', p.value)));
        pagina.push(new Paragraph(styles.list2('Description', p.description)));
        pagina.push(new Paragraph(''));
      });

      hoja.children = pagina;
      doc.addSection(hoja);

      pagina = [new Paragraph(''), new Paragraph('')];

      // Q1.2
      pagina.push(new Paragraph(styles.titulo('The Enterprise Radar Map')));
      pagina.push(
        new Paragraph(
          styles.chartTitle('The Enterprise Actors experience of each problem'),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of which problems effect the most actors',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M1chart1)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q1.3
      pagina.push(
        new Paragraph(
          styles.chartTitle('The Enterprise Actors experience of each problem'),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of which actors experience the most amount of pain',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M1chart2)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q1.4
      pagina.push(new Paragraph(styles.titulo('The root problem description')));
      pagina.push(new Paragraph(this.state.modulo1.data.root));

      // Q1.5
      pagina.push(
        new Paragraph(styles.titulo('Problems caused by the root problem')),
      );
      this.state.modulo1.data.caused.forEach((p) => {
        pagina.push(new Paragraph(styles.list2('Problem', p.problem)));
        pagina.push(new Paragraph(styles.list2('Value', p.option)));
        pagina.push(new Paragraph(''));
      });
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q1.6
      pagina.push(
        new Paragraph(styles.titulo('Who Will Support The Good Idea?')),
      );
      pagina.push(new Paragraph(styles.imagen(M1SupportChart)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('1. Organisational Problems')));
      pagina.push(new Paragraph('You need to complete section 1'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 2
    if (this.state.fill2) {
      pagina.push(new Paragraph(styles.modulo('2. Eco System Problems')));

      // Q2.1
      pagina.push(new Paragraph(styles.chartTitle('Eco System Radar Map')));
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of the intensity to fix the root problem',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2chart1)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('2. Eco System Problems')));
      pagina.push(new Paragraph('You need to complete section 2'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 3
    if (this.state.fill3) {
      pagina.push(new Paragraph(styles.modulo('3. Pain Points')));

      // Q3.1
      pagina.push(new Paragraph(styles.titulo('Pain Criteria List')));
      this.state.modulo3.pains.forEach((p) => {
        pagina.push(new Paragraph(styles.list(p)));
      });
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q3.2
      pagina.push(new Paragraph(styles.titulo('Pain Points Radar Maps')));
      this.state.modulo3.data.forEach((p) => {
        let x = Media.addImage(
          doc,
          Uint8Array.from(
            atob(
              this.state.graphics[p.role.split(/[^A-Za-z0-9]/).join('')].split(
                ',',
              )[1],
            ),
            (c) => c.charCodeAt(0),
          ),
          600,
          500,
        );
        pagina.push(new Paragraph(styles.chartTitle(p.role)));
        pagina.push(
          new Paragraph(
            styles.textCenter(
              'Snap shot of the pain experienced as a consequence of the identified root problem',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(x)));
        hoja.children = pagina;
        doc.addSection(hoja);
        // doc.addSection({
        // 		children: pagina,
        // });
        pagina = [new Paragraph(''), new Paragraph('')];
      });
    } else {
      pagina.push(new Paragraph(styles.modulo('3. Pain Points')));
      pagina.push(new Paragraph('You need to complete section 3'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 4
    if (this.state.fill4) {
      pagina.push(new Paragraph(styles.modulo('4. A Typical Scenario')));

      // Q4.1
      pagina.push(
        new Paragraph(styles.titulo('The typical scenario description')),
      );
      pagina.push(new Paragraph(this.state.modulo4.escenario.description));

      // Q4.2
      pagina.push(new Paragraph(styles.titulo('Scenario Pain Points')));
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of the pain experienced by the key actors in the scenario and what they need it to be at a minimum in order to cope',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M4chart)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('4. A Typical Scenario')));
      pagina.push(new Paragraph('You need to complete section 4'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 5
    if (this.state.fill5) {
      pagina.push(new Paragraph(styles.modulo('5. What if? Capability')));

      // Q5.1
      pagina.push(new Paragraph(styles.titulo('Clients Solution Set')));
      this.state.modulo5.data.forEach((p) => {
        pagina.push(new Paragraph(styles.list2('Characteristic', p.value)));
        pagina.push(new Paragraph(styles.list2('Capabilities', p.description)));
        pagina.push(new Paragraph(''));
      });

      // Q5.2
      pagina.push(
        new Paragraph(styles.titulo('“in principle” business characteristics')),
      );
      this.state.modulo5.dataq2.forEach((p) => {
        pagina.push(new Paragraph(styles.list2('Characteristic', p.value)));
        pagina.push(
          new Paragraph(
            styles.list2(
              'Description of the requirement’s contribution',
              p.description,
            ),
          ),
        );
        pagina.push(new Paragraph(''));
      });
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('5. What if? Capability')));
      pagina.push(new Paragraph('You need to complete section 5'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 6
    if (this.state.fill6) {
      pagina.push(new Paragraph(styles.modulo('6. A Good Idea')));

      // Q6.1
      pagina.push(new Paragraph(styles.titulo('The good idea description')));
      pagina.push(new Paragraph(this.state.modulo6.idea.description));

      // Q6.2
      pagina.push(new Paragraph(styles.titulo('To Be Radar Map')));
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of the pain experienced by the key actors in the scenario and where they feel their pain would be (to be) if the good idea was adopted',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M6chart)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [];
    } else {
      pagina.push(new Paragraph(styles.modulo('6. A Good Idea')));
      pagina.push(new Paragraph('You need to complete section 6'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 7
    if (this.state.fill7) {
      pagina.push(new Paragraph(styles.modulo('7. Value Measurements')));

      // Q7.1
      pagina.push(
        new Paragraph(styles.titulo('Estimate will be saved and made')),
      );

      this.state.modulo7.money.forEach((p) => {
        pagina.push(new Paragraph(styles.list2('Role', p.role)));
        pagina.push(new Paragraph(styles.list2('Saved', p.saved)));
        pagina.push(new Paragraph(styles.list2('Made', p.made)));
        pagina.push(new Paragraph(''));
      });

      // Q7.2
      pagina.push(new Paragraph(styles.titulo('Could Be Radar Map')));
      pagina.push(
        new Paragraph(
          styles.textCenter(
            'Snap shot of the value experienced by the key actors in the scenario if the good idea is adopted',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M7chart)));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('7. Value Measurements')));
      pagina.push(new Paragraph('You need to complete section 7'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 8
    if (this.state.fill8) {
      pagina.push(new Paragraph(styles.modulo('8. Value Proposition')));

      // Q8.1
      pagina.push(
        new Paragraph(styles.titulo('What is the value of your good idea?')),
      );

      pagina.push(
        new Paragraph(
          styles.list2(
            'For each of the actors from the scenario',
            this.state.modulo8.data.actorsScenario,
          ),
        ),
      );
      pagina.push(new Paragraph(''));
      pagina.push(
        new Paragraph(
          styles.list2(
            'For each of the roles of the enterprise',
            this.state.modulo8.data.rolesEcosystem,
          ),
        ),
      );
      pagina.push(new Paragraph(''));
      pagina.push(
        new Paragraph(
          styles.list2(
            'For each of the roles of the eco-system',
            this.state.modulo8.data.rolesEnterprise,
          ),
        ),
      );
      pagina.push(new Paragraph(''));
      pagina.push(
        new Paragraph(
          styles.list2(
            'For the people who will fund your good idea',
            this.state.modulo8.data.whoFund,
          ),
        ),
      );
      pagina.push(new Paragraph(''));

      // Q8.2
      pagina.push(
        new Paragraph(
          styles.titulo('What question would they have to ask to find you?'),
        ),
      );
      pagina.push(new Paragraph(this.state.modulo8.data.question));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('8. Value Proposition')));
      pagina.push(new Paragraph('You need to complete section 8'));
      hoja.children = pagina;
      doc.addSection(hoja);
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${this.state.name} - Report.docx`);
    });
  };

  render() {
    return (
      <>
        {this.state.modulo1r === true &&
        this.state.modulo2r === true &&
        this.state.modulo3r === true &&
        this.state.modulo4r === true &&
        this.state.modulo5r === true &&
        this.state.modulo6r === true &&
        this.state.modulo7r === true &&
        this.state.modulo8r === true
          ? this.state.listo
            ? null
            : this.generateDOCX()
          : null}
        <p className="question-title text-center">
          <b>Final Report</b>
        </p>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>1. Organisational Problems</b>
          </p>
          <M1Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo1r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>2. Eco System Problems</b>
          </p>
          <M2Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo2r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>3. Pain Points</b>
          </p>
          <M3Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo3r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>4. A Typical Scenario</b>
          </p>
          <M4Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo4r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>5. What if? Capability</b>
          </p>
          <M5Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo5r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>6. A Good Idea</b>
          </p>
          <M6Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo6r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>7. Value Measurements</b>
          </p>
          <M7Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo7r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>8. Value Proposition</b>
          </p>
          <M8Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo8r');
            }}
            graphic={this.graphic}
          />
        </div>
        <hr />
        <div
          className="hideButtons"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>

          {this.state.modulo1 !== false &&
          this.state.modulo2 !== false &&
          this.state.modulo3 !== false &&
          this.state.modulo4 !== false &&
          this.state.modulo5 !== false &&
          this.state.modulo6 !== false &&
          this.state.modulo7 !== false &&
          this.state.modulo8 !== false ? (
            <button
              className="btn my-4 p-3"
              style={{
                color: 'white',
                backgroundColor: '#cea977',
                borderRadius: '10px',
              }}
              onClick={this.download}
            >
              <i className="fas fa-arrow-down"></i> Download Report
            </button>
          ) : (
            <button
              className="btn my-4 p-3"
              style={{
                color: 'white',
                backgroundColor: '#cea977',
                borderRadius: '10px',
              }}
              disabled
            >
              <Spinner animation="border" size="sm" variant="light" />{' '}
              Generating Report
            </button>
          )}
        </div>
      </>
    );
  }
}

export default Report;
