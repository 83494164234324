import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './QuickLook.css';
import Footer from '../../components/Footer/Footer';
import Divisor from '../../assets/img/landing/vectores-11.png';
import Chart1 from '../../assets/img/landing/vectores-23.png';
import Chart2 from '../../assets/img/landing/vectores-24.png';
import toastr from 'toastr';

export class QuickLook extends Component {
  redirectUrl = () => {
    if (this.props.isPaid) {
      return '/quick_look/projects';
    } else return '/purchase_module/quick_look';
  };

  handleLink = () => {
    if (window.innerWidth < 767) {
      toastr.remove();
      toastr.warning(
        'Dashboard is not meant to be used in phones, please use your desktop or tablet',
      );
    } else {
      window.location = this.redirectUrl();
    }
  };

  render() {
    return (
      <>
        <NavBar auth={this.props.auth} quicklook={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">
              Quick Look <br /> Will it meet the needs?
            </h3>
          </div>
        </div>
        <div className="p-5 problem-section">
          <div className="row problem-text">
            <div className="col-sm-12 col-md-6 mb-5">
              <p>
                Please think of the Quick Look as a ready reckoner that quickly
                works out the odds of getting the support you need to develop
                your good idea and the required adoption of its use.
                <br />
                <br />
                It helps you find the needle in the haystack, the one good idea
                out of many that people actually care about enough to support
                you.
                <br />
                <br />
                You get support when you can clearly demonstrate that your Good
                Idea will fix the root cause of the problems that keep them
                awake at night, the problems that consume them and their
                resources and stop them from getting on with core business.
                <br />
                <br />
                Imagine being able first to qualify your confidence in your Good
                Idea and then develop a 60-second pitch that quantifies their
                willingness to support you.
                <br />
                <br />
                How compelling would that pitch be?
              </p>
              {this.props.auth ? (
                <button
                  className="btn mx-3 deepdive-buttons"
                  onClick={this.handleLink}
                >
                  {this.props.isPaid ? 'Go to Dashboard' : 'Get it now'}
                </button>
              ) : null}
            </div>
          </div>
        </div>

        <img
          src={Divisor}
          alt="barra-divisora"
          className="w-50 d-block problem-barra-divisora"
          style={{ marginBottom: '10%', marginTop: '-5%' }}
        />

        <div className="row p-0 mx-0 mb-4" style={{ marginTop: '-5%' }}>
          <div className="w-75 font-bold" style={{ marginLeft: '8%' }}>
            The Quadrant Chart
          </div>
          <div className="col-5 ml-auto">
            <img src={Chart1} alt="chart 1" className="w-100 mx-1 my-5" />
          </div>
          <div className="col-5 mr-auto">
            <img src={Chart2} alt="chart 2" className="w-100 mx-1 my-5" />
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default QuickLook;
