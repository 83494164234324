import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Modules from '../../../components/Modules/DeepDive/Modules';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { Row, Col } from 'react-grid-system';
import './dashboard.css';
import Sidebar from '../../../components/Sidebar/DeepDive/Sidebar';
import { getToken } from '../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';

export class Dashboard extends Component {
  state = {
    chart: {},
    title: '1. Organisational Problems',
    progress: 0,
    displayChart: 0,
    showModal: false,
    option: 1,
    project: '',
  };

  changeOption = (val) => {
    let titulo = '';
    if (val >= 1 && val <= 8) {
      titulo = '1. Organisational Problems';
    }
    if (val >= 9 && val <= 11) {
      titulo = '2. Eco System Problems';
    }
    if (val >= 12 && val <= 13) {
      titulo = '3. Pain Points';
    }
    if (val >= 14 && val <= 17) {
      titulo = '4. A Typical Scenario';
    }
    if (val >= 18 && val <= 20) {
      titulo = '5. What If Capability';
    }
    if (val >= 21 && val <= 23) {
      titulo = '6. A Good Idea';
    }
    if (val >= 24 && val <= 26) {
      titulo = '7. Value Measurements';
    }
    if (val >= 27 && val <= 29) {
      titulo = '8. Value Proposition';
    }
    this.setState({
      option: val,
      displayChart: 0,
      title: titulo,
      progress: ((val * 100) / 29).toFixed(1),
    });
    if (val === 31) {
      window.location = '/deep_dive/projects';
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleModal = () => {
    this.setState({ showModal: true });
  };

  componentDidMount() {
    let data = {
      id: this.props.match.params.id,
    };
    Axios.post('/api/app/deep_dive/getProject', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          project: response.data.name,
        });
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  }

  render() {
    return this.state.option < 31 ? (
      <>
        <div className="dashboard">
          <Sidebar
            project={this.state.project}
            changeOption={this.changeOption}
            option={this.state.option}
          />
          <div className="main">
            <Row className="mt-3">
              <Col md={12}>
                <div className="question-div dash-div">
                  <div
                    className="question-div-header"
                    style={{
                      color: '#cea977',
                      borderBottom: '1px solid #a1a1a1',
                      padding: '0 20px',
                      height: '30px',
                      background: '#fff',
                      position: 'sticky',
                      top: '0',
                      zIndex: '99',
                    }}
                  >
                    <p style={{ fontWeight: '600', paddingTop: '2px' }}>
                      {this.state.title}{' '}
                      <span
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={this.handleModal}
                      >
                        Video Advice{' '}
                        <i className="fas fa-question-circle ml-1"></i>
                      </span>
                      <Modal
                        show={this.state.showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={this.handleClose}
                      >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body
                          style={{ height: '50vh', width: '100%' }}
                        ></Modal.Body>
                      </Modal>
                    </p>
                  </div>
                  <div className="progressBar">
                    <ProgressBar number={this.state.progress} />
                  </div>
                  <div className="question-content">
                    <Modules
                      id={this.props.match.params.id}
                      changeOption={this.changeOption}
                      option={this.state.option}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default Dashboard;
