import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import Modules from '../../../components/Modules/QuickLook/Modules';
import ProgressBar from '../../../components/ProgressBar/ProgressBar';
import { Row, Col } from 'react-grid-system';
import './dashboard.css';
import Sidebar from '../../../components/Sidebar/QuickLook/Sidebar';
import { getToken } from '../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';

export class Dashboard extends Component {
  state = {
    chart: {},
    startup: false,
    title: '1. Enterprise Problems',
    progress: 0,
    displayChart: 0,
    showModal: false,
    option: 1,
    project: '',
    video: '',
  };

  setTitle = (val) => {
    let titulo = '';
    if (
      (this.state.startup && val >= 1 && val <= 10) ||
      (!this.state.startup && val >= 1 && val <= 9)
    ) {
      titulo = '1. Enterprise Problems';
    }
    if (val >= 11 && val <= 18) {
      titulo = '2. Eco System Problems';
    }
    if (val >= 19 && val <= 22) {
      titulo = '3. Pain Criteria Types';
    }
    if (val >= 23 && val <= 27) {
      titulo = '4. A Typical Scenario';
    }
    if (val >= 28 && val <= 31) {
      titulo = '5. What If? (Capability)';
    }
    if (val >= 32 && val <= 35) {
      titulo = '6. A Good Idea';
    }
    if (val >= 36 && val <= 40) {
      titulo = '7. Value Proposition';
    }
    if (val === 41) {
      titulo = 'Final Report';
    }

    this.setState({
      option: val,
      displayChart: 0,
      title: titulo,
      progress: ((val * 100) / 41).toFixed(1),
    });
    if (val === 42) {
      window.location = '/quick_look/projects';
    }
  };

  changeOptionSidebar = (val) => {
    this.setTitle(val);
  };

  changeOptionModule = (valor) => {
    let val = this.state.option + valor;
    this.setTitle(val);
  };

  handleClose = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  changeVideo = (id) => {
    this.setState({ video: id });
  };

  handleModal = () => {
    this.setState({
      showModal: true,
    });
  };

  startUp = (val) => {
    this.setState({ startup: val });
  };

  componentDidMount() {
    let data = {
      id: this.props.match.params.id,
    };
    Axios.post('/api/app/quick_look/getProject', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          project: response.data.name,
          startup: response.data.startup,
        });
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  }

  render() {
    return this.state.option < 42 ? (
      <>
        <div className="dashboard">
          <Sidebar
            project={this.state.project}
            changeOption={this.changeOptionSidebar}
            option={this.state.option}
            startup={this.state.startup}
          />
          <div className="main">
            <Row className="mt-3">
              <Col md={12}>
                <div className="question-div dash-div">
                  <div
                    className="question-div-header"
                    style={{
                      color: '#cea977',
                      borderBottom: '1px solid #a1a1a1',
                      padding: '0 20px',
                      height: '30px',
                      background: '#fff',
                      position: 'sticky',
                      top: '0',
                      zIndex: '99',
                    }}
                  >
                    <p style={{ fontWeight: '600', paddingTop: '2px' }}>
                      {this.state.title}{' '}
                      <span
                        style={{ float: 'right', cursor: 'pointer' }}
                        onClick={this.handleModal}
                      >
                        Video Advice{' '}
                        <i className="fas fa-question-circle ml-1"></i>
                      </span>
                      <Modal
                        show={this.state.showModal}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={this.handleClose}
                      >
                        <Modal.Body
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            className="relative"
                            style={{
                              height: '349px',
                              width: '620px',
                            }}
                          >
                            <iframe
                              src={`https://player.vimeo.com/video/${this.state.video}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                              frameBorder="0"
                              allow="autoplay; fullscreen; picture-in-picture"
                              title="Q1.1"
                              className="absolute"
                              style={{
                                height: '100%',
                                width: '100%',
                                top: '0',
                                left: '0',
                              }}
                            ></iframe>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </p>
                  </div>
                  <div className="progressBar">
                    <ProgressBar number={this.state.progress} />
                  </div>
                  <div className="question-content">
                    <Modules
                      id={this.props.match.params.id}
                      changeStartUp={this.startUp}
                      changeOption={this.changeOptionModule}
                      option={this.state.option}
                      startup={this.state.startup}
                      changeVideo={this.changeVideo}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default Dashboard;
