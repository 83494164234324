import React, { Component } from 'react';
import M1ReportEnterprise from './Module1/enterprise/Report';
import M1ReportStartup from './Module1/startup/Report';
import M2Report from './Module2/Report';
import M3Report from './Module3/Report';
import M4Report from './Module4/Report';
import M5Report from './Module5/Report';
import M6Report from './Module6/Report';
import M7Report from './Module7/Report';
import ScatterChartReport from '../../ScatterChart/ScatterChartReport';

import { saveAs } from 'file-saver';
import { Document, Media, Packer, Paragraph, Header, Footer } from 'docx';
import { styles } from './DocxStyles';

import Axios from 'axios';
import { getToken } from '../../../config/index';
import html2canvas from 'html2canvas';
import Spinner from 'react-bootstrap/Spinner';
import { stephenLogo, stephenLogo2 } from '../img';

export class Report extends Component {
  state = {
    modulo1: false,
    modulo2: false,
    modulo3: false,
    modulo4: false,
    modulo5: false,
    modulo6: false,
    modulo7: false,
    modulo1r: false,
    modulo2r: false,
    modulo3r: false,
    modulo4r: false,
    modulo5r: false,
    modulo6r: false,
    modulo7r: false,
    scatterChart: false,
    listo: false,
    graphics: {},
  };

  graphic = (id, img) => {
    let x = this.state.graphics;
    x[id] = img;
    let boo = false;
    if (id === 'scatterChart') boo = true;
    this.setState({
      graphics: x,
      scatterChart: boo,
    });
  };

  handleLoad = (modulo) => {
    this.setState({
      [modulo]: true,
    });
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleNext = () => {
    this.props.next(1);
  };

  generateDOCX = () => {
    Axios.get('/api/app/quick_look/getProjectStartup?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      this.setState(
        {
          username: response.data.username,
          email: response.data.email,
          startup: response.data.data,
          name: response.data.name,
          listo: true,
        },
        () => {
          // MODULO 1
          if (this.state.startup) {
            Axios.get(
              '/api/app/quick_look/modulo1/reportStartup?id=' + this.props.id,
              {
                headers: { Authorization: 'Bearer ' + getToken() },
              },
            ).then((response) => {
              if (response.data.ok) {
                setTimeout(() => {
                  this.setState({
                    modulo1: response.data.data,
                    fill1: true,
                  });
                }, 5000);
              } else {
                setTimeout(() => {
                  this.setState({
                    modulo1: {},
                    fill1: false,
                  });
                }, 5000);
              }
            });
          } else {
            Axios.get(
              '/api/app/quick_look/modulo1/reportEnterprise?id=' +
                this.props.id,
              {
                headers: { Authorization: 'Bearer ' + getToken() },
              },
            ).then((response) => {
              if (response.data.ok) {
                setTimeout(() => {
                  this.setState({
                    modulo1: response.data.data,
                    fill1: true,
                  });
                }, 5000);
              } else {
                setTimeout(() => {
                  this.setState({
                    modulo1: {},
                    fill1: false,
                  });
                }, 5000);
              }
            });
          }
          // MODULO 1

          // MODULO 2
          Axios.get('/api/app/quick_look/modulo2/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              setTimeout(() => {
                this.setState({
                  modulo2: response.data.data,
                  fill2: true,
                });
              }, 5000);
            } else {
              setTimeout(() => {
                this.setState({
                  modulo2: {},
                  fill2: false,
                });
              }, 5000);
            }
          });
          // MODULO 2

          // MODULO 3
          Axios.get('/api/app/quick_look/modulo3/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                modulo3: response.data.data,
                fill3: true,
              });
            } else {
              setTimeout(() => {
                this.setState({
                  modulo3: {},
                  fill3: false,
                });
              }, 5000);
            }
          });
          // MODULO 3

          // MODULO 4
          Axios.get('/api/app/quick_look/modulo4/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              let x = response.data.data;
              setTimeout(() => {
                html2canvas(document.querySelector('#M4q3chartP')).then(
                  (canvas) => {
                    var imgData = canvas.toDataURL('image/png');
                    x.M4q3chartP = imgData;
                    this.setState({
                      modulo4: x,
                      fill4: true,
                    });
                  },
                );
              }, 3000);
            } else {
              setTimeout(() => {
                this.setState({
                  modulo4: {},
                  fill4: false,
                });
              }, 5000);
            }
          });
          // MODULO 4

          // MODULO 5
          Axios.get('/api/app/quick_look/modulo5/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                modulo5: response.data.data,
                fill5: true,
              });
            } else {
              setTimeout(() => {
                this.setState({
                  modulo5: {},
                  fill5: false,
                });
              }, 5000);
            }
          });
          // MODULO 5

          // MODULO 6
          Axios.get('/api/app/quick_look/modulo6/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              let x = response.data.data;
              setTimeout(() => {
                html2canvas(document.querySelector('#M6q2chartP')).then(
                  (canvas) => {
                    var imgData = canvas.toDataURL('image/png');
                    x.M6q2chartP = imgData;
                    this.setState({
                      modulo6: x,
                      fill6: true,
                    });
                  },
                );
              }, 3000);
            } else {
              setTimeout(() => {
                this.setState({
                  modulo6: {},
                  fill6: false,
                });
              }, 5000);
            }
          });
          // MODULO 6

          // MODULO 7
          Axios.get('/api/app/quick_look/modulo7/report?id=' + this.props.id, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                modulo7: response.data.data,
                fill7: true,
              });
            } else {
              setTimeout(() => {
                this.setState({
                  modulo7: {},
                  fill7: false,
                });
              }, 5000);
            }
          });
          // MODULO 7
        },
      );
    });
  };

  download = () => {
    const doc = new Document({
      creator: 'Digital value Capture',
      description: 'Final report',
      title: this.state.name,
    });

    let headerLogo = Media.addImage(
      doc,
      Uint8Array.from(atob(stephenLogo), (c) => c.charCodeAt(0)),
      200,
      50,
    );
    let footerLogo = Media.addImage(
      doc,
      Uint8Array.from(atob(stephenLogo2), (c) => c.charCodeAt(0)),
      50,
      50,
    );

    let hoja = {
      headers: {
        default: new Header({
          children: [new Paragraph(styles.headerLogo(headerLogo))],
        }),
      },
      footers: {
        default: new Footer({
          children: styles.footerLogo(
            footerLogo,
            'https://digital-value-capture.teachable.com',
            'admin@stephenalexander.com.au',
          ),
        }),
      },
      children: [],
    };

    let M1q3chartS = '';
    if (this.state.graphics.M1q3chartS) {
      M1q3chartS = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q3chartS.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q5chartS = '';
    if (this.state.graphics.M1q5chartS) {
      M1q5chartS = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q5chartS.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q6chartS = '';
    if (this.state.graphics.M1q6chartS) {
      M1q6chartS = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q6chartS.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q8chartS = '';
    if (this.state.graphics.M1q8chartS) {
      M1q8chartS = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q8chartS.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }

    let M1q3chartE = '';
    if (this.state.graphics.M1q3chartE) {
      M1q3chartE = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q3chartE.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q4chartE = '';
    if (this.state.graphics.M1q4chartE) {
      M1q4chartE = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q4chartE.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q5chartE = '';
    if (this.state.graphics.M1q5chartE) {
      M1q5chartE = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q5chartE.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M1q7chartE = '';
    if (this.state.graphics.M1q7chartE) {
      M1q7chartE = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M1q7chartE.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M2q2chart = '';
    if (this.state.graphics.M2q2chart) {
      M2q2chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M2q2chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M2q3chart = '';
    if (this.state.graphics.M2q3chart) {
      M2q3chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M2q3chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M2q4chart = '';
    if (this.state.graphics.M2q4chart) {
      M2q4chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M2q4chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M2q5chart = '';
    if (this.state.graphics.M2q5chart) {
      M2q5chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M2q5chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M2q6chart = '';
    if (this.state.graphics.M2q6chart) {
      M2q6chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M2q6chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M3q2chart = '';
    if (this.state.graphics.M3q2chart) {
      M3q2chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M3q2chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M4q3chartP = '';
    if (this.state.modulo4.M4q3chartP) {
      M4q3chartP = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.modulo4.M4q3chartP.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        120,
      );
    }
    let M5q2chart = '';
    if (this.state.graphics.M5q2chart) {
      M5q2chart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.M5q2chart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        500,
      );
    }
    let M6q2chartP = '';
    if (this.state.modulo6.M6q2chartP) {
      M6q2chartP = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.modulo6.M6q2chartP.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        120,
      );
    }
    let scatterChart = '';
    if (this.state.graphics.scatterChart) {
      scatterChart = Media.addImage(
        doc,
        Uint8Array.from(
          atob(this.state.graphics.scatterChart.split(',')[1]),
          (c) => c.charCodeAt(0),
        ),
        600,
        300,
      );
    }

    let pagina = [new Paragraph(''), new Paragraph('')];

    pagina.push(
      new Paragraph(
        styles.textCenter(
          this.state.username +
            ' - ' +
            this.state.email +
            ' - ' +
            this.state.name,
        ),
      ),
    );

    pagina.push(new Paragraph(styles.report('Final Report')));

    //MODULO 1
    if (this.state.fill1) {
      if (this.state.modulo1.q1) {
        // Q1.1
        pagina.push(
          new Paragraph(styles.modulo('1. Enterprise Problems: New Start-Up')),
        );

        // Q1.2
        pagina.push(
          new Paragraph(
            styles.titulo('Problems that your good idea can address'),
          ),
        );
        this.state.modulo1.q2.forEach((p) => {
          pagina.push(new Paragraph(styles.list(`${p.value}`)));
        });

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.3
        pagina.push(
          new Paragraph(
            styles.titulo(
              'How confident are you that a demand exists within the new start up to fix these problems?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q3chartS)));

        // Q1.4
        pagina.push(
          new Paragraph(
            styles.titulo(
              'What are the three roles of the most influential actors?',
            ),
          ),
        );

        this.state.modulo1.q4.forEach((p) => {
          pagina.push(new Paragraph(styles.list2('Actor', p.actor)));
          pagina.push(new Paragraph(styles.list2('Role', p.role)));
          pagina.push(new Paragraph(''));
        });

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.5
        pagina.push(
          new Paragraph(
            styles.titulo(
              'Would the actors agree that these problems exist for them?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q5chartS)));

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.6
        pagina.push(
          new Paragraph(
            styles.titulo('This is the in-principle value proposition'),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q6chartS)));

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.7
        pagina.push(
          new Paragraph(styles.titulo('The root cause of those problems')),
        );
        pagina.push(new Paragraph(this.state.modulo1.q7));

        // Q1.8
        pagina.push(
          new Paragraph(
            styles.titulo(
              'Would you get the support required to address the root problem?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q8chartS)));

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];
      } else {
        // Q1.1
        pagina.push(
          new Paragraph(
            styles.modulo('1. Enterprise Problems: Established Enterprise'),
          ),
        );

        // Q1.2
        pagina.push(
          new Paragraph(
            styles.titulo('Problems that your good idea can address'),
          ),
        );
        this.state.modulo1.q2.forEach((p) => {
          pagina.push(new Paragraph(styles.list(`${p.value}`)));
        });

        // Decision 1
        pagina.push(new Paragraph(styles.decisionTitle('Is this true?')));
        pagina.push(
          new Paragraph(
            styles.noLineSeparation(
              'Please review if your good idea can address these problems.\nIf not, then please re-consider the question and rewrite the answers.',
            ),
          ),
        );
        pagina.push(
          new Paragraph(
            styles.decision(
              'If they are authentic, please explain why you focused on these problems, not others.',
            ),
          ),
        );

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.3
        pagina.push(
          new Paragraph(
            styles.titulo(
              'How confident are you that a demand exists within the established enterprise to fix these problems?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q3chartE)));

        // Decision 2
        pagina.push(
          new Paragraph(styles.decisionTitle('Your level of confidence')),
        );
        pagina.push(
          new Paragraph(
            styles.decision(
              'Please explore what your confidence is based on and then explain this so that any funder can understand your reasoning and then evaluate your logic.',
            ),
          ),
        );

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.4
        pagina.push(
          new Paragraph(
            styles.titulo(
              'Would the people in each of the three tiers agree that these problems exist for them?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q4chartE)));

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.5
        pagina.push(
          new Paragraph(
            styles.titulo(
              'Would the people in each of the three tiers want to address these problems?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q5chartE)));

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.6
        pagina.push(
          new Paragraph(styles.titulo('The root cause of those problems')),
        );
        pagina.push(new Paragraph(this.state.modulo1.q6));

        // Decision 3
        pagina.push(new Paragraph(styles.decisionTitle('Root cause problem')));
        pagina.push(
          new Paragraph(
            styles.decision(
              'If you addressed or provided a workaround for this root cause problem, would your good idea address all the problems listed?',
            ),
          ),
        );
        pagina.push(
          new Paragraph(
            styles.noLineSeparation(
              'If not, then please explain why your good idea will still work. \nIf yes, please consider what other problems your good idea might address indirectly.\nNow, you can expand on your observations and consider this as you explore the pain points.',
            ),
          ),
        );

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];

        // Q1.7
        pagina.push(
          new Paragraph(
            styles.titulo(
              'Would you get the support required to address the root problem?',
            ),
          ),
        );
        pagina.push(new Paragraph(styles.imagen(M1q7chartE)));

        // Decision 4
        pagina.push(
          new Paragraph(styles.decisionTitle('Assumptions challenged')),
        );
        pagina.push(
          new Paragraph(
            styles.decision(
              'This is the first crunch point where any assumptions that each actor/role will invest money, time, effort and reputation is challenged by the data expressed in the chart. You can now assess if each actor/role will prioritise addressing this root cause problem above or at least equal to the problems they are already committed to solving.',
            ),
          ),
        );
        pagina.push(
          new Paragraph(
            styles.decision(
              'Please expand on your findings, observations, reasoning and any logic used to justify going forward with your good idea, stating if you do or do not have enough support to address the problem.',
            ),
          ),
        );

        hoja.children = pagina;
        doc.addSection(hoja);
        pagina = [new Paragraph(''), new Paragraph('')];
      }
    } else {
      pagina.push(new Paragraph(styles.modulo('1. Enterprise Problems')));
      pagina.push(new Paragraph('You need to complete section 1'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 2
    if (this.state.fill2) {
      pagina.push(new Paragraph(styles.modulo('2. Eco System Problems')));

      // Q2.1
      pagina.push(
        new Paragraph(
          styles.titulo('Actors that are critical for your good idea to work'),
        ),
      );
      this.state.modulo2.q1.forEach((p) => {
        pagina.push(new Paragraph(styles.list(`${p.value}`)));
      });

      // Decision 5
      pagina.push(new Paragraph(styles.decisionTitle('Is this true?')));
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'Please review if these are all the actors/roles that your good idea is dependent upon to work.\nIf not, then please re-consider the question and re-write the answers.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If they are trustworthy, please expand on why you focused on these actors/roles and not others.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q2.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'How confident are you that a general level of demand exists within the new start up to fix the root cause problem?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2q2chart)));

      // Decision 6
      pagina.push(
        new Paragraph(
          styles.decision(
            'Please explore what your confidence is based on and then explain this so that any funder can understand your reasoning and then evaluate your logic. ',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q2.3
      pagina.push(
        new Paragraph(
          styles.titulo(
            'Does your perspective of the root cause problem exist for them and do they agree?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2q3chart)));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q2.4
      pagina.push(
        new Paragraph(
          styles.titulo(
            'How strongly would they agree with your perspective that they need to solve the root cause problem and reduce the impact on the eco-system as a whole?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2q4chart)));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q2.5
      pagina.push(
        new Paragraph(
          styles.titulo(
            'How strongly would they agree with your suggestion that there would be a strong general consensus to support your good idea to fix the root cause problem?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2q5chart)));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q2.6
      pagina.push(
        new Paragraph(
          styles.titulo(
            'How much would they agree to making a meaningful contribution in order to achieve the business goals that are important to acquiring a critical mass of adoption/usage of your good idea?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M2q6chart)));

      // Decision 7
      pagina.push(
        new Paragraph(styles.decisionTitle('Assumptions challenged.')),
      );
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'This is the second crunch point where any assumptions that each actor/role will invest money, time, effort and reputation is challenged by the data expressed in the chart. You can now assess if each actor/role will prioritise addressing this root cause problem above or at least equal to the problems they are already committed to solving.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'Please expand on your findings, observations, reasoning and any logic used to justify going forward with your good idea, stating if you do or do not have enough support to address the problem.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('2. Eco System Problems')));
      pagina.push(new Paragraph('You need to complete section 2'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 3
    if (this.state.fill3) {
      pagina.push(new Paragraph(styles.modulo('3. Pain Criteria Types')));

      // Q3.1
      pagina.push(
        new Paragraph(
          styles.titulo(
            'Typical types of pain criteria that cascade from the root problem.',
          ),
        ),
      );
      this.state.modulo3.q1.forEach((p) => {
        pagina.push(new Paragraph(styles.list(`${p.value}`)));
      });

      // Decision 8
      pagina.push(new Paragraph(styles.decisionTitle('Is this true?')));
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'Please review if these pain criteria types result from the root cause of the problem.\nIf not, then please reconsider the question and re-write the answers.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If they are authentic, please expand on why you focused on these pain point types and not others.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q3.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'How confident are you that the pain is of sufficient magnitude to motivate the adoption of any solution to reduce that pain to an acceptable level from their perspective?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M3q2chart)));

      // Decision 9
      pagina.push(
        new Paragraph(
          styles.decision(
            'Please explore what your confidence is based on and then explain this so that any funder can understand your reasoning and then evaluate your logic.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('3. Pain Criteria Types')));
      pagina.push(new Paragraph('You need to complete section 3'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 4
    if (this.state.fill4) {
      pagina.push(new Paragraph(styles.modulo('4. A Typical Scenario')));

      // Q4.1
      pagina.push(
        new Paragraph(styles.titulo('Typical day-in-the-life-of a customer')),
      );
      pagina.push(new Paragraph(this.state.modulo4.q1));

      // Q4.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'Within the context of your scenario, can you approximate this overall magnitude of pain?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M4q3chartP)));

      // Decision 10
      pagina.push(
        new Paragraph(
          styles.decision(
            'This is your first benchmarked estimate that should illustrate what the consumer of a good idea requires in terms of pain point reduction for the good idea to work for them.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'When thinking about a typical consumer, try to empathise with their whole life experience to comprehend better how the wicked problem impacts their life in terms of pain point types and the magnitude of impact.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'In the Quick Look, you can weigh up what the average pain score might be to understand this exercise.',
          ),
        ),
      );

      pagina.push(
        new Paragraph(
          styles.decision(
            'Please expand on your observations, reasoning, the logic used for both scores and what that told you.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('4. A Typical Scenario')));
      pagina.push(new Paragraph('You need to complete section 4'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    //MODULO 5
    if (this.state.fill5) {
      pagina.push(new Paragraph(styles.modulo('5. What If? (Capability)')));

      // Q5.1
      pagina.push(
        new Paragraph(
          styles.titulo(
            'The solution set that you need to make your good idea work.',
          ),
        ),
      );
      this.state.modulo5.q1.forEach((p) => {
        pagina.push(new Paragraph(styles.list(`${p.value}`)));
      });

      // Decision 11
      pagina.push(new Paragraph(styles.decisionTitle('Is this true?')));
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'If you still need to, please review if you have a complete solution set to meet the needs of your typical consumer.\nIf not, or you are not sure, then please re-examine.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If yes, please expand on how the solution set will achieve the needs and why you chose this combination and not other options.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Q5.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'What is your overall level of confidence that the selected capabilities can form the core solution set that is capable of achieving adoption, strategic problem solving, operational pain reduction and a return on investment?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M5q2chart)));

      // Decision 12
      pagina.push(
        new Paragraph(
          styles.decision(
            'Please explore what your confidence is based on and then explain this so that any funder can understand your reasoning and then evaluate your logic.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('5. What If? (Capability)')));
      pagina.push(new Paragraph('You need to complete section 5'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }
    //MODULO 6
    if (this.state.fill6) {
      pagina.push(new Paragraph(styles.modulo('6. A Good Idea')));

      // Q6.1
      pagina.push(
        new Paragraph(
          styles.titulo(
            'What it will do and how it will impact the target user?',
          ),
        ),
      );
      pagina.push(new Paragraph(this.state.modulo6.q1.description));

      // Q6.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'If you pitched your good idea to all the critical actors in the scenario and asked them what their magnitude of pain would be if they adopted it, what would that average score be?',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(M6q2chartP)));

      // Decision 13
      pagina.push(
        new Paragraph(styles.decisionTitle('No go, you go or re-consider.')),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'This is your second benchmark estimate that should illustrate if the chosen solution set will deliver the adoption of your good idea by meeting or exceeding the needs of the typical consumer.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'If not, then there is a high probability that your good ideas cannot work regardless of the level of funding or length of runway.\nIf your “To Be score” dramatically exceeds what the consumers need, then you risk them not believing.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'The sweet spot is to meet or exceed the needs for it to make sense to the consumer.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'Please expand on your observations, reasoning, the logic used for both scores and what that told you.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    } else {
      pagina.push(new Paragraph(styles.modulo('6. A Good Idea')));
      pagina.push(new Paragraph('You need to complete section 6'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }
    //MODULO 7
    if (this.state.fill7) {
      pagina.push(new Paragraph(styles.modulo('7. Value Proposition')));

      // Q7.1
      pagina.push(
        new Paragraph(
          styles.titulo(
            'The approximated value impacts on these three systems.',
          ),
        ),
      );

      pagina.push(new Paragraph(styles.list2('Actor', 'The Enterprise')));
      pagina.push(
        new Paragraph(styles.list2('Score', this.state.modulo7.q1.savings)),
      );
      pagina.push(new Paragraph(''));

      pagina.push(new Paragraph(styles.list2('Actor', 'The Eco-system')));
      pagina.push(
        new Paragraph(styles.list2('Score', this.state.modulo7.q1.income)),
      );
      pagina.push(new Paragraph(''));

      pagina.push(
        new Paragraph(styles.list2('Actor', 'Human Value Experience')),
      );
      pagina.push(
        new Paragraph(styles.list2('Score', this.state.modulo7.q1.impact)),
      );
      pagina.push(new Paragraph(''));

      // Q7.2
      pagina.push(
        new Paragraph(
          styles.titulo(
            'The value proposition that embodies the essence of all the problem-solving, pain reduction, and financial and human impact to the customer.',
          ),
        ),
      );
      pagina.push(new Paragraph(this.state.modulo7.q2.description));

      // Q7.3
      pagina.push(
        new Paragraph(
          styles.titulo(
            'What is your overall level of confidence that your value proposition to the customer would be embraced by them and endorsed by all the other key actors?',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.textCenter('Score: ' + this.state.modulo7.q3.score),
        ),
      );

      // Decision 14
      pagina.push(
        new Paragraph(
          styles.decisionTitle('What is the proposition at this stage?'),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            'In principle, what is your sense of the types of value and magnitude of impact that your good idea would have on people and the systems it impacts:',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.list('The Enterprise')));
      pagina.push(new Paragraph(styles.list('The Eco-system')));
      pagina.push(new Paragraph(styles.list('Human value impact experience')));

      pagina.push(
        new Paragraph(
          styles.decision(
            'At this stage, you need to have a sense of how impactful your good idea would be if adopted.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If your good idea and the solution set could work, then you have the option to take the Deep Dive, where you measure the pain points of every critical actor/role.',
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If you undertake the Value Validation exercise, you can also determine if the value impact outcome is worth the type and magnitude of investment required to realise the potential benefit.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];

      // Scatter Chart
      pagina.push(
        new Paragraph(
          styles.modulo(
            'Their willingness to invest time, money, effort and risk reputation score over the confidence score',
          ),
        ),
      );
      pagina.push(new Paragraph(styles.imagen(scatterChart)));

      // Decision 15
      pagina.push(
        new Paragraph(
          styles.decisionTitle('Their willingness and your confidence'),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.noLineSeparation(
            "The obvious question any funder would have if your score is in any quadrant other than the top right is why you would proceed.\nIf that is the case with your score, it is wise to re-examine why it's not in the top right and then either re-work the good idea, park it, or craft a precise examination to answer that question.",
          ),
        ),
      );
      pagina.push(
        new Paragraph(
          styles.decision(
            'If your score is in the top right quadrant, please summarise why a funder should see it’s not a fluke.',
          ),
        ),
      );

      doc.addSection({
        children: pagina,
      });
    } else {
      pagina.push(new Paragraph(styles.modulo('7. Value Proposition')));
      pagina.push(new Paragraph('You need to complete section 7'));

      doc.addSection({
        children: pagina,
      });
      pagina = [new Paragraph(''), new Paragraph('')];
    }

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${this.state.name} - Report.docx`);
    });
  };

  render() {
    return (
      <>
        {this.state.modulo1r &&
        this.state.modulo2r &&
        this.state.modulo3r &&
        this.state.modulo4r &&
        this.state.modulo5r &&
        this.state.modulo6r &&
        this.state.modulo7r &&
        this.state.scatterChart
          ? this.state.listo
            ? null
            : this.generateDOCX()
          : null}
        <p className="question-title text-center">
          <b>Final Report</b>
        </p>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>1. Enterprise Problems</b>
          </p>
          {this.props.startup ? (
            <M1ReportStartup
              nonext={false}
              progress={this.props.progress}
              id={this.props.id}
              next={this.nextModule}
              load={() => {
                this.handleLoad('modulo1r');
              }}
              graphic={this.graphic}
            />
          ) : (
            <M1ReportEnterprise
              nonext={false}
              progress={this.props.progress}
              id={this.props.id}
              next={this.nextModule}
              load={() => {
                this.handleLoad('modulo1r');
              }}
              graphic={this.graphic}
            />
          )}
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>2. Eco System Problems</b>
          </p>
          <M2Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo2r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>3. Pain Criteria Types</b>
          </p>
          <M3Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo3r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>4. A Typical Scenario</b>
          </p>
          <M4Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo4r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>5. What if? (Capability)</b>
          </p>
          <M5Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo5r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>6. A Good Idea</b>
          </p>
          <M6Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo6r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <p className="text-center">
            <b>7. Value Proposition</b>
          </p>
          <M7Report
            nonext={false}
            progress={this.props.progress}
            id={this.props.id}
            next={this.nextModule}
            load={() => {
              this.handleLoad('modulo7r');
            }}
            graphic={this.graphic}
          />
        </div>
        <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
          <ScatterChartReport
            id={this.props.id}
            load={() => {
              this.handleLoad('scatterChart');
            }}
            graphic={this.graphic}
          />
        </div>
        <hr />
        <div
          className="hideButtons"
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>

          {this.state.modulo1 !== false &&
          this.state.modulo2 !== false &&
          this.state.modulo3 !== false &&
          this.state.modulo4 !== false &&
          this.state.modulo5 !== false &&
          this.state.modulo6 !== false &&
          this.state.modulo7 !== false ? (
            <button
              className="btn my-4 p-3"
              style={{
                color: 'white',
                backgroundColor: '#cea977',
                borderRadius: '10px',
              }}
              onClick={this.download}
            >
              <i className="fas fa-arrow-down"></i> Download Report
            </button>
          ) : (
            <button
              className="btn my-4 p-3"
              style={{
                color: 'white',
                backgroundColor: '#cea977',
                borderRadius: '10px',
              }}
              disabled
            >
              <Spinner animation="border" size="sm" variant="light" />{' '}
              Generating Report
            </button>
          )}
        </div>
      </>
    );
  }
}

export default Report;
