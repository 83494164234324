import React from 'react';

export function PrivacyPolicy() {
  return (
    <div className="px-4 py-2">
      <p className="font-bold" style={{ fontSize: '20px' }}>
        Digital Value Capture Policy
      </p>
      <p className="font-normal pb-2">
        This Privacy Policy applies to your use of the Digital Value Capture
        application (‘the Application’) subject to the Terms and Conditions of
        Use between you and Annexus Pty Ltd (‘Annexus’).
      </p>
      <ol>
        <li className="font-bold">
          Our commitment to privacy, security, and responsible data stewardship
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                Annexus is committed to be a leader in the field of data
                stewardship and take seriously our obligation to ensure that
                your data is handled in accordance with the principles of
                accountability, privacy and security, and fair value exchange.
                We understand data stewardship as achieving a balance between
                the interests of data subjects, innovative entities such as
                Annexus who gather and aggregate data, and the common good.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                Insofar as possible, access, control, and benefits from your
                data will remain with you under the principle of data
                self-sovereignty. Exceptions to this include authorised requests
                by competent authorities as set out in this policy. Where your
                data accrues value only because it is aggregated with that of
                others, or because it is subjected to analysis by Annexus, we
                are committed to share the benefits on a fair and equitable
                basis.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                This policy sets out how we collect and treat your Personal
                Information. You may contact us in writing at for further
                information about this Privacy Policy.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                We adhere to the Australian Privacy Principles contained in the
                Privacy Act 1988 (Cth) and to the extent applicable, the EU
                General Data Protection Regulation (GDPR).
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                ‘Personal Information’ is information we hold which is
                identifiable as being about you. This includes information such
                as your name, email address, identification number, or any other
                type of information that can reasonably identify an individual,
                either directly or indirectly.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          What Personal Information is collected
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                Annexus will, from time to time, receive and store Personal
                Information you submit to our website, provided to us directly
                or given to us in other forms.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                You may provide basic information such as your name, phone
                number, address and email address to enable us to send you
                information, provide updates and process your product or service
                order. You may also provide us with information relating to your
                health.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                We may collect additional information at other times, including
                but not limited to, when you provide feedback, when you provide
                information about your personal or business affairs, change your
                content or email preference, respond to surveys and/or
                promotions, provide financial or credit card information, or
                communicate with our customer support.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                Additionally, we may also collect any other information you
                provide while interacting with us.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          How we collect your Personal Information
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                Annexus collects Personal Information from you in a variety of
                ways, including when you interact with us electronically or in
                person, when you use the Application or access our website and
                when we engage in business activities with you. We may receive
                Personal Information from third parties. If we do, we will
                protect it as set out in this Privacy Policy.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                We will only collect Personal Information relating to your
                health when it is necessary to provide our service to you. We
                will generally do so with your knowledge and your consent. Where
                we obtain health information from a third party, we will use
                only lawful and fair means to do so.
              </span>
            </li>
            <li className="font-bold">
              <span className="font-normal">
                When we collect Personal Information relating to your health, we
                will do so pursuant to a privacy management plan that ensures
                clear lines of accountability for privacy management and
                safeguards the principles and best practices of data
                stewardship. This will include maintaining a record of the types
                of Personal Information we handle.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                When we collect Personal Information relating to your health, we
                will provide notice to you within a reasonable time of the
                following: (i) our organisational and contact details; (ii) if
                you may not be aware of the collection, the circumstances of its
                collection; (iii) whether the collection was required or
                authorised by law; (iv) the purposes of collection; (v) any
                consequences of non-collection; (vi) details about this Privacy
                Policy including how you can access and correct the information
                collected, how you can make a complaint about your information
                and how that complaint will be dealt with, and any intended or
                likely transfer of your information overseas.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                By providing us with Personal Information, you consent to the
                supply of that information subject to the terms of this Privacy
                Policy.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          How we use your Personal Information
          <ol type="a" className="py-3">
            <li className="font-bold">
              <span className="font-normal">
                Annexus may use Personal Information collected from you to
                provide you with information about our products or services. We
                may also make you aware of new and additional products, services
                and opportunities available to you.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                Annexus will use Personal Information only for the purposes that
                you consent to. This may include to:
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                provide you with products and services during the usual course
                of our business activities;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                administer our business activities;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                manage, research and develop our products and services;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                provide you with information about our products and services;
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                communicate with you by a variety of measures including, but not
                limited to, by telephone, email, sms or mail; and
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">investigate any complaints.</span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                If you withhold your Personal Information, it may not be
                possible for us to provide you with our products and services or
                for you to fully access our website.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                We may disclose your Personal Information to comply with a legal
                requirement, such as a law, regulation, court order, subpoena,
                warrant, legal proceedings or in response to a law enforcement
                agency request.
              </span>
            </li>
            <li className="font-bold pt-2">
              <span className="font-normal">
                If there is a change of control in our business or a sale or
                transfer of business assets, we reserve the right to transfer to
                the extent permissible at law our user databases, together with
                any Personal Information and non-Personal Information contained
                in those databases.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Disclosure of your Personal Information
          <ol type="a" className="py-2">
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                Annexus may disclose your Personal Information to any of our
                employees, officers, insurers, professional advisers, agents,
                suppliers or subcontractors insofar as reasonably necessary for
                the purposes set out in this privacy policy.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                If we do disclose your Personal Information to a third party, we
                will protect it in accordance with this privacy policy.
              </p>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          General Data Protection Regulation (GDPR) for the European Union (EU)
          <ol type="a" className="py-2">
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                Annexus will comply with the principles of data protection set
                out in the GDPR for the purpose of fairness, transparency and
                lawful data collection and use.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We process your Personal Information as a Processor and/or to
                the extent that we are a Controller as defined in the GDPR.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We must establish a lawful basis for processing your Personal
                Information. The legal basis for which we collect your Personal
                Information depends on the data that we collect and how we use
                it.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We will only collect your Personal Information with your express
                consent for a specific purpose and any data collected will be to
                the extent necessary and not excessive for its purpose. We will
                keep your data safe and secure.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We will also process your Personal Information if it is
                necessary for our legitimate interests, or to fulfil a
                contractual or legal obligation.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We process your Personal Information if it is necessary to
                protect your life or in a medical situation, it is necessary to
                carry out a public function, a task of public interest or if the
                function has a clear basis in law.
              </p>
            </li>
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                We will not collect or process any Personal Information from you
                that is considered ‘Sensitive Personal Information’ under the
                GDPR, such as Personal Information relating to your sexual
                orientation or ethnic origin, unless we have obtained your
                explicit consent, or if it is being collected subject to and in
                accordance with the GDPR.
              </p>
            </li>{' '}
            <li className="font-bold">
              <p className="font-normal pt-2 mb-0">
                You must not provide us with your Personal Information if you
                are under the age of 16 without the consent of your parent or
                someone who has parental authority for you. We do not knowingly
                collect or process the Personal Information of children.
              </p>
            </li>
          </ol>
        </li>

        <li className="font-bold">
          Your rights under the GDPR
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                If you are an individual residing in the EU, you have certain
                rights as to how your Personal Information is obtained and used.
                complies with your rights under the GDPR as to how your Personal
                Information is used and controlled if you are an individual
                residing in the EU
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Except as otherwise provided in the GDPR, you have the following
                rights:
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                to be informed how your Personal Information is being used;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                access your Personal Information (we will provide you with a
                free copy of it);
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                to correct your Personal Information if it is inaccurate or
                incomplete;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                to delete your Personal Information (also known as ‘the right to
                be forgotten’);
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                to restrict processing of your Personal Information;
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                to retain and reuse your Personal Information for your own
                purposes;
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                to object to your Personal Information being used; and to object
                against automated decision making and profiling.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                Please contact us at any time to exercise your rights under the
                GDPR at the contact details in this Privacy Policy.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                We may ask you to verify your identity before acting on any of
                your requests.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Hosting and International Data Transfers
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Information that we collect may from time to time be stored,
                processed in or transferred between parties or sites located in
                countries outside of Australia. These may include, but are not
                limited to the United Kingdom.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                We and our other group companies have offices and/or facilities
                in the United Kingdom. Transfers to each of these countries will
                be protected by appropriate safeguards, these include one or
                more of the following: the use of standard data protection
                clauses adopted or approved by the European Commission which you
                can obtain from the European Commission Website; the use of
                binding corporate rules, a copy of which you can obtain from our
                Data Protection Officer.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                The hosting facilities for our website are situated in the
                United Kingdom. Transfers to each of these Countries will be
                protected by appropriate safeguards, including the use of
                standard data protection clauses adopted or approved by the
                European Commission (which you can obtain from the European
                Commission Website) and/or the use of binding corporate rules (a
                copy of which you can obtain from our Data Protection Officer).
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                Our Suppliers and Contractors are situated in Australia and the
                United Kingdom. Transfers to each of these Countries will be
                protected by appropriate safeguards, including the use of
                standard data protection clauses adopted or approved by the
                European Commission (which you can obtain from the European
                Commission Website) and/or the use of binding corporate rules (a
                copy of which you can obtain from our Data Protection Officer).
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                You acknowledge that Personal Information that you submit for
                publication through our website or services may be available,
                via the internet, around the world. We cannot prevent the use
                (or misuse) of such Personal Information by others.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Security of your Personal Information
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Annexus is committed to ensuring that the information you
                provide to us is secure. In order to prevent unauthorised access
                or disclosure, we have put in place suitable physical,
                electronic and managerial procedures to safeguard and secure
                information and protect it from misuse, interference, loss and
                unauthorised access, modification and disclosure.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Where we employ data processors to process Personal Information
                on our behalf, we only do so on the basis that such data
                processors comply with the requirements under the GDPR and that
                have adequate technical measures in place to protect Personal
                Information against unauthorised use, loss and theft.
              </span>
            </li>
            <li className="py-2 font-bold">
              <span className="font-normal">
                The transmission and exchange of information is carried out at
                your own risk. We cannot guarantee the security of any
                information that you transmit to us, or receive from us.
                Although we take measures to safeguard against unauthorised
                disclosures of information, we cannot assure you that Personal
                Information that we collect will not be disclosed in a manner
                that is inconsistent with this Privacy Policy.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold">
          Access to your Personal Information
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                You may request details of Personal Information that we hold
                about you in accordance with the provisions of the Privacy Act
                1988 (Cth), and to the extent applicable the EU GDPR. If you
                would like a copy of the information which we hold about you or
                believe that any information we hold on you is inaccurate, out
                of date, incomplete, irrelevant or misleading, please email us
                at admin@digitalvaluecapture.com.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                We reserve the right to refuse to provide you with information
                that we hold about you, in certain circumstances set out in the
                Privacy Act or any other applicable law.
              </span>
            </li>
          </ol>
        </li>
        <li className="font-bold pt-2">
          Complaints about privacy
          <p className="pt-2 font-normal">
            If you have any complaints about our privacy practices, please feel
            free to send in details of your complaints to
            admin@digitalvaluecapture.com. We take complaints very seriously and
            will respond shortly after receiving written notice of your
            complaint.
          </p>
        </li>
        <li className="font-bold">
          Changes to Privacy Policy
          <p className="pt-2 font-normal">
            Please be aware that we may change this Privacy Policy in the
            future. We may modify this Policy at any time, in our sole
            discretion and all modifications will be effective immediately upon
            our posting of the modifications on our website or notice board.
            Please check back from time to time to review our Privacy Policy.
          </p>
        </li>
        <li className="font-bold">
          Website
          <ol type="a">
            <li className="pt-2 font-bold">
              <span className="font-normal">
                When you visit our website: When you come to our website
                www.digitalvaluecapture.com, we may collect certain information
                such as browser type, operating system, website visited
                immediately before coming to our site, etc. This information is
                used in an aggregated manner to analyse how people use our site,
                such that we can improve our service.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Cookies: We may from time to time use cookies on our website.
                Cookies are very small files which a website uses to identify
                you when you come back to the site and to store details about
                your use of the site. Cookies are not malicious programs that
                access or damage your computer. Most web browsers automatically
                accept cookies but you can choose to reject cookies by changing
                your browser settings. However, this may prevent you from taking
                full advantage of our website. Our website may from time to time
                use cookies to analyses website traffic and help us provide a
                better website visitor experience. In addition, cookies may be
                used to serve relevant ads to website visitors through third
                party services such as Google AdWords. These ads may appear on
                this website or other websites you visit.
              </span>
            </li>
            <li className="pt-2 font-bold">
              <span className="font-normal">
                Third party sites: Our site may from time to time have links to
                other websites not owned or controlled by us. These links are
                meant for your convenience only. Links to third party websites
                do not constitute sponsorship or endorsement or approval of
                these websites. Please be aware that is not responsible for the
                privacy practises of other such websites. We encourage our users
                to be aware, when they leave our website, to read the privacy
                statements of each and every website that collects personal
                identifiable information.
              </span>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
