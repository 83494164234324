import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question4Startup extends Component {
  state = {
    actors: [
      { actor: '', role: '' },
      { actor: '', role: '' },
      { actor: '', role: '' },
    ],
    edit: false,
    ok: true,
    nextloading: false,
    loading: true,
    chart: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleChange = (e, key) => {
    let v = this.state.actors;
    if (
      e.target.value &&
      e.target.value[0] &&
      !e.target.value[0].match(/[0-9]/)
    ) {
      v[key][e.target.name] = e.target.value;
    } else {
      v[key][e.target.name] = '';
    }
    this.setState({
      actors: v,
      edit: true,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        actors: this.state.actors,
      };
      Axios.post('/api/app/quick_look/modulo1/question4Startup', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    Axios.get(
      '/api/app/quick_look/modulo1/question4Startup?id=' + this.props.id,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    ).then((response) => {
      if (response.data.ok) {
        if (response.data.data.length < 3) {
          this.setState({
            loading: false,
            actors: [
              { actor: '', role: '' },
              { actor: '', role: '' },
              { actor: '', role: '' },
            ],
          });
        } else {
          this.setState({
            loading: false,
            actors: response.data.data,
          });
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.4</b>
              </p>
              <p className="mb-4">
                What are the three roles of the most influential (named) actors
                in your new start-up?
              </p>
              {this.state.actors.map((arg, index) => (
                <div
                  key={index}
                  className="row mx-auto"
                  style={{ width: '95%' }}
                >
                  <div className="col-6 mx-auto">
                    <div className="row">
                      <div className="col-2 superCenter">
                        <span>Actor:</span>
                      </div>
                      <div className="col-10">
                        <input
                          placeholder="Actor"
                          className="form-control m-0"
                          name="actor"
                          value={arg.actor}
                          onChange={(e) => this.handleChange(e, index)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mx-auto text-center">
                    <div className="row">
                      <div className="col-2 superCenter">
                        <span>Role:</span>
                      </div>
                      <div className="col-10">
                        <input
                          placeholder="Role"
                          className="form-control m-0"
                          name="role"
                          value={arg.role}
                          onChange={(e) => this.handleChange(e, index)}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    {this.state.actors.length === index + 1 ? null : <hr />}
                  </div>
                </div>
              ))}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question4Startup;
