import React, { Component } from 'react';
import './progress_bar.css';

export class Progress_Bar extends Component {
  render() {
    const progress = this.props.number;
    return (
      <>
        <div className="containerPB">
          <div className="progressBarCustom">
            <div
              className="progress-line"
              style={{ width: `${progress}%` }}
            ></div>
            <div className="progress-circle-outter">
              <div className="progress-circle">
                <span>{progress}%</span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Progress_Bar;
