import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import BarChart from '../../../BarChart/BarChart';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/modulo4/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          q1: response.data.data.q1,
          q3chartP: {
            id: 'M4q3chartP',
            data: [
              {
                label: 'AS IS',
                score: response.data.data.q2.pain,
                color: '#bf0000a3',
              },
              {
                label: 'Needs to Be',
                score: response.data.data.q3.pain,
                color: '#7200a7a3',
              },
            ],
          },
          // q3chartV: {id:'M4q3chartV', data: [{label: 'AS IS',score: response.data.data.q2.value, color:'#bf0000a3'},{label: 'Needs to Be',score: response.data.data.q3.value, color:'#7200a7a3'}]}
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>4. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                {/* Q1 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Typical day-in-the-life-of a customer</b>
                  </p>
                  <hr />
                  <div className="row">
                    <div className="col-10 mx-auto">
                      <p className="my-1">{this.state.q1}</p>
                    </div>
                  </div>
                </div>

                {/* Q1 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Within the context of your scenario, can you approximate
                      this overall magnitude of pain?
                    </b>
                  </p>
                  <hr />
                  {/* <div className="row">
                        <span className='mx-auto'>Pain</span>
                        </div> */}
                  <br />
                  <BarChart val={this.state.q3chartP} pain={true} />
                  <br />
                  <br />
                  {/* <div className="row">
                        <span className='mx-auto'>Value</span>
                        </div>
                        <br/>
                        <BarChart val={this.state.q3chartV} value={true}/>
                        <br/>
                        <br/>
                        <br/> */}
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
