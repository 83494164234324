import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import React from 'react';
import Axios from 'axios';
import toastr from 'toastr';
import { useState } from 'react';
import { getToken } from '../config';

interface GrantUserAccessProps {
  display: boolean;
  setDisplay: (value: boolean) => void;
  showSuperModal: (value: boolean) => void;
  module: 'deep_dive' | 'quick_look';
}

export function GrantUserAccess({
  display,
  setDisplay,
  showSuperModal,
  module,
}: GrantUserAccessProps) {
  const [loading, setloading] = useState(false);

  const grantUserAccess = (e) => {
    e.preventDefault();

    if (e.target.email.value === '') {
      toastr.remove();
      toastr.warning('Please provide the user email');
      return;
    }

    const data = {
      email: e.target.email.value,
      module,
    };
    Axios.post('/api/app/admin/grantUser', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        setDisplay(false);
        showSuperModal(true);
        toastr.remove();
        toastr.success('Permission Granted!');
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
      }
      setloading(false);
    });
  };

  return (
    <Modal
      show={display}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setDisplay(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>Module Access</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={grantUserAccess}>
          <Form.Group>
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="Email"
            />
          </Form.Group>
          <div className="submit-button text-center">
            <Button type="submit" className="btn btn-primary btn-custom">
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Grant'
              )}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
