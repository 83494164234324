import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    problems: [],
    loading: true,
    edit: false,
    nextloading: false,
    ok: true,
    error: '',
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role);
      data.push(element.score);
    });
    let series = [];
    series.push({ name: '', data: data });
    this.props.chart({ labels, series });
  };

  handleBack = () => {
    this.props.next(24);
  };

  handleNext = () => {
    this.props.next(26);
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    if (e.target.name === 'score') {
      v[key][e.target.name] = parseInt(e.target.value);
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar();
        },
      );
    } else {
      if (
        e.target.value &&
        e.target.value[0] &&
        !e.target.value[0].match(/[0-9]/)
      ) {
        v[key][e.target.name] = e.target.value;
      } else {
        v[key][e.target.name] = '';
      }
      this.setState({
        edit: true,
        problems: v,
      });
    }
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo7/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(26);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(26);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo7/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.data.length === 0) {
          toastr.remove();
          toastr.warning('Fill Question 7.1 first');
        } else {
          this.setState({
            problems: response.data.data,
          });
          response.data.prev.forEach((element) => {
            let esta = false;
            this.state.problems.forEach((e) => {
              if (element.role === e.role && element.p1 === e.pain) esta = true;
            });

            if (!esta) {
              this.setState({
                problems: [
                  ...this.state.problems,
                  { role: element.role, pain: element.p1, saved: '', made: '' },
                ],
              });
            }
          });
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 7.2</b>
              </p>
              <p className="mb-4">
                Now for each actor from the scenario list how much money
                (budget) they would have saved and how much they would have
                made.
                <br />
                <br />
                Please watch the help video, which will help you ask the
                questions in a way that gets the best results.
              </p>
              {this.state.problems.map((arg, index) => (
                <React.Fragment key={index}>
                  <b>
                    <p>{arg.role + ' - ' + arg.pain}</p>
                  </b>

                  <table style={{ width: '100%', margin: '2% 0' }}>
                    <tbody>
                      <tr style={{ borderBottom: '1px solid #dcdcdc' }}>
                        <td style={{ width: '10%' }}>
                          <p>Saved</p>
                          <br />
                          <br />
                          <br />
                          <p>Made</p>
                        </td>
                        <td style={{ margin: '20px 0' }}>
                          <input
                            className="form-control form-check-input position-static mx-2 w-100"
                            type="text"
                            name="saved"
                            value={arg.saved}
                            onChange={(e) => this.handleChange(e, index)}
                            style={{ margin: '0px!important' }}
                          />
                          <br />
                          <input
                            className="form-control form-check-input position-static mx-2 w-100"
                            type="text"
                            name="made"
                            value={arg.made}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </React.Fragment>
              ))}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
