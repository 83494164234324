import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import BarChart from '../../../BarChart/BarChart';

export class Question2 extends Component {
  state = {
    pain: 1,
    value: 10,
    edit: true,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
    chartP: { id: 'M4q2chartP', data: [] },
  };

  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: parseInt(e.target.value),
        value: 10 - parseInt(e.target.value) + 1,
        edit: true,
      },
      () => {
        this.makeChart();
      },
    );
  };

  makeChart = () => {
    this.setState({
      chartP: {
        id: 'M4q2chartP',
        data: [{ label: 'AS IS', score: this.state.pain, color: '#bf0000a3' }],
      },
    });
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        pain: this.state.pain,
        value: this.state.value,
      };
      Axios.post('/api/app/quick_look/modulo4/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908885834?h=564ee95473');
    Axios.get('/api/app/quick_look/modulo4/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState(
          {
            loading: false,
            description: response.data.description,
            pain: response.data.data.pain,
            value: response.data.data.value,
          },
          () => {
            this.makeChart();
          },
        );
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 4.2</b>
              </p>
              <p className="mb-4">
                Within the context of your scenario, can you approximate this
                overall magnitude of pain?
              </p>
              <p className="mb-4">1 = low magnitude and 10 = high magnitude</p>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <div className="text-left" style={{ width: '70%' }}></div>
                <div className="text-center" style={{ width: '30%' }}>
                  <span className="font-bold">Score</span>
                </div>
              </div>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  "{this.state.description}"
                </div>
                <div style={{ width: '30%' }}>
                  <select
                    className="form-control w-50 mx-auto"
                    value={this.state.pain}
                    name="pain"
                    onChange={(e) => this.handleChange(e)}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </div>
              </div>
              <hr />
              <br />
              <BarChart
                val={this.state.chartP}
                pain={true}
                color={'#bf0000a3'}
              />
              <br />
              <br />
              <br />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
