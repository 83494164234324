import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './home.css';
import Footer from '../../components/Footer/Footer';
import Login from '../Login/Login';
import Divisor from '../../assets/img/landing/vectores-11.png';

export class Home extends Component {
  render() {
    return (
      <>
        <NavBar auth={this.props.auth} home={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="row p-0 m-0 home-login-banner">
          <div className="home-login-form col-sm-10 col-md-8 col-lg-5 superCenter">
            {!this.props.auth ? (
              <Login />
            ) : (
              <div className="text-center">
                <p
                  className="w-100 mb-0 font-bold"
                  style={{ fontSize: '5rem' }}
                >
                  Welcome
                </p>
                <p
                  className="font-bold"
                  style={{ fontSize: '2rem', color: '#cea977' }}
                >
                  Digital Value Capture
                  <i
                    className="far fa-registered"
                    style={{
                      fontSize: '1.2rem',
                      marginRight: '5px',
                      marginLeft: '2px',
                    }}
                  ></i>{' '}
                  Toolbox
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="home-section">
          <div className="row text-center">
            <div className="col-12 mx-auto my-5">
              <h3 className="font-bold">
                What is This Digital Value Capture
                <i
                  className="far fa-registered"
                  style={{
                    fontSize: '1.4rem',
                    marginRight: '5px',
                    marginLeft: '2px',
                  }}
                ></i>{' '}
                Toolbox?
              </h3>
            </div>
            <div style={{ margin: 'auto', width: '85%', marginBottom: '20px' }}>
              It’s a game-changing set of tools that measures and then validates
              the potential for a good idea to solve a root cause problem for
              people, systems, organisations, markets sectors and even whole
              economies. The apps do this by calculating the total value impact
              on any system of introducing any capability or solution set into
              an interconnected environment.
            </div>
            <br />
            <div style={{ margin: 'auto', width: '85%', marginBottom: '20px' }}>
              The apps also assist in capturing the verified value, including
              financial value, cryptocurrencies and human-generated value, by
              documenting value measures in a legally owned artefact. This
              allows tokenisation of new digital artefacts, so we can use them
              as digital currency or trade them as a new type of digital asset
              under management.
            </div>
            <div className="col-12 mx-auto my-5">
              <h3 className="font-bold">
                We are building a range of apps that codifies the Digital Value
                Capture
                <i
                  className="far fa-registered"
                  style={{
                    fontSize: '1.4rem',
                    marginRight: '5px',
                    marginLeft: '2px',
                  }}
                ></i>
                <br /> methodology into these modules and more.
              </h3>
            </div>
            <div
              style={{ margin: 'auto', width: '90%' }}
              className="superCenter"
            >
              <div
                className="col-5 superCenter font-bold my-4"
                style={{ color: '#cea977', fontSize: '1.2rem' }}
              >
                Could It Meet The Needs Of Consumers ?
              </div>
              <div className="col-7 text-left my-4">
                <span className="font-bold" style={{ fontSize: '1.1rem' }}>
                  The Quick Look App & embedded learning Course <br />
                  USD$475
                </span>
                <p>
                  To discover if a good idea could work before you invest money,
                  time, effort and reputation in something that could never
                  work.
                  <br />
                  <br />
                  The App takes you through a series of probing questions
                  requiring problem-solving, sense-making, and numerical
                  assessments of the impact your solution would have on all the
                  actors your idea will depend on for its adoption.
                  <br />
                  <br />
                  Visual representations of your inputs are created to assist
                  awareness of the implications of introducing a new capability
                  into an eco-like interconnected network of relationships,
                  things, and entities so you can better understand the
                  consequences in terms of how your idea impacts people,
                  processes, and systems.
                  <br />
                  <br />
                  When the surveys are complete, the App generates a feasibility
                  study report (approx 18 pages) with all the graphic results
                  and a series of questions that expand on the findings,
                  including the Go, No-Go or Review decision points and the
                  final Their Willingness versus Your Confidence score quadrant
                  chart. This enables you to customise the report by adding more
                  contextual details, such as observations, recommendations and
                  a pitch to go to the next stage using the Deep Dive App and/or
                  the Worth Calculation App.
                  <br />
                  <br />
                  The App also contains a learning course containing video
                  explanations to help you understand how to respond to each
                  question and a more lengthy detailed description of what you
                  can discover in each section. This App was used as part of an
                  accelerated MBA course at the Hult International Business
                  School.
                </p>
                <p
                  className="text-center font-bold"
                  style={{ color: '#cea977', fontSize: '0.9rem' }}
                >
                  Special Offer the first 20 people who sign up get a one-year
                  free subscription to the Digital Value Capture Apprenticeship
                  community that will cost USD$99 per month
                </p>
              </div>
            </div>
            <div
              style={{
                margin: 'auto',
                width: '90%',
                borderTop: '1px solid #dadada',
                borderBottom: '1px solid #dadada',
              }}
              className="superCenter"
            >
              <div
                className="col-5 superCenter font-bold my-4"
                style={{ color: '#cea977', fontSize: '1.2rem' }}
              >
                Will It Work?
              </div>
              <div className="col-7 text-left my-4">
                <span className="font-bold" style={{ fontSize: '1.1rem' }}>
                  The Deep Dive
                </span>
                <p>
                  The expert system that helps entrepreneurs succeed by solving
                  real problems with value-driven business solution sets that
                  work. Generates a substantial report identifying if the good
                  idea would meet or exceed the needs of each actor within the
                  interconnected environment that the enterprise operates
                  within.
                </p>
              </div>
            </div>
            <div
              style={{ margin: 'auto', width: '90%' }}
              className="superCenter"
            >
              <div
                className="col-5 superCenter font-bold my-4"
                style={{ color: '#cea977', fontSize: '1.2rem' }}
              >
                Will It Be Worth It?
              </div>
              <div className="col-7 text-left my-4">
                <span className="font-bold" style={{ fontSize: '1.1rem' }}>
                  Value Impact Validation
                </span>
                <p>
                  Understanding if the value impact of the beneficial outcomes
                  within the context of a typical consumer's life exprience was
                  worth the type and amount of investment they were willing and
                  able to make.
                  <br />
                  This equation lets us consider the most critical question when
                  supporting or funding a new venture: Is it worth it? To answer
                  that question, any proposer would be significantly advantaged
                  if they could estimate, predict, and validate any claim that
                  the intended typical consumer would consider the solution set
                  to be worth it.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-0 m-0 text-center home-evidence-div">
          <img src={Divisor} alt="barra-divisora" className="w-50 d-block" />
          <div
            className="col-7"
            style={{ marginLeft: '6%', textAlign: 'left' }}
          >
            {/* REVISARRRRRR */}
            <h3 className="font-bold my-5">
              What's the Evidence that it works?
            </h3>
            <p>
              I'm applying over thirty years of experience interacting with
              thought leaders and pioneering disruptive technologies across
              multiple market sectors to teach the Applied Business Blockchain,
              Web3 & Holochain: Realising Value course and the Future Proofing
              challenge to MAB Students and business executives from over twenty
              countries.
            </p>
            <p>
              For twenty years, the Digital Value Capture framework lived inside
              me; it was simply how I calculated the value impact of solving
              problems with innovative ideas on multiple systems. Now, I've
              begun to codify the framework into structured processes and
              coaching programs that I'm making available to the broader
              business community because I want to see more businesses built on
              the foundations of human-centric value.
            </p>
            <p>
              Using my unique experience designing decentralised solutions to
              solve complex, wicked multi-sector strategic problems, reduce
              operational pain, create verifiable meaningful value and generate
              the equivalent of digital alchemy to create a new class of digital
              assets, I can bridge the gap between business theory and the
              practical application.
            </p>
            <p className="mb-5">
              I draw upon real examples from almost every market sector when
              responding to student's questions to illustrate how each student
              can reduce the cost and risk of their innovative ideas to solve a
              given set of problems. In today's risk-averse funding culture,
              innovators will have to increase the odds of getting enough
              adoption for the initiative to work in terms of return on
              investment for both the financial investor and the consumer.
            </p>
          </div>
        </div>
        <div className="row p-0 m-0 text-center home-problem-div">
          <div className="col-10 mx-auto text-center">
            <h3 className="font-bold my-5">The Problem this solves</h3>
            <p>
              we all have ideas... but are they any good? To me, a GOOD IDEA
              means an idea for solving a problem real people care about enough
              that they'll actually invest in a solution.
            </p>
            <p>
              When evaluating ideas, traditional market research an ROI
              calculations fall short largely because they don't account for the
              human dimension of problem-solving: the experience of pain. Put
              simply - if your solution doesn't reduce their pain, they won't
              buy it.
            </p>
            <p>
              It's easy to spend a lot of time and money testing ideas. Many new
              projects run out of resources before gtting clarity on the problem
              they solve. And now the world has changed due to COVID. The
              systemused to reward risk-taking and there was investment money to
              burn, so your track record of failure didn't matter. New, money
              for new ventures has dried up. You - and your investors - can no
              loger afford an 80-90% project failure rate... or to be marked as
              a serial failure.
            </p>
            <p>
              I've developed a framework that helps you arrive at a qualified
              GOOD IDEA that has a high probability of success BEFORE you spend
              real tiem and money developing the new thing.
            </p>
            <p>
              When you can build solutions on solid foundations and high
              probability, you mitigat business risk by creating real value for
              real people. And you create a strong reputation for success, which
              is essential in the risk-averse economic environment we all live
              on now.
            </p>
            <p>
              {' '}
              That's why I've developed a step-by-step formula for evaluating
              new business ideas in a risk-averse post COVID economy - and a
              bundle of tools that will help you determine wheter anyone will
              buy your idea.
            </p>
          </div>
        </div>
        <div className="row p-0 m-0 home-divisor2"></div>
        <div className="row text-center home-digital-div">
          <div className="col-10 mx-auto text-center">
            <h3 className="font-bold my-5">What is Digital Value Capture</h3>
            <p>
              It's a value methodology for business-oriented professionals that
              allows business and human-centric value to drive the agenda,
              rather than technology-based strategies.
            </p>
            <p>
              Human-centric value is the value a person receives when they
              experience less pain. When assessing whether anyone cares enough
              about your idea to actually "buy" it - that is, either to purchase
              it or simply support it - Digital Value Capture begins with both
              the human and the business system's experience of pain.
            </p>
            <p>
              Once that pain reduces, we can begin to examine the value that
              lies in aspirations - that is... where the human and the business
              aspire to be. And that's usually somewhere better than they're
              currently heading.
            </p>
            <p>
              As a value practitioner, you can acquire the ability to validate
              human-centric value outcomes, such as truth and trust, alongside
              traditional metrics (like ROI). This allows you to differentiate
              yourself, as well as the businesses you create, from robots and
              AI-based self-learning systems.
            </p>
            <p>
              In later stags, the methodology also addresses the opportunities
              to covert captured verifiable value into tradable legal artefacts
              and derivatives, which will form the new types of digital
              exchangeable currencies that can be employed within both
              traditional centralised systems and the fast-emerging distributed
              models.
            </p>
            <p>
              If you can actually measure all types of value, including so
              called intangible (ie. non-numerical) value then you can predict,
              validate and then capture value. Capturing value allows us to
              optimise the way we do things today, do things differently and
              smarter, and even create a type of digital alchemy where we turn
              no-value and low-value data into new assets.
            </p>
            <p>
              When creating new assets from data, the sum total of the value
              assets you capture are often greater than the individual parts of
              any business. Digital alchemy is THE biggest business opportuniy
              of the next decade.
            </p>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Home;
