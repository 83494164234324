import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Nav, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Logo from '../../assets/img/logos/SA_logo.png';
import {
  getToken,
  removeSignup,
  setSignup,
  getSignup,
} from '../../config/index';
import axios from 'axios';
import toastr from 'toastr';
import './signup.css';
import { TermsAndConditions } from './components/TermsAndConditions.tsx';
import { PrivacyPolicy } from './components/PrivacyPolicy.tsx';

export class SignupConditions extends Component {
  state = {
    redirect: false,
    loading: false,
    showModal: false,
    viewModal: false,
    val: 0,
  };

  handleModal = (val) => {
    this.setState({
      showModal: val,
      viewModal: true,
    });
  };

  handleChange = (e) => {
    let val = 0;
    if (e.target.checked) {
      val = 1;
    } else {
      val = -1;
    }
    this.setState({
      val: this.state.val + val,
    });
  };

  handleSubmit = () => {
    if (this.state.val === 5) {
      if (this.state.viewModal) {
        this.setState({ loading: true });
        let data = {
          token: this.state.token,
        };
        axios
          .post('/api/agreements', data, {
            headers: { Authorization: 'Bearer ' + getToken() },
          })
          .then((response) => {
            if (response.data.ok) {
              removeSignup();
              setSignup(response.data.token);
              this.setState({
                loading: false,
                token: response.data.token,
                redirect: true,
              });
            } else {
              removeSignup();
              toastr.remove();
              toastr.warning(response.data.error);
              this.setState({ loading: false });
              setTimeout(() => {
                window.location = '/signup';
              }, 2000);
            }
          });
      } else {
        toastr.remove();
        toastr.warning('Read the terms and conditions first');
      }
    } else {
      toastr.remove();
      toastr.warning('You must agree with everthing.');
    }
  };

  componentDidMount() {
    if (getSignup()) {
      this.setState({
        token: getSignup(),
      });
    } else {
      toastr.remove();
      toastr.warning('Please fill Sign Up form again');
      setTimeout(() => {
        window.location = '/signup';
      }, 2000);
    }
  }

  render() {
    return !this.state.redirect ? (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row signup-conditions-section">
          <div className="signup-conditions-agreement-section">
            <p className="font-bold" style={{ fontSize: '1.2rem' }}>
              Conditional to use the App
            </p>
            <p>
              Digital Value Capture will assign you the purchaser and the user
              all ownership rights of the data you generate and the analytical
              results that you generate to yourself and then, respect that you
              own this data, forever. this we belive is the future for personal
              data where it's your property so we can only manage your data by
              your conditional consent
            </p>
            <p>
              This is on condition that you willing to grant consent for Digital
              Value Capture to
            </p>
            <div className="row m-0 p-0">
              <div className="col-1 m-0 p-0 text-center prueba">
                <label className="signup-checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="signup-checkbox-custom"></span>
                </label>
              </div>
              <div className="col-11 m-0 p-0">
                I accept{' '}
                <span
                  className="font-bold"
                  style={{ color: '#cea977', cursor: 'pointer' }}
                  onClick={() => {
                    this.handleModal(true);
                  }}
                >
                  terms and conditions
                </span>
              </div>
            </div>
            <br />
            <div className="row m-0 p-0">
              <div className="col-1 m-0 p-0 text-center prueba">
                <label className="signup-checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="signup-checkbox-custom"></span>
                </label>
              </div>
              <div className="col-11 m-0 p-0">
                <span>
                  Manage your data to provide you with the App services,
                  research the use of the methodology, improve the service and
                  provide support to you as the App platform requires for the
                  system to funcion. This includes the generating and sending of
                  reports as you request them.
                </span>
              </div>
            </div>
            <br />
            <div className="row m-0 p-0">
              <div className="col-1 m-0 p-0 text-center prueba">
                <label className="signup-checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="signup-checkbox-custom"></span>
                </label>
              </div>
              <div className="col-11 m-0 p-0">
                <span>
                  Manage your data to provide you with the App services,
                  research the use of the methodology, improve the service and
                  provide support to you as the App platform requires for the
                  system to funcion. This includes the generating and sending of
                  reports as you request them.
                </span>
              </div>
            </div>
            <br />
            <div className="row m-0 p-0">
              <div className="col-1 m-0 p-0 text-center prueba">
                <label className="signup-checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="signup-checkbox-custom"></span>
                </label>
              </div>
              <div className="col-11 m-0 p-0">
                <span>
                  Use your de-identified data & equations to enable analitycs
                  that populate the dashboards that enable searching to make
                  comparisons with other deidentified data to improve the
                  service.
                </span>
              </div>
            </div>
            <br />
            <div className="row m-0 p-0">
              <div className="col-1 m-0 p-0 text-center prueba">
                <label className="signup-checkbox-label">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                  />
                  <span className="signup-checkbox-custom"></span>
                </label>
              </div>
              <div className="col-11 m-0 p-0">
                <span>
                  This will also generate aggregated data of the collective use
                  of the <b>Digital Value Capture</b> methodology that will be
                  analysed to greatly improve the overall comprehension of that
                  good ideas have been proven to work. This high-level
                  perspective in turn will be shared for the common good,
                  learning in the <b>Digital Value Capture</b> academy and the
                  more granular level of analytics to foster new services within
                  the App platform.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="superCenter signup-conditions-button-section">
          <button
            className="btn signup-conditions-button"
            disabled={this.state.loading}
            onClick={this.handleSubmit}
          >
            {this.state.loading ? (
              <Spinner size="sm" animation="border" variant="light" />
            ) : (
              'Next'
            )}
          </button>
        </div>

        <Modal
          show={this.state.showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            this.handleModal(false);
          }}
        >
          <Modal.Body>
            {/* TERMS AND CONDITIONS */}
            <TermsAndConditions />
            {/* PRIVACY POLICY */}
            <PrivacyPolicy />
          </Modal.Body>
        </Modal>
      </div>
    ) : (
      <Redirect
        to={{
          pathname: '/public-data',
          state: { token: this.state.token },
        }}
      />
    );
  }
}

export default SignupConditions;
