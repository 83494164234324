import React, { Component } from 'react';
import Chart from 'react-apexcharts';

export class RadarChart extends Component {
  state = {
    options: {},
    series: [],
  };

  componentDidMount() {
    console.log('this.props.val', this.props.val);

    let labels = [];
    let max = 0;

    if (this.props.val.labels) {
      this.props.val.labels.forEach((element) => {
        if (element) {
          if (element.length > max) max = element.length;
          if (element.length > 30)
            labels.push(element.substring(0, 30) + '...');
          else {
            labels.push(element);
          }
        } else {
          labels.push('');
        }
      });
    }

    let series = [];
    if (this.props.val.series) {
      series = this.props.val.series;
    }

    let colors = '';
    if (!this.props.val.colors) {
      colors = [
        '#0091ea',
        '#e50000',
        '#00e676',
        '#7c4dff',
        '#ffd600',
        '#ff6d00',
        '#c5e1a5',
        '#ea80fc',
        '#4dd0e1',
        '#795548',
      ];
    } else {
      colors = this.props.val.colors;
    }

    let id = '';
    if (this.props.val.id) {
      id = this.props.val.id;
    }

    let options = {
      colors,
      legend: {
        position: 'bottom',
        offsetY: 0,
      },
      chart: {
        id,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: false,
        },
      },
      fill: {
        type: 'gradient',
        gradient: {
          shadeIntensity: 1,
          inverseColors: false,
          opacityFrom: 0.1,
          opacityTo: 0,
          stops: [0, 0, 100],
        },
      },
      labels: labels,
      yaxis: [
        {
          tickAmount: 10,
          min: 0,
          max: 10,
          forceNiceScale: true,
        },
      ],
    };
    this.setState({
      options,
      series,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.val !== prevProps.val) {
      console.log('this.props.val', this.props.val);
      let labels = [];
      let max = 0;

      if (this.props.val.labels) {
        this.props.val.labels.forEach((element) => {
          if (element) {
            if (element.length > max) max = element.length;
            if (element.length > 30)
              labels.push(element.substring(0, 30) + '...');
            else {
              labels.push(element);
            }
          } else {
            labels.push('');
          }
        });
      }

      let series = [];
      if (this.props.val.series) {
        series = this.props.val.series;
      }

      let colors = '';
      if (!this.props.val.colors) {
        colors = [
          '#0091ea',
          '#e50000',
          '#00e676',
          '#7c4dff',
          '#ffd600',
          '#ff6d00',
          '#c5e1a5',
          '#ea80fc',
          '#4dd0e1',
          '#795548',
        ];
      } else {
        colors = this.props.val.colors;
      }

      let id = '';
      if (this.props.val.id) {
        id = this.props.val.id;
      }

      let options = {
        colors,
        legend: {
          position: 'bottom',
          offsetY: 0,
        },
        chart: {
          id,
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.1,
            opacityTo: 0,
            stops: [0, 0, 100],
          },
        },
        labels: labels,
        yaxis: [
          {
            tickAmount: 10,
            min: 0,
            max: 10,
            forceNiceScale: true,
          },
        ],
      };
      this.setState(
        {
          options: {},
          series: [],
        },
        () => {
          this.setState({
            options,
            series,
          });
        },
      );
    }
  }

  render() {
    return (
      <Chart
        options={this.state.options}
        series={this.state.series}
        type="radar"
        height={600}
        width={'100%'}
      />
    );
  }
}

export default RadarChart;
