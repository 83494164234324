import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  componentDidMount() {
    this.props.changeVideo('932329828?h=b6c4a15a2c');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION TWO = ECO-SYSTEM PROBLEMS</b>
        </p>

        <p className="mb-4">
          By getting into conversation with others, you need to answer this
          fundamental question:
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            How does the root problem affect others in the eco, (like),
            interconnected and interdependent world that your solution set will
            operate within, and who or where they are?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can better understand the interconnected world and have
            sufficient confidence that the root problem exists and impacts the
            actors who are critical in supporting me in fixing the root problem.
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can better comprehend if each actor is aligned in their
            perspective and sufficiently impacted to be willing to support my
            endeavours to fix the problem as they see it.
          </span>
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
