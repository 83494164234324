import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import icon1 from '../../../../assets/img/icons/icon-21.png';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    problems: [
      { role: '', pain: '', score: 1 },
      { role: '', pain: '', score: 1 },
      { role: '', pain: '', score: 1 },
    ],
    ok: true,
    loading: true,
    edit: false,
    nextloading: false,
    chart: {},
    error: '',
  };

  handleRemove = (index) => {
    this.state.problems.splice(index, 1);
    this.setState(
      {
        edit: true,
        problems: this.state.problems,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role + ' - ' + element.pain);
      data.push(element.score);
    });
    let series = [];
    series.push({ name: 'As is', data: data });

    this.setState({
      chart: { labels, series, colors: ['#ed0000'] },
    });
  };

  handleBack = () => {
    this.props.next(14);
  };

  handleNext = () => {
    this.props.next(16);
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    if (e.target.name !== 'score') {
      if (
        e.target.value &&
        e.target.value[0] &&
        !e.target.value[0].match(/[0-9]/)
      ) {
        v[key][e.target.name] = e.target.value;
      } else {
        v[key][e.target.name] = '';
      }
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar();
        },
      );
    } else {
      v[key]['score'] = parseInt(e.target.value);
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar();
        },
      );
    }
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      let valid = true;
      let vacio = false;
      if (this.state.problems.length < 3) {
        valid = false;
      }
      this.state.problems.forEach((e) => {
        if (e.role === '') {
          vacio = true;
        }
      });
      if (valid) {
        if (!vacio) {
          const data = {
            id: this.props.id,
            problems: this.state.problems,
          };
          Axios.post('/api/app/deep_dive/modulo4/question2', data, {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              if (response.data.refill) {
                toastr.remove();
                toastr.warning(response.data.msg);
              } else {
                toastr.remove();
                toastr.success('Saved!');
              }
              this.props.next(16);
            } else {
              toastr.remove();
              toastr.warning(response.data.error);
            }
            this.setState({
              nextloading: false,
            });
          });
        } else {
          toastr.remove();
          toastr.warning("Can't be empty pain input");
        }
      } else {
        toastr.remove();
        toastr.warning('Minimum 3 type of pain per role');
      }
    } else {
      this.props.next(16);
    }
  };

  handleAdd = () => {
    this.setState({
      edit: true,
      problems: [...this.state.problems, { role: '', pain: '', score: 1 }],
    });
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo4/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.data.length > 0) {
          this.setState({
            problems: response.data.data,
          });
          this.makeRadar();
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 4.2</b>
              </p>
              <p className="mb-4">
                Who are the key actors directly involved in this scenario
                regardless of who they work for (ie customer; customer support;
                delivery ect), what type of pain do they experience and what is
                the magnitude of their pain on a scale of 1 to 10 where 1 = low
                pain and 10 = high pain. Pain type should be no more than 3
                words and you can add each role type more than once if they
                experience diﬀerent types of pain. Add a minimum of 3 and a
                maximum of 10.
              </p>
              <table style={{ width: '100%', margin: '2% 0' }}>
                <thead>
                  <tr>
                    <td>
                      <strong>Role</strong>
                    </td>
                    <td>
                      <strong>Pain type</strong>
                    </td>
                    <td>
                      <strong>Score 1-10</strong>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="role"
                          value={arg.role}
                          onChange={(e) => this.handleChange(e, index)}
                        />
                      </td>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="pain"
                          value={arg.pain}
                          onChange={(e) => this.handleChange(e, index)}
                        />
                      </td>
                      <td>
                        <select
                          className="form-control mx-auto w-75 text-center"
                          value={arg.score}
                          name="score"
                          id="score"
                          onChange={(e) => this.handleChange(e, index)}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </td>
                      <td>
                        <span
                          onClick={() => this.handleRemove(index)}
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            className="mx-2"
                            style={{ width: '30px' }}
                            src={icon1}
                            alt="delete"
                          />
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="btn ml-3 mt-4"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleAdd}
              >
                <i
                  className="fas fa-plus mr-1"
                  style={{ fontSize: '13px' }}
                ></i>
                Add
              </button>
              {this.state.problems && this.state.problems.length > 2 ? (
                <>
                  <hr />
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Scenario Pain Points
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the pain experienced by the key actors in the
                    scenario.{' '}
                  </p>
                  <RadarChart val={this.state.chart} />
                </>
              ) : null}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
