import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import toastr from 'toastr';
import { getToken, removeToken } from '../config/index';

//HOME
import Home from '../views/Home/Home';
import Academy from '../views/Academy/Academy';
import Journey from '../views/JourneyRoadmap/JourneyRoadmap';
import DeepDive from '../views/DeepDive/DeepDive';
import QuickLook from '../views/QuickLook/QuickLook';
import About from '../views/About/About';
import Quote from '../views/Quote/Quote';
import SendQuote from '../views/Quote/SendQuote';
import Signup from '../views/Signup/Signup';
import SignupConditions from '../views/Signup/SignupConditions';
import SignupOptional from '../views/Signup/SignupOptional';

import RecoverPass from '../views/RecoverPass/RecoverPass';
import NewPass from '../views/NewPass/NewPass';
import MiddlePass from '../views/NewPass/MiddlePass';
import TwoFactor from '../views/2fa/Use';
import CreateTwoFactor from '../views/2fa/Create';
import ConfirmAccount from '../views/Signup/ConfirmAccount';
import BuyModule from '../views/BuyModule/BuyModule';
import PaySuccess from '../views/BuyModule/PaySuccess';

import quickLookProjects from '../views/Projects/QuickLook/Projects';
import quickLookStatistics from '../views/Projects/QuickLook/Statistics';
import quickLookDashboard from '../views/Dashboard/QuickLook/Dashboard';

import deepDiveProjects from '../views/Projects/DeepDive/Projects';
import deepDiveDashboard from '../views/Dashboard/DeepDive/Dashboard';

export class Routes extends Component {
  state = {
    loading: true,
    auth: false,
    user: '',
    payedModules: '',
  };

  askToken = () => {
    Axios.get('/api/logged', {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.valid) {
          this.setState({
            loading: false,
            auth: true,
            user: response.data.user,
            payedModules: response.data.payedModules,
          });
        } else {
          this.setState({
            loading: false,
            auth: false,
          });
          if (response.data.error === 'TokenExpiredError') {
            toastr.remove();
            toastr.warning('Session expired, please sign in again');
            removeToken();
          }
        }
      })
      .catch((err) => {
        if (err.response.data.error === 'TokenExpiredError') {
          toastr.remove();
          toastr.warning('Session expired, please sign in again');
          removeToken();
        }
        this.setState({
          loading: false,
          auth: false,
        });
      });
  };

  componentDidMount() {
    this.askToken();
    setInterval(() => {
      this.askToken();
    }, 30000);
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <div className="col-2 text-center mx-auto my-5">
            <Spinner animation="border" variant="secondary" />
          </div>
        ) : (
          <BrowserRouter>
            <Switch>
              {this.state.auth ? (
                <Route
                  exact
                  path="/quick_look/projects"
                  component={quickLookProjects}
                />
              ) : null}
              {this.state.auth ? (
                <Route
                  exact
                  path="/quick_look/statistics"
                  component={quickLookStatistics}
                />
              ) : null}
              {this.state.auth ? (
                <Route
                  exact
                  path="/quick_look/project/:id"
                  component={quickLookDashboard}
                />
              ) : null}

              {this.state.auth ? (
                <Route
                  exact
                  path="/purchase_module/:id"
                  component={BuyModule}
                />
              ) : null}
              {this.state.auth ? (
                <Route
                  exact
                  path="/pay-stripe-success/:id/"
                  render={() => <PaySuccess valid={true} />}
                />
              ) : null}
              {this.state.auth ? (
                <Route
                  exact
                  path="/pay-stripe-cancel"
                  render={() => <PaySuccess valid={false} />}
                />
              ) : null}

              {this.state.auth &&
              this.state.payedModules.includes('deep_dive') ? (
                <Route
                  exact
                  path="/deep_dive/projects"
                  component={deepDiveProjects}
                />
              ) : null}
              {this.state.auth &&
              this.state.payedModules.includes('deep_dive') ? (
                <Route
                  exact
                  path="/deep_dive/project/:id"
                  component={deepDiveDashboard}
                />
              ) : null}

              {!this.state.auth ? (
                <Route exact path="/resetPassword" component={RecoverPass} />
              ) : null}
              {!this.state.auth ? (
                <Route
                  exact
                  path="/resetPassword/:token"
                  component={MiddlePass}
                />
              ) : null}
              {!this.state.auth ? (
                <Route exact path="/reset" component={NewPass} />
              ) : null}
              {!this.state.auth ? (
                <Route exact path="/twofactor" component={TwoFactor} />
              ) : null}
              {!this.state.auth ? (
                <Route
                  exact
                  path="/newUser/:token"
                  component={ConfirmAccount}
                />
              ) : null}
              {!this.state.auth ? (
                <Route
                  exact
                  path="/createtwofactor"
                  component={CreateTwoFactor}
                />
              ) : null}

              <Route
                exact
                path="/"
                render={() => <Home auth={this.state.auth} />}
              />
              <Route
                exact
                path="/academy"
                render={() => <Academy auth={this.state.auth} />}
              />
              <Route
                exact
                path="/journey_roadmap"
                render={() => <Journey auth={this.state.auth} />}
              />
              <Route
                exact
                path="/deep_dive"
                render={() => (
                  <DeepDive
                    auth={this.state.auth}
                    isPaid={this.state.payedModules.includes('deep_dive')}
                  />
                )}
              />
              <Route
                exact
                path="/quick_look"
                render={() => (
                  <QuickLook
                    auth={this.state.auth}
                    isPaid={this.state.payedModules.includes('quick_look')}
                  />
                )}
              />
              <Route
                exact
                path="/about"
                render={() => <About auth={this.state.auth} />}
              />
              <Route
                exact
                path="/signup/:id"
                render={() => (
                  <Signup auth={this.state.auth} organization={true} />
                )}
              />
              <Route
                exact
                path="/signup"
                render={() => <Signup auth={this.state.auth} />}
              />
              <Route exact path="/agreements" component={SignupConditions} />
              <Route exact path="/public-data" component={SignupOptional} />
              <Route
                exact
                path="/quote"
                render={() => <Quote auth={this.state.auth} />}
              />
              <Route
                exact
                path="/send_quote"
                render={() => <SendQuote auth={this.state.auth} />}
              />

              <Route exact render={() => <Home auth={this.state.auth} />} />
            </Switch>
          </BrowserRouter>
        )}
      </>
    );
  }
}

export default Routes;
