import React, { Component } from 'react';
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { setToken } from '../../config/index';
import { Form, Button, Spinner } from 'react-bootstrap';
import './use.css';
import Logo from '../../assets/img/landing/vectores-07.png';
import { getToken } from '../../config/index';
import Axios from 'axios';
import toastr from 'toastr';

export class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingQR: true,
      img: '',
      code: '',
      token: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({
      token: e.target.value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });

    const data = {
      token: this.state.token,
      code: this.state.code,
      id: this.props.location.state.id,
    };

    Axios.post('/api/create2fa', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        toastr.remove();
        toastr.success('Successfully created!');
        this.setState({ loading: false });
        setToken(response.data.token);
        setTimeout(() => {
          window.location = '/';
        }, 1000);
      } else {
        toastr.remove();
        toastr.warning('Invalid username or password');
        this.setState({ loading: false });
      }
    });
  };

  componentDidMount() {
    const data = {
      id: this.props.location.state.id,
    };

    Axios.post('/api/new2fa', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          url: response.data.url,
          code: response.data.code,
          loadingQR: false,
        });
      } else {
        toastr.remove();
        toastr.warning('Error');
      }
    });
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="create2fa-section text-center">
          <h3 className="font-bold mt-5" style={{ color: '#cea977' }}>
            2-FACTOR AUTHENTICATION
          </h3>
          <br />
          {this.state.loadingQR ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="m-0">Scan the QR with your phone</p>
              <img src={this.state.url} alt="QR" />
              <p className="mb-2">Or enter the following code manually</p>
              <p>
                <b>{this.state.code}</b>
              </p>
            </>
          )}

          <Form className="form mt-4 w-25 mx-auto" onSubmit={this.handleSubmit}>
            <Form.Group className="text-center">
              <span>Enter the 6-digits code from the app</span>
              <input
                className="form-control"
                type="text"
                id="token"
                onChange={this.handleInputChange}
                placeholder="2FA"
              />
            </Form.Group>
            <div className="submit-button text-center">
              <Button
                type="submit"
                className="btn create2fa-button"
                disabled={this.state.loading}
              >
                {this.state.loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Send'
                )}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default Create;
