import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../config/index';
import Axios from 'axios';
import toastr from 'toastr';

export class MiddlePass extends Component {
  state = {
    pass: 0,
    email: '',
  };
  componentDidMount() {
    Axios.get('/api/confirmAccount/' + this.props.match.params.token, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ pass: 1 });
        toastr.remove();
        toastr.success('Confirmed!');
      } else {
        this.setState({ pass: 1 });
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  }

  render() {
    if (this.state.pass !== 0) {
      return (
        <Redirect
          to={{
            pathname: '/',
          }}
        />
      );
    }
    return <></>;
  }
}

export default MiddlePass;
