import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    actorsScenario: '',
    rolesEnterprise: '',
    rolesEcosystem: '',
    edit: false,
    whoFund: '',
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(26);
  };

  handleNext = () => {
    this.props.next(28);
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
      [e.target.id]: e.target.value,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        actorsScenario: this.state.actorsScenario,
        rolesEnterprise: this.state.rolesEnterprise,
        rolesEcosystem: this.state.rolesEcosystem,
        whoFund: this.state.whoFund,
      };
      Axios.post('/api/app/deep_dive/modulo8/question1', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(28);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(28);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo8/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          actorsScenario: response.data.data.actorsScenario,
          rolesEcosystem: response.data.data.rolesEcosystem,
          rolesEnterprise: response.data.data.rolesEnterprise,
          whoFund: response.data.data.whoFund,
          loading: false,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 8.1</b>
              </p>

              <p className="mb-4">
                What is the value of your good idea? Please write a value
                proposition for each of the following groups:
              </p>
              <p>
                <b>For each of the actors from the scenario</b>
              </p>

              <textarea
                id="actorsScenario"
                onChange={this.handleChange}
                defaultValue={this.state.actorsScenario}
                className="description"
                placeholder="Write one sentence here:"
              ></textarea>

              <p>
                <b>For each of the roles of the enterprise</b>
              </p>

              <textarea
                id="rolesEnterprise"
                onChange={this.handleChange}
                defaultValue={this.state.rolesEnterprise}
                className="description"
                placeholder="Write one sentence here:"
              ></textarea>

              <p>
                <b>For each of the roles of the eco-system</b>
              </p>

              <textarea
                id="rolesEcosystem"
                onChange={this.handleChange}
                defaultValue={this.state.rolesEcosystem}
                className="description"
                placeholder="Write one sentence here:"
              ></textarea>

              <p>
                <b>For the people who will fund your good idea</b>
              </p>

              <textarea
                id="whoFund"
                onChange={this.handleChange}
                defaultValue={this.state.whoFund}
                className="description"
                placeholder="Write one sentence here:"
              ></textarea>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
