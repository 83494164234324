import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './About.css';
import Footer from '../../components/Footer/Footer';

export class About extends Component {
  render() {
    return (
      <>
        <NavBar auth={this.props.auth} about={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">About + Bio</h3>
          </div>
        </div>
        <div className="bio-banner-photo"></div>
        <div className="bio-section">
          <p>
            <b>Stephen Alexander</b>, Adjunct Professor at the Hult
            International Business School London campus, is the creator of the
            Digital Value Capture framework bringing his 30 years’ experience of
            interacting with thought leaders, including Bill Gates, whilst
            pioneering many disruptive technologies, including e-commerce, and
            driving digital transformation journeys across multiple market
            sectors and Smart Cities, to teach his Applied Business Blockchain,
            Web3 & Holochain: Realising Value course. In more recent times,
            Stephen has worked with many of the distributed ledger based
            technologies including Ethereum co-founder Joe Lubin, who funded
            Stephen to determine the total value potential of decentralised
            currencies and digital assets over the next ten years.
          </p>

          <p>
            Using his unique experience of designing decentralised solutions to
            solve complex wicked multi sector strategic problems, reduce
            operational pain, create verifiable meaningful value and generate
            the equivalent of digital alchemy to create a new class of digital
            assets, Stephen can bridge the gap between business theory and the
            practical application.
          </p>

          <p>
            Stephen draws upon real examples from almost every market sector
            when responding to the needs of each student's questions to
            illustrate how each student can reduce the cost, risk and liability
            of their own good idea to solve a given set of problems and increase
            the odds of the required adoption for the initiative to work.{' '}
          </p>

          <a href="/#" className="btn about-button my-5">
            Full portfolio
          </a>
        </div>
        <Footer />
      </>
    );
  }
}

export default About;
