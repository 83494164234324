import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    problems: [],
    description: '',
    ok: true,
    q2: [],
    edit: false,
    q3: [],
    loading: true,
    nextloading: false,
    chart: {},
    error: '',
  };

  handleBack = () => {
    this.props.next(21);
  };

  handleNext = () => {
    this.props.next(23);
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    let datai = [];
    let dataj = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role + ' - ' + element.pain);
      data.push(element.score);
      this.state.q2.forEach((e) => {
        if (element.role === e.role && element.pain === e.pain) {
          datai.push(e.score);
        }
      });
      this.state.q3.forEach((e) => {
        if (element.role === e.role && element.pain === e.pain) {
          dataj.push(e.score);
        }
      });
    });
    let series = [];
    series.push({ name: 'As is', data: datai });
    series.push({ name: 'Needs to be', data: dataj });
    series.push({ name: 'To be', data: data });

    this.setState({
      chart: { labels, series, colors: ['#ed0000', '#00e676', '#0091ea'] },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.problems;

    v[key]['score'] = parseInt(e.target.value);

    this.setState(
      {
        edit: true,
        problems: v,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo6/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(23);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(23);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo6/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        response.data.data.forEach((element) => {
          this.setState({
            problems: [
              ...this.state.problems,
              { role: element.role, pain: element.pain, score: element.score },
            ],
          });
        });
        response.data.q2.forEach((element) => {
          let esta = 0;
          this.setState({
            q2: [...this.state.q2, element],
          });
          this.state.problems.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain) esta = 1;
          });
          if (esta === 0) {
            this.setState({
              problems: [
                ...this.state.problems,
                { role: element.role, pain: element.pain, score: 1 },
              ],
            });
          }
        });
        response.data.q3.forEach((element) => {
          this.setState({
            q3: [...this.state.q3, element],
          });
        });
        this.setState({ description: response.data.description.description });
        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 6.2</b>
              </p>
              <p className="mb-4">
                If you were to go back to each of the roles in the scenario and
                present to them your good idea paragraph and then ask them to
                estimate how much pain would be reduced if they adopted its use,
                what would that be for each actor? Score on a scale of 1 to 10
                where 1 = low pain and 10 = high pain.
                <br />
                <br />
                Please watch the help video, which will help you ask the
                questions in a way that gets the best results.
              </p>
              <p>"{this.state.description}"</p>
              <table style={{ width: '100%', margin: '2% 0' }}>
                <thead>
                  <tr>
                    <td>
                      <strong>Role</strong>
                    </td>
                    <td>
                      <strong>Pain type</strong>
                    </td>
                    <td>
                      <strong>Score 1-10</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="role"
                          value={arg.role}
                          disabled
                          style={{ background: 'white', width: '100%' }}
                        />
                      </td>
                      <td>
                        <input
                          className="m-0 w-75 form-control form-check-input position-static"
                          type="text"
                          name="pain"
                          value={arg.pain}
                          disabled
                          style={{ background: 'white', width: '100%' }}
                        />
                      </td>
                      <td>
                        <select
                          className="form-control mx-auto w-75 text-center"
                          value={arg.score}
                          name="score"
                          id="score"
                          onChange={(e) => this.handleChange(e, index)}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Scenario To Be Radar Map
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of the pain experienced by the key actors in the
                scenario and where they feel their pain would be (to be) if the
                good idea was adopted.{' '}
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
