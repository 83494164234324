const TOKEN_KEY = 'SessionToken';

export function setToken(token) {
  sessionStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return sessionStorage.getItem(TOKEN_KEY);
}

export function removeToken() {
  sessionStorage.removeItem(TOKEN_KEY);
}

export function setOrg(id) {
  sessionStorage.setItem('OrganizationID', id);
}

export function removeOrg() {
  sessionStorage.removeItem('OrganizationID');
}

export function getOrg() {
  return sessionStorage.getItem('OrganizationID');
}

export function setSignup(data) {
  sessionStorage.setItem('signupData', data);
}

export function removeSignup() {
  sessionStorage.removeItem('signupData');
}

export function getSignup() {
  return sessionStorage.getItem('signupData');
}

export function logout() {
  sessionStorage.removeItem(TOKEN_KEY);
  window.location = '/';
}

export const selectStyle = {
  container: (provided) => ({
    ...provided,
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
  }),
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #cea977' : '1px solid #c3c3c3',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #cea977',
    },
    borderRadius: '10px',
  }),
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    maxHeight: '50vh',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? '#cea977'
      : state.isFocused
        ? '#cea97766'
        : 'white',
    borderBottom: state.data.value === 'Add one' ? '1px solid #bababa' : '0px',
    padding: state.data.value === 'Add one' ? '10px ' : '7px',
  }),
};

// export const STRIPE_PUBLIC =
//   'pk_test_51HIeMDJCPTUKB5PjfMThEAHKVR7Dmxpnem3yNsnm7jxT7QkP9xHQGKCA25gDDp0f2eymEjw92E1JdAPg1Od79MlP00s3qyCb9h';
export const STRIPE_PUBLIC = 'pk_live_ElaweO15lbkhRpcv9sDnyBPB';
