import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    supportChart: {},
    roles: [],
    problems: [],
    chart: {},
    chartI: {},
    root: '',
    loading: true,
    caused: [],
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo1/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          root: response.data.data.root,
          loading: false,
          caused: response.data.data.caused,
        });

        // SUPPORT
        let labels = [];
        let data = [];
        response.data.data.support.forEach((element) => {
          labels.push(element.role);
          data.push(element.score);
        });
        let series = [];
        series.push({ name: '', data: data });
        this.setState({
          supportChart: { labels, series, id: 'M1SupportChart' },
        });
        // END SUPPORT

        // PROBLEMS
        this.setState({
          problems: response.data.data.problems,
        });
        // END PROBLEMS

        // ENTERPRISE
        labels = [];
        let roles = [];
        response.data.data.radar.forEach((element) => {
          labels.push(element.problem);
        });
        response.data.data.radar[0].roles.forEach((element) => {
          roles.push(element.role);
        });
        roles.push('Client');
        series = [];
        roles.forEach((element) => {
          let scores = [];
          if (element !== 'Client') {
            response.data.data.radar.forEach((e) => {
              e.roles.forEach((e2) => {
                if (e2.role === element) {
                  scores.push(e2.score);
                }
              });
            });
            series.push({ name: element, data: scores });
          } else {
            response.data.data.client.forEach((c) => {
              scores.push(c.score);
            });
            series.push({ name: 'Client', data: scores });
          }
        });

        let labelsI = [];
        series.forEach((element) => {
          labelsI.push(element.name);
        });
        let seriesI = [];
        labels.forEach((element, index) => {
          let nuevo = [];
          series.forEach((e) => {
            nuevo.push(e.data[index]);
          });
          seriesI.push({ name: element, data: nuevo });
        });
        this.setState({
          chart: { labels, series, id: 'M1chart1' },
          chartI: { labels: labelsI, series: seriesI, id: 'M1chart2' },
        });
        // END ENTERPRISE
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>1. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Problem Index</b>
                  </p>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Problems</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">
                        <b>Description</b>
                      </p>
                    </div>
                  </div>
                  {this.state.problems.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-6">
                        <p className="my-1">{p.value}</p>
                      </div>
                      <div className="border col-6">
                        <p className="my-1">{p.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center mb-4">
                    <b>The Enterprise Radar Map</b>
                  </p>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    The Enterprise Actors experience of each problem
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of which problems effect the most actors
                  </p>
                  <RadarChart val={this.state.chart} graphic={this.graphic} />
                  <hr />
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    The Enterprise Actors experience of each problem
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of which actors experience the most amount of pain
                  </p>
                  <RadarChart val={this.state.chartI} graphic={this.graphic} />
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>The root problem description</b>
                  </p>
                  {this.state.root}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Problems caused by the root problem</b>
                  </p>
                  {this.state.caused.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-6">
                        <p className="my-1">{p.problem}</p>
                      </div>
                      <div className="border col-6">
                        <p className="my-1">{p.option}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Who Will Support The Good Idea?</b>
                  </p>
                  <RadarChart
                    val={this.state.supportChart}
                    graphic={this.graphic}
                  />
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
