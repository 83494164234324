import React, { Component } from 'react';
import './ScatterChart.css';
import Axios from 'axios';
import { getToken } from '../../config/index';
import ApexCharts from 'apexcharts';

export class ScatterChart extends Component {
  state = {
    loading: true,
    myProject: {},
    series: [],

    options: {
      colors: ['#cea977'],
      tooltip: {
        enabled: true,
        intersect: true,
        custom: () => {
          return `
            <div class="ScatterChart-cuadro row text-center">
              <div class='col-12 superCenter'>
                <div class="ScatterChart-circle mr-2"></div>
                <div class='ScatterChart-title'>
                  The Root Problem:
                </div>
              </div> 
              <div class="col-10 text-center mx-auto">
                <span>
                      ${this.state.myProject.root}
                </span>
              </div>
              <div class='col-12 superCenter mt-4'>
                <div class="ScatterChart-circle2 mr-2"></div>
                <div class='ScatterChart-title'>
                  Single Problems:
                </div>
              </div> 
                ${this.state.myProject.problems.map(
                  (arg) =>
                    `<div class="col-10 text-center mx-auto">
                    <span>${arg}</span>
                  </div>`,
                )}
            </div>
          `;
        },
      },
      grid: {
        show: true,
        borderColor: '#aaa',
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      chart: {
        height: 350,
        type: 'scatter',
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: false,
      },
      xaxis: {
        tickAmount: 2,
        min: 0,
        max: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
        },
        labels: {
          align: 'center',
          formatter: function (val) {
            if (val === 0) return 'Low';
            if (val === 5) return 'Your confidence';
            if (val === 10) return 'High';
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 2,
        min: 0,
        max: 10,
        axisBorder: {
          show: true,
          color: '#78909C',
        },
        labels: {
          align: 'right',
          minWidth: 100,
          formatter: function (val) {
            if (val === 0) return 'Low';
            if (val === 5) return 'Their willingness';
            if (val === 10) return 'High';
          },
        },
      },
    },
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/scatterChartReport?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.ok) {
          let series = [
            {
              name: 'My Project',
              data: [
                [
                  response.data.myProject.confidence,
                  response.data.myProject.willing,
                ],
              ],
            },
          ];
          this.setState(
            {
              myProject: response.data.myProject,
              series,
              loading: false,
            },
            () => {
              let x = this.state.options;
              x.series = series;
              var chart = new ApexCharts(document.querySelector('#scatter'), x);
              chart.render();
              setTimeout(() => {
                chart.dataURI().then(({ imgURI }) => {
                  if (this.props.graphic) {
                    this.props.graphic('scatterChart', imgURI);
                  }
                });
              }, 3000);
            },
          );
        }
      })
      .catch((err) => {
        console.log('err.response', err.response);
      });
  }

  render() {
    return <div id="scatter"></div>;
  }
}

export default ScatterChart;
