import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { setToken } from '../../config/index';
import { getToken } from '../../config/index';
import Axios from 'axios';
import './login.css';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      loading: false,
      create: false,
      twofa: false,
      id: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e) {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    const data = {
      name: this.state.username,
      password: this.state.password,
    };
    Axios.post('/api/login', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.valid) {
          this.setState({
            id: response.data.id,
          });
          if (response.data.twofa) {
            this.setState({
              twofa: true,
            });
          } else {
            if (response.data.create) {
              this.setState({
                create: true,
              });
            } else {
              toastr.remove();
              toastr.success('Logged Successfully');
              setToken(response.data.token);
              setTimeout(() => {
                window.location = '/';
              }, 1000);
            }
          }
        } else {
          this.setState({ loading: false });
          toastr.remove();
          toastr.warning(response.data.error);
        }
      })
      .catch((e) => console.log(e.response.data.error));
  };

  render() {
    return (
      <>
        {this.state.create ? (
          <Redirect
            to={{ pathname: '/createtwofactor', state: { id: this.state.id } }}
          />
        ) : null}
        {this.state.twofa ? (
          <Redirect
            to={{ pathname: '/twofactor', state: { id: this.state.id } }}
          />
        ) : null}

        <Form
          className="border-shadow row login-form"
          onSubmit={this.handleSubmit}
        >
          <h3 className="mx-auto mt-3 mb-5">Log in</h3>
          <input
            className="form-control login-input"
            id="username"
            type="text"
            placeholder="Username"
            onChange={this.handleInputChange}
          />
          <input
            className="form-control login-input"
            id="password"
            type="password"
            placeholder="Password"
            onChange={this.handleInputChange}
          />
          <button
            className="btn login-button"
            type="submit"
            disabled={this.state.loading}
          >
            {this.state.loading ? (
              <Spinner animation="border" variant="light" size="sm" />
            ) : (
              'Send'
            )}
          </button>
          <br />
          <p className="text-center w-100 p-0 m-0 font-bold">
            Don't have an account?{' '}
            <Link style={{ color: '#cea977' }} to="/signup">
              Register here
            </Link>
          </p>
          <p className="text-center w-100 p-0 m-0 font-bold">
            Forgot your password?{' '}
            <Link style={{ color: '#cea977' }} to="/resetPassword">
              Reset password here
            </Link>
          </p>
        </Form>
      </>
    );
  }
}

export default Login;
