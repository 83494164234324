import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    edit: false,
    actors: [],
    description: '',
    ok: true,
    nextloading: false,
    loading: true,
    chart: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  makeRadar = () => {
    let labels = [];
    let serie = [];
    this.state.actors.forEach((element) => {
      labels.push(element.actor);
      serie.push(element.score);
    });
    let series = [{ name: '', data: serie }];

    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.actors;
    v[key][e.target.name] = parseInt(e.target.value);
    this.setState(
      {
        actors: v,
        edit: true,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        actors: this.state.actors,
      };
      Axios.post('/api/app/quick_look/modulo2/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908489728?h=2c71db2cc3');
    Axios.get('/api/app/quick_look/modulo2/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState(
          {
            loading: false,
            actors: response.data.data,
            description: response.data.description,
          },
          () => {
            this.makeRadar();
          },
        );
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 2.2</b>
              </p>
              <p className="mb-4">
                Now you have identified all these actors, how confident are you
                that a general level of demand exists within the{' '}
                {this.props.startup ? 'new start up' : 'established enterprise'}{' '}
                to fix the root cause problem?
                <br />
                1 = low confidence and 10 = high confidence
                <br />
                <br />
                Do the other actors you need support from care? Why? (or why
                not)
              </p>
              <p className="mb-1 font-bold">
                The Root Cause Problem Statement:
              </p>
              <p className="mb-4">"{this.state.description}"</p>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="" style={{ width: '80%' }}>
                  <span className="font-bold">Actor</span>
                </div>
                <div className="text-center" style={{ width: '20%' }}>
                  <span className="font-bold">Score</span>
                </div>
              </div>
              {this.state.actors.map((arg, index) => (
                <div
                  key={index}
                  className="superCenter mx-auto"
                  style={{ width: '80%' }}
                >
                  <div className="" style={{ width: '80%' }}>
                    {arg.actor}
                  </div>
                  <div className="" style={{ width: '20%' }}>
                    <select
                      className="form-control w-75 mx-auto"
                      value={arg.score}
                      name="score"
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              ))}
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Problem Chart
                  </p>
                  {/* <p style={{fontSize:"13px", textAlign:"center"}}>Snap shot of the intensity to fix each problem</p> */}
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
