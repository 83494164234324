import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/modulo2/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        // Q2
        let q2labels = [];
        let q2data = [];
        response.data.data.q2.forEach((element) => {
          q2labels.push(element.actor);
          q2data.push(element.score);
        });
        let q2series = [];
        q2series.push({ name: '', data: q2data });
        // END Q2

        // Q3
        let q3labels = [];
        let q3data = [];
        response.data.data.q3.forEach((element) => {
          q3labels.push(element.actor);
          q3data.push(element.score);
        });
        let q3series = [];
        q3series.push({ name: '', data: q3data });
        // END Q3

        // Q4
        let q4labels = [];
        let q4data = [];
        response.data.data.q4.forEach((element) => {
          q4labels.push(element.actor);
          q4data.push(element.score);
        });
        let q4series = [];
        q4series.push({ name: '', data: q4data });
        // END Q4

        // Q5
        let q5labels = [];
        let q5data = [];
        response.data.data.q5.forEach((element) => {
          q5labels.push(element.actor);
          q5data.push(element.score);
        });
        let q5series = [];
        q5series.push({ name: '', data: q5data });
        // END Q5

        // Q6
        let q6labels = [];
        let q6data = [];
        response.data.data.q6.forEach((element) => {
          q6labels.push(element.actor);
          q6data.push(element.score);
        });
        let q6series = [];
        q6series.push({ name: '', data: q6data });
        // END Q6

        this.setState({
          loading: false,
          q1: response.data.data.q1,
          q2chart: { labels: q2labels, series: q2series, id: 'M2q2chart' },
          q3chart: { labels: q3labels, series: q3series, id: 'M2q3chart' },
          q4chart: { labels: q4labels, series: q4series, id: 'M2q4chart' },
          q5chart: { labels: q5labels, series: q5series, id: 'M2q5chart' },
          q6chart: { labels: q6labels, series: q6series, id: 'M2q6chart' },
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>2. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                {/* Q1 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Actors that are critical for your good idea to work</b>
                  </p>
                  <hr />
                  {this.state.q1.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-8 mx-auto">
                        <p className="my-1">{p.value}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Q2 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How confident are you that a general level of demand
                      exists within the new start up to fix the root cause
                      problem?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q2chart} graphic={this.graphic} />
                </div>

                {/* Q3 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Does your perspective of the root cause problem exist for
                      them and do they agree?{' '}
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q3chart} graphic={this.graphic} />
                </div>

                {/* Q4 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How strongly would they agree with your perspective that
                      they need to solve the root cause problem and reduce the
                      impact on the eco-system as a whole?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q4chart} graphic={this.graphic} />
                </div>

                {/* Q5 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How strongly would they agree with your suggestion that
                      there would be a strong general consensus to support your
                      good idea to fix the root cause problem?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q5chart} graphic={this.graphic} />
                </div>

                {/* Q6 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How much would they agree to making a meaningful
                      contribution in order to achieve the business goals that
                      are important to acquiring a critical mass of
                      adoption/usage of your good idea?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q6chart} graphic={this.graphic} />
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
