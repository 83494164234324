import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleNext = () => {
    this.props.next(2);
  };

  graphic = (id, img) => {
    if (this.props.graphic) {
      this.props.graphic(id, img);
    }
  };

  componentDidMount() {
    Axios.get(
      '/api/app/quick_look/modulo1/reportEnterprise?id=' + this.props.id,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    ).then((response) => {
      if (response.data.ok) {
        // Q3
        let q3labels = [];
        let q3data = [];
        response.data.data.q3.forEach((element) => {
          q3labels.push(element.problem);
          q3data.push(element.score);
        });
        let q3series = [];
        q3series.push({ name: '', data: q3data });
        // END Q3

        // Q4
        let q4labels = [
          'Senior Management',
          'Middle Management',
          'Operational',
        ];
        let q4series = [];
        response.data.data.q4.forEach((element) => {
          q4series.push({
            name: element.problem,
            data: [element.senior, element.middle, element.operational],
          });
        });
        // END Q4

        // Q5
        let q5labels = [
          'Senior Management',
          'Middle Management',
          'Operational',
        ];
        let q5series = [];
        response.data.data.q5.forEach((element) => {
          q5series.push({
            name: element.problem,
            data: [element.senior, element.middle, element.operational],
          });
        });
        // END Q5

        // Q7
        let q7labels = [
          'Senior Management',
          'Middle Management',
          'Operational',
        ];
        let q7series = [
          {
            name: '',
            data: [
              response.data.data.q7.senior,
              response.data.data.q7.middle,
              response.data.data.q7.operational,
            ],
          },
        ];
        // END Q7

        this.setState(
          {
            loading: false,
            startup: response.data.data.q1,
            q2: response.data.data.q2,
            q3chart: { labels: q3labels, series: q3series, id: 'M1q3chartE' },
            q4chart: { labels: q4labels, series: q4series, id: 'M1q4chartE' },
            q5chart: { labels: q5labels, series: q5series, id: 'M1q5chartE' },
            q6: response.data.data.q6,
            q7chart: { labels: q7labels, series: q7series, id: 'M1q7chartE' },
          },
          () => {
            this.props.load();
          },
        );
      } else {
        this.setState(
          {
            error: response.data.error,
          },
          () => {
            this.props.load();
          },
        );
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>1. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                {/* Q1 */}
                <p className="text-center">
                  <b>
                    {this.state.startup ? 'Start-up' : 'Established Enterprise'}
                  </b>
                </p>

                {/* Q2 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Problems that your good idea can address</b>
                  </p>
                  <hr />
                  {this.state.q2.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-8 mx-auto">
                        <p className="my-1">{p.value}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Q3 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How confident are you that a demand exists within the new
                      start up to fix these problems?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q3chart} graphic={this.graphic} />
                </div>

                {/* Q4 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Would the people in each of the three tiers agree that
                      these problems exist for them?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q4chart} graphic={this.graphic} />
                </div>

                {/* Q5 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Would the people in each of the three tiers want to
                      address these problems?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q5chart} graphic={this.graphic} />
                </div>

                {/* Q6 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>The root cause of those problems</b>
                  </p>
                  <hr />
                  <div className="row">
                    <div className="col-10 mx-auto">
                      <p className="my-1">{this.state.q6}</p>
                    </div>
                  </div>
                </div>

                {/* Q7 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Would you get the support required to fix the root
                      problem?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q7chart} graphic={this.graphic} />
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
