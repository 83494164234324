import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import './question2.css';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    description: '',
    problems: [],
    ok: true,
    edit: false,
    nextloading: false,
    loading: true,
    chart: {},
    error: '',
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role);
      data.push(element.score);
    });
    let series = [];
    series.push({ name: '', data: data });

    this.setState({
      chart: { labels, series },
    });
  };

  handleBack = () => {
    this.props.next(9);
  };

  handleNext = () => {
    this.props.next(11);
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    v[key]['score'] = parseInt(e.target.value);
    this.setState(
      {
        edit: true,
        problems: v,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
      [e.target.id]: e.target.value,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problem: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo2/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(11);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(11);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo2/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.data.length > 0) {
          this.setState({
            problems: response.data.data,
            description: response.data.prev,
          });
        } else {
          this.setState({
            description: response.data.prev,
          });
        }
        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 2.2</b>
              </p>
              <p className="mb-4">
                Please rate their intensity of the need to fix the root problem
                on a scale of 1 - 10 where 1 = not urgent and 10 = urgent.
              </p>
              <p className="mb-4">
                <b>The Root Problem</b>
              </p>
              <b>
                <blockquote>"{this.state.description}"</blockquote>
              </b>
              <hr />
              <table
                className="mx-auto"
                style={{ width: '90%', margin: '2% 0' }}
              >
                <thead>
                  <tr>
                    <td>
                      <strong>Actor / Role Types</strong>
                    </td>
                    <td className="text-center">
                      <strong>Score 1-10</strong>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <tr key={index}>
                      <td>{arg.role}</td>
                      <td className="center-td">
                        <select
                          className="form-control w-50 mx-auto"
                          value={arg.score}
                          name="score"
                          id="score"
                          onChange={(e) => this.handleChange(e, index)}
                        >
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                          <option>6</option>
                          <option>7</option>
                          <option>8</option>
                          <option>9</option>
                          <option>10</option>
                        </select>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <hr />
              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Eco System Radar Map
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of the intensity to fix the root problem
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
