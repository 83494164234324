import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getToken } from '../../config/index';
import Axios from 'axios';
import toastr from 'toastr';

export class MiddlePass extends Component {
  state = {
    pass: 0,
    email: '',
  };
  componentDidMount() {
    Axios.get('/api/resetPass/' + this.props.match.params.token, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          pass: 1,
          email: response.data.email,
        });
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
        this.setState({
          pass: 2,
        });
      }
    });
  }

  render() {
    if (this.state.pass === 1) {
      return (
        <Redirect
          to={{
            pathname: '/reset',
            state: { email: this.state.email },
          }}
        />
      );
    }
    if (this.state.pass === 2) {
      return (
        <Redirect
          to={{
            pathname: '/resetPassword',
          }}
        />
      );
    }
    return <></>;
  }
}

export default MiddlePass;
