import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    loading: true,
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/modulo1/reportStartup?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        // Q3
        let q3labels = [];
        let q3data = [];
        response.data.data.q3.forEach((element) => {
          q3labels.push(element.problem);
          q3data.push(element.score);
        });
        let q3series = [];
        q3series.push({ name: '', data: q3data });
        // END Q3

        // Q5
        let q5labels = [];
        let q5serie1 = [];
        let q5serie2 = [];
        let q5serie3 = [];
        response.data.data.q5.forEach((element) => {
          q5labels.push(element.problem);
          q5serie1.push(element.data[0].score);
          q5serie2.push(element.data[1].score);
          q5serie3.push(element.data[2].score);
        });

        let q5series = [
          { name: response.data.data.q5[0].data[0].actor, data: q5serie1 },
          { name: response.data.data.q5[0].data[1].actor, data: q5serie2 },
          { name: response.data.data.q5[0].data[2].actor, data: q5serie3 },
        ];
        // END Q5

        // Q6
        let q6labels = [];
        let q6serie1 = [];
        let q6serie2 = [];
        let q6serie3 = [];
        response.data.data.q6.forEach((element) => {
          q6labels.push(element.problem);
          q6serie1.push(element.data[0].score);
          q6serie2.push(element.data[1].score);
          q6serie3.push(element.data[2].score);
        });

        let q6series = [
          { name: response.data.data.q6[0].data[0].actor, data: q6serie1 },
          { name: response.data.data.q6[0].data[1].actor, data: q6serie2 },
          { name: response.data.data.q6[0].data[2].actor, data: q6serie3 },
        ];
        // END Q6

        // Q6
        let q8labels = [];
        let q8data = [];
        response.data.data.q8.forEach((element) => {
          q8labels.push(`${element.actor} - ${element.role}`);
          q8data.push(element.score);
        });
        let q8series = [];
        q8series.push({ name: '', data: q8data });
        // END Q8

        this.setState({
          loading: false,
          startup: response.data.data.q1,
          q2: response.data.data.q2,
          q3chart: { labels: q3labels, series: q3series, id: 'M1q3chartS' },
          q4: response.data.data.q4,
          q5chart: { labels: q5labels, series: q5series, id: 'M1q5chartS' },
          q6chart: { labels: q6labels, series: q6series, id: 'M1q6chartS' },
          q7: response.data.data.q7,
          q8chart: { labels: q8labels, series: q8series, id: 'M1q8chartS' },
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>1. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                {/* Q1 */}
                <p className="text-center">
                  <b>
                    {this.state.startup ? 'Start-up' : 'Established Enterprise'}
                  </b>
                </p>

                {/* Q2 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Problems that your good idea can address</b>
                  </p>
                  <hr />
                  {this.state.q2.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-8 mx-auto">
                        <p className="my-1">{p.value}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Q3 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      How confident are you that a demand exists within the new
                      start up to fix these problems?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q3chart} graphic={this.graphic} />
                </div>

                {/* Q4 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      What are the three roles of the most influential actors?
                    </b>
                  </p>
                  <hr />
                  <div className="row">
                    <div className="border col-5 ml-auto">
                      <p className="my-1 font-bold">Actor</p>
                    </div>
                    <div className="border col-5 mr-auto">
                      <p className="my-1 font-bold">Role</p>
                    </div>
                  </div>
                  {this.state.q4.map((p, index) => (
                    <div className="row" key={index}>
                      <div className="border col-5 ml-auto">
                        <p className="my-1">{p.actor}</p>
                      </div>
                      <div className="border col-5 mr-auto">
                        <p className="my-1">{p.role}</p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Q5 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Actors that agree that these problems exist for them</b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q5chart} graphic={this.graphic} />
                </div>

                {/* Q6 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>Actors that want to solve these problems</b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q6chart} graphic={this.graphic} />
                </div>

                {/* Q7 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>The root cause of those problems</b>
                  </p>
                  <hr />
                  <div className="row">
                    <div className="col-10 mx-auto">
                      <p className="my-1">{this.state.q7}</p>
                    </div>
                  </div>
                </div>

                {/* Q8 */}
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p className="text-center">
                    <b>
                      Would you get the support required to fix the root cause
                      problem?
                    </b>
                  </p>
                  <hr />
                  <RadarChart val={this.state.q8chart} graphic={this.graphic} />
                </div>

                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
