import React, { Component } from 'react';
// MODULO 1
import M1Intro from './Module1/Intro';
import M1Question1 from './Module1/Question1';
import M1Question2 from './Module1/Question2';
import M1Question3 from './Module1/Question3';
import M1Question4Startup from './Module1/startup/Question4';
import M1Question4Enterprise from './Module1/enterprise/Question4';
import M1Question5Startup from './Module1/startup/Question5';
import M1Question5Enterprise from './Module1/enterprise/Question5';
import M1Question6Startup from './Module1/startup/Question6';
import M1Question6Enterprise from './Module1/enterprise/Question6';
import M1Question7Startup from './Module1/startup/Question7';
import M1Question7Enterprise from './Module1/enterprise/Question7';
import M1Question8Startup from './Module1/startup/Question8';
import M1ReportEnterprise from './Module1/enterprise/Report';
import M1ReportStartup from './Module1/startup/Report';
// // MODULO 2
import M2Intro from './Module2/Intro';
import M2Question1 from './Module2/Question1';
import M2Question2 from './Module2/Question2';
import M2Question3 from './Module2/Question3';
import M2Question4 from './Module2/Question4';
import M2Question5 from './Module2/Question5';
import M2Question6 from './Module2/Question6';
import M2Report from './Module2/Report';
// // MODULO 3
import M3Intro from './Module3/Intro';
import M3Question1 from './Module3/Question1';
import M3Question2 from './Module3/Question2';
import M3Report from './Module3/Report';
// // MODULO 4
import M4Intro from './Module4/Intro';
import M4Question1 from './Module4/Question1';
import M4Question2 from './Module4/Question2';
import M4Question3 from './Module4/Question3';
import M4Report from './Module4/Report';
// // MODULO 5
import M5Intro from './Module5/Intro';
import M5Question1 from './Module5/Question1';
import M5Question2 from './Module5/Question2';
import M5Report from './Module5/Report';
// // MODULO 6
import M6Intro from './Module6/Intro';
import M6Question1 from './Module6/Question1';
import M6Question2 from './Module6/Question2';
import M6Report from './Module6/Report';
// // MODULO 7
import M7Intro from './Module7/Intro';
import M7Question1 from './Module7/Question1';
import M7Question2 from './Module7/Question2';
import M7Question3 from './Module7/Question3';
import M7Report from './Module7/Report';
// FINAL
import FinalReport from './Report';

export class Modules extends Component {
  nextModule = (val) => {
    this.props.changeOption(val);
  };

  changeVideo = (id) => {
    this.props.changeVideo(id);
  };

  handleLoad = () => {};

  render() {
    return (
      <>
        {/* MODULO 1 */}

        {this.props.option === 1 ? (
          <M1Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {!this.props.startup ? (
          <>
            {this.props.option === 2 ? (
              <M1Question1
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                changeStartUp={this.props.changeStartUp}
              />
            ) : null}
            {this.props.option === 3 ? (
              <M1Question2
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                startup={this.props.startup}
              />
            ) : null}
            {this.props.option === 4 ? (
              <M1Question3
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                startup={this.props.startup}
              />
            ) : null}
            {this.props.option === 5 ? (
              <M1Question4Enterprise
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 6 ? (
              <M1Question5Enterprise
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 7 ? (
              <M1Question6Enterprise
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 8 ? (
              <M1Question7Enterprise
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 9 ? (
              <M1ReportEnterprise
                nonext={true}
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                load={() => {
                  this.handleLoad();
                }}
              />
            ) : null}
          </>
        ) : (
          <>
            {this.props.option === 2 ? (
              <M1Question1
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                changeStartUp={this.props.changeStartUp}
              />
            ) : null}
            {this.props.option === 3 ? (
              <M1Question2
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                startup={this.props.startup}
              />
            ) : null}
            {this.props.option === 4 ? (
              <M1Question3
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                startup={this.props.startup}
              />
            ) : null}
            {this.props.option === 5 ? (
              <M1Question4Startup
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 6 ? (
              <M1Question5Startup
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 7 ? (
              <M1Question6Startup
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 8 ? (
              <M1Question7Startup
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 9 ? (
              <M1Question8Startup
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
              />
            ) : null}
            {this.props.option === 10 ? (
              <M1ReportStartup
                nonext={true}
                id={this.props.id}
                next={this.nextModule}
                changeVideo={this.changeVideo}
                load={() => {
                  this.handleLoad();
                }}
              />
            ) : null}
          </>
        )}

        {/* MODULO 2 */}

        {this.props.option === 11 ? (
          <M2Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 12 ? (
          <M2Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 13 ? (
          <M2Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 14 ? (
          <M2Question3
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 15 ? (
          <M2Question4
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 16 ? (
          <M2Question5
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 17 ? (
          <M2Question6
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 18 ? (
          <M2Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 3 */}

        {this.props.option === 19 ? (
          <M3Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 20 ? (
          <M3Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 21 ? (
          <M3Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 22 ? (
          <M3Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 4 */}

        {this.props.option === 23 ? (
          <M4Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 24 ? (
          <M4Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 25 ? (
          <M4Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 26 ? (
          <M4Question3
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 27 ? (
          <M4Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 5 */}

        {this.props.option === 28 ? (
          <M5Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 29 ? (
          <M5Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 30 ? (
          <M5Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 31 ? (
          <M5Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 6 */}

        {this.props.option === 32 ? (
          <M6Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 33 ? (
          <M6Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 34 ? (
          <M6Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 35 ? (
          <M6Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* MODULO 7 */}

        {this.props.option === 36 ? (
          <M7Intro next={this.nextModule} changeVideo={this.changeVideo} />
        ) : null}
        {this.props.option === 37 ? (
          <M7Question1
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 38 ? (
          <M7Question2
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 39 ? (
          <M7Question3
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
        {this.props.option === 40 ? (
          <M7Report
            nonext={true}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
            load={() => {
              this.handleLoad();
            }}
          />
        ) : null}

        {/* FIN */}

        {this.props.option === 41 ? (
          <FinalReport
            nonext={false}
            id={this.props.id}
            next={this.nextModule}
            changeVideo={this.changeVideo}
            startup={this.props.startup}
          />
        ) : null}
      </>
    );
  }
}

export default Modules;
