import React, { Component } from 'react';
import { getToken } from '../../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import RadarChart from '../../../../RadarChart/RadarChart';
import Spinner from 'react-bootstrap/Spinner';

export class Question5Enterprise extends Component {
  state = {
    problems: [],
    ok: true,
    edit: false,
    nextloading: false,
    loading: true,
    chart: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  makeRadar = () => {
    let labels = ['Senior Management', 'Middle Management', 'Operational'];
    let series = [];
    this.state.problems.forEach((element) => {
      series.push({
        name: element.problem,
        data: [element.senior, element.middle, element.operational],
      });
    });

    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    v[key][e.target.name] = parseInt(e.target.value);
    this.setState(
      {
        problems: v,
        edit: true,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/quick_look/modulo1/question5Enterprise', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908483715?h=9b57b88c10');
    Axios.get(
      '/api/app/quick_look/modulo1/question5Enterprise?id=' + this.props.id,
      {
        headers: { Authorization: 'Bearer ' + getToken() },
      },
    ).then((response) => {
      if (response.data.ok) {
        this.setState(
          {
            loading: false,
            problems: response.data.problems,
          },
          () => {
            this.makeRadar();
          },
        );
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.5</b>
              </p>
              <p className="mb-4">
                Would the actors in each of the three tiers in your established
                enterprise want to solve these problems? Rate their reaction.
                <br />
                <br />
                Each would be rated on a score of 1-10 where 1 = strongly
                disagree and 10 = strongly agree that the problems exist for
                them.
                <br />
                <br />
                Does everyone care the same amount? Are some people indifferent?
                Who has power? Do the people with power care?
              </p>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <div className="" style={{ width: '40%' }}>
                  <span className="font-bold">Problem</span>
                </div>
                <div className="text-center" style={{ width: '20%' }}>
                  <span className="font-bold">Senior Management</span>
                </div>
                <div className="text-center" style={{ width: '20%' }}>
                  <span className="font-bold">Middle Management</span>
                </div>
                <div className="text-center" style={{ width: '20%' }}>
                  <span className="font-bold">Operational</span>
                </div>
              </div>
              {this.state.problems.map((arg, index) => (
                <div
                  key={index}
                  className="superCenter mx-auto"
                  style={{ width: '95%' }}
                >
                  <div className="" style={{ width: '40%' }}>
                    {arg.problem}
                  </div>
                  <div className="" style={{ width: '20%' }}>
                    <select
                      className="form-control w-75 mx-auto"
                      value={arg.senior}
                      name="senior"
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                  <div className="" style={{ width: '20%' }}>
                    <select
                      className="form-control w-75 mx-auto"
                      value={arg.middle}
                      name="middle"
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                  <div className="" style={{ width: '20%' }}>
                    <select
                      className="form-control w-75 mx-auto"
                      value={arg.operational}
                      name="operational"
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              ))}
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Problem Chart
                  </p>
                  {/* <p style={{fontSize:"13px", textAlign:"center"}}>Snap shot of the intensity to fix each problem</p> */}
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question5Enterprise;
