import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import Logo from '../../assets/img/logos/SA_logo.png';
import { getToken, getSignup, removeSignup } from '../../config/index';
import axios from 'axios';
import toastr from 'toastr';
import './signup.css';

export class SignupOptional extends Component {
  state = {
    loading: false,
    checke: false,
  };

  handleChange = (e) => {
    this.setState({
      checke: e.target.checked,
    });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    let data = {
      token: this.state.token,
      checked: this.state.checke,
    };
    axios
      .post('/api/publicData', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      })
      .then((response) => {
        if (response.data.ok) {
          this.setState({ loading: false });
          removeSignup();
          toastr.remove();
          toastr.success('Check your email for confirmation');
          setTimeout(() => {
            window.location = '/';
          }, 2000);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
          this.setState({ loading: false });
          if (response.data.error === 'Please agreed with terms first') {
            setTimeout(() => {
              window.location = '/agreements';
            }, 2000);
          } else {
            if (
              response.data.error ===
              'Manipulated Data, please fill Sign Up form again'
            ) {
              removeSignup();
              setTimeout(() => {
                window.location = '/signup';
              }, 2000);
            }
          }
        }
      });
  };

  componentDidMount() {
    if (getSignup()) {
      this.setState({
        token: getSignup(),
      });
    } else {
      toastr.remove();
      toastr.warning('Please fill Sign Up form again');
      setTimeout(() => {
        window.location = '/signup';
      }, 2000);
    }
  }

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="signup-optional-section">
          <div className="row">
            <div className="signup-optional-agreement-section">
              <p className="font-bold" style={{ fontSize: '1.2rem' }}>
                Optional to use the App
              </p>
              <div className="row m-0 p-0">
                <div className="col-1 m-0 p-0 text-center prueba">
                  <label className="signup-checkbox-label">
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                    />
                    <span className="signup-checkbox-custom"></span>
                  </label>
                </div>
                <div className="col-11 m-0 p-0">
                  You may also choose to grant the consent to be approached to
                  participate in the commercialisation of your personal data
                  where <b>Digital Value Capture</b> would have to agree to your
                  own terms and conditions before acting as a trusted broker and
                  custodian to tokenise digital assets and trade them on your
                  behalf. <b>(optional)</b>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="signup-optional-button-section">
              <button
                className="btn signup-optional-button"
                disabled={this.state.loading}
                onClick={this.handleSubmit}
              >
                {this.state.loading ? (
                  <Spinner size="sm" animation="border" variant="light" />
                ) : (
                  'Next'
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignupOptional;
