import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChart';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    problems: [],
    ok: true,
    q42: [],
    q43: [],
    edit: false,
    q62: [],
    loading: true,
    nextloading: false,
    chart: {},
    error: '',
  };

  makeRadar = () => {
    let labels = [];
    let data = [];
    let data1 = [];
    let data2 = [];
    let data3 = [];
    this.state.problems.forEach((element) => {
      labels.push(element.role + ' - ' + element.p1);
      data.push(element.score);
      this.state.q42.forEach((e) => {
        if (element.role === e.role) data1.push(e.score);
      });
      this.state.q43.forEach((e) => {
        if (element.role === e.role) data2.push(e.score);
      });
      this.state.q62.forEach((e) => {
        if (element.role === e.role) data3.push(e.score);
      });
    });
    let series = [];
    series.push({ name: 'As is', data: data1 });
    series.push({ name: 'Needs to Be', data: data2 });
    series.push({ name: 'To Be', data: data3 });
    series.push({ name: 'Could Be', data: data });

    this.setState({
      chart: {
        labels,
        series,
        colors: ['#ed0000', '#00e676', '#0091ea', '#ffd600'],
      },
    });
  };

  handleBack = () => {
    this.props.next(23);
  };

  handleNext = () => {
    this.props.next(25);
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    if (e.target.name === 'score') {
      v[key][e.target.name] = parseInt(e.target.value);
      this.setState(
        {
          edit: true,
          problems: v,
        },
        () => {
          this.makeRadar();
        },
      );
    } else {
      if (
        e.target.value &&
        e.target.value[0] &&
        !e.target.value[0].match(/[0-9]/)
      ) {
        v[key][e.target.name] = e.target.value;
      } else {
        v[key][e.target.name] = '';
      }
      if (v[key][e.target.name].split(' ').length < 4) {
        this.setState(
          {
            edit: true,
            problems: v,
          },
          () => {
            this.makeRadar();
          },
        );
      } else {
        toastr.remove();
        toastr.warning('Max 3 words');
      }
    }
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        problems: this.state.problems,
      };
      Axios.post('/api/app/deep_dive/modulo7/question1', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(25);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(25);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo7/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        response.data.data.forEach((element) => {
          this.setState({
            problems: [
              ...this.state.problems,
              {
                role: element.role,
                pain: element.pain,
                p1: element.p1,
                score: element.score,
              },
            ],
          });
        });
        response.data.q42.forEach((element) => {
          let esta = 0;
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q42: [
              ...this.state.q42,
              { role: element.role, pain: element.pain, score: x },
            ],
          });

          this.state.problems.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain) esta = 1;
          });
          if (esta === 0) {
            this.setState({
              problems: [
                ...this.state.problems,
                { role: element.role, pain: element.pain, p1: '', score: 1 },
              ],
            });
          }
        });

        response.data.q43.forEach((element) => {
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q43: [
              ...this.state.q43,
              { role: element.role, pain: element.pain, score: x },
            ],
          });
        });

        response.data.q62.forEach((element) => {
          let x = element.score - 10;
          x = Math.abs(x);
          x++;
          this.setState({
            q62: [
              ...this.state.q62,
              { role: element.role, pain: element.pain, score: x },
            ],
          });
        });

        this.makeRadar();
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 7.1</b>
              </p>
              <p className="mb-4">
                Now go back to the pain points of each actor from the scenario
                and ask them to describe their aspirations, either as a feeling
                or a business improvement requirement (max 3 words) and then
                rate it’s value on scale of 1 - 10 where 1 = low value and 10 =
                high value.
                <br />
                <br />
                Please watch the help video, which will help you ask the
                questions in a way that gets the best results.
              </p>
              <table style={{ width: '100%', margin: '2% 0' }}>
                <tbody>
                  {this.state.problems.map((arg, index) => (
                    <React.Fragment key={index}>
                      <tr style={{ height: '45px', width: '75%' }}>
                        <td>
                          <b>{arg.role + ' - ' + arg.pain}</b>
                        </td>

                        <td
                          className="text-center mx-4"
                          style={{ width: '15%' }}
                        >
                          <b>Score 1 - 10</b>
                        </td>
                      </tr>
                      <tr style={{ borderBottom: '1px solid #dcdcdc' }}>
                        <td>
                          <input
                            className="form-control form-check-input position-static w-75 mx-2"
                            type="text"
                            name="p1"
                            value={arg.p1}
                            onChange={(e) => this.handleChange(e, index)}
                          />
                        </td>
                        <td>
                          <select
                            className="form-control mx-auto"
                            value={arg.score}
                            name="score"
                            onChange={(e) => this.handleChange(e, index)}
                          >
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                          </select>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              <p
                style={{
                  fontSize: '20px',
                  textAlign: 'center',
                  marginBottom: '0px',
                }}
              >
                Could Be Radar Map
              </p>
              <p style={{ fontSize: '13px', textAlign: 'center' }}>
                Snap shot of the value experienced by the key actors in the
                scenario if the good idea is adopted
              </p>
              <RadarChart val={this.state.chart} />
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
