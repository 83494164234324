import React, { Component } from 'react';
import NavBar from '../../components/NavBar/NavBar';
import './Academy.css';
import Footer from '../../components/Footer/Footer';

export class Academy extends Component {
  render() {
    return (
      <>
        <NavBar auth={this.props.auth} academy={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">I-Future Proof Community</h3>
          </div>
        </div>
        <div className="academy-section">
          <div className="row academy-text">
            <div>
              <p style={{ fontSize: '1.8rem' }} className="font-bold">
                I-Future Proof Community
              </p>
              <p style={{ fontSize: '1.4rem' }}>
                Sharing the knowledge of what works to make a good idea worth
                the human and financial investment in the world that awaits us
              </p>
              <p className="text-center">
                Free access for people who have a strong desire to know how to
                make a meaningful, measurable and purposeful impact on the
                interconnected systems that people rely on within the context of
                what will become the new normal in the highly intelligent,
                conscious, self-generating neuro-like network that we and
                everything will exist within or be influenced by.
                <br />
                <br />
                Learning about the notion of influencing the direction,
                magnitude and nature of the flow within those systems of what
                people experience as a measurable value impact that brings about
                a state change in their lives
                <br />
                <br />
                Discovering how its possible to capture the full range of value
                types as currencies, including human attributes, such as trust,
                as currency types, artefacts as common law property, and new
                classes of digital assets and capital that can be applied to a
                given purpose to realise a collective aspiration.
              </p>
              <p>
                Every week
                <br />
                As part of our program, you'll have the opportunity to engage in
                weekly surveys, quizzes, chats, and feedback sessions. These
                interactive elements are designed to enhance your learning
                experience and encourage active participation.
                <br />
                Live 30-minute video stream and Q&A exploring all the critical
                aspects of a structured 12-month agenda that will enable you to
                understand better what differentiates you from AI, how you could
                get above the pay grade determined by AI for work-related tasks,
                see the possibility of experiencing a state-change where the
                improbable becomes the possible and morphs into the probable
                just by discovering and sharing the knowledge of what works.
              </p>
              <p>
                Every month
                <br />
                Live interview and discussion on the hot issue of the day
              </p>
              <p>
                Special events
                <br />
                Special guest interviews and two-way interviews with members
              </p>
              <p>
                This is a general discussion community where any members who
                want to learn how to use the various methodologies are invited
                to sign up for the Digital Value Capture Apprenticeship
                Community and bring their own gig, start-up or project to share
                in the hands-on experience of using the Digital Value Capture
                Toolkit to realise the value potential of your good idea. In
                this Community, all of the weekly and monthly activities are
                centred around coaching you and mentoring your team's
                endeavours.
              </p>
            </div>
            <div className="col-sm-12 col-md-6">
              <p style={{ fontSize: '1.8rem' }} className="font-bold">
                Digital Value Capture Apprenticeship community
              </p>
              <p style={{ fontSize: '1.4rem' }}>
                A comprehensive toolkit using the methodologies within the
                Digital Value Capture Framework to use on your own gig, start-up
                or project
              </p>
              <p>12-month subscription</p>
              <p>
                Every week
                <br />
                Engage in weekly surveys, quizzes, chats, and feedback sessions
                <br />
                Live 30-minute video stream with examples and case studies and
                30 minutes of Q&A
              </p>
              <p>
                Every month
                <br />
                Live video exploration of a member’s gig with discussion and Q&A
                <br />
                Member-to-member showcase with discussion
              </p>
              <p>
                Special events
                <br />
                Interviews, special guest interviews and two-way interviews with
                members
                <br />
                Deep dive into the opportunities within disruptive tipping
                points and what will become a new normal
              </p>
              <p>Subscription US$99 per month.</p>
              <a
                href="http://ifutureproof.global/"
                className="btn academy-button"
              >
                Go to I-Future Proof Community now
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Academy;
