import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Visible, Hidden } from 'react-grid-system';
import icon_1 from '../../../assets/img/icons/icon-16.png';
import icon_2 from '../../../assets/img/icons/icon-17.png';
import icon_3 from '../../../assets/img/icons/icon-08.png';
import icon_4 from '../../../assets/img/icons/icon-10.png';
import icon_5 from '../../../assets/img/icons/icon-09.png';
import icon_6 from '../../../assets/img/icons/icon-13.png';
import icon_7 from '../../../assets/img/icons/icon-11.png';
import icon_8 from '../../../assets/img/icons/icon-12.png';
import icon_10 from '../../../assets/img/icons/icon-23.png';
import logo from '../../../assets/img/landing/vectores-07.png';
import Burger from './Burger';
import '../sidebar.css';
import { logout } from '../../../config/index';

export class Sidebar extends Component {
  state = {
    name: this.props.project,
  };

  render() {
    return (
      <>
        <Visible xs sm md>
          <Burger
            project={this.props.project}
            startup={this.props.startup}
            changeOption={this.props.changeOption}
          />
        </Visible>

        <Hidden xs sm md>
          <div className="sidebar" style={{ overflowY: 'auto' }}>
            <img
              className="sidebar-logo"
              src={logo}
              alt="Stephen_Alexander_Dashboard"
            />

            <div className="options">
              <p className="font-bold" style={{ fontSize: '1.2rem' }}>
                Quick Look
              </p>
              <button className="accordion-btn">
                <i className="fas fa-home mr-2"></i>
                <Link
                  className="optionHover"
                  to="/"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {' '}
                  Home{' '}
                </Link>
              </button>
              <button
                className="accordion-btn"
                data-target="#projectStates"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
              >
                <i className="fas fa-tasks mr-2"></i>
                <Link
                  className="optionHover"
                  to="/quick_look/projects"
                  style={{ textDecoration: 'none', color: 'black' }}
                >
                  {' '}
                  Projects{' '}
                </Link>
              </button>

              <button
                className="accordion-btn optionHover"
                type="button"
                data-toggle="collapse"
                aria-expanded="true"
              >
                <p className="m-0">
                  <img
                    className="sidebar-icons"
                    src={icon_1}
                    alt="Project Name"
                  />{' '}
                  {this.props.project}
                </p>
              </button>

              <button
                id="module1c"
                className="accordion-btn optionHover"
                data-target="#module1"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2 w-100">
                  <img
                    src={icon_2}
                    alt="Organisational"
                    className="sidebar-icons"
                  />{' '}
                  1. Organisational Problems
                </p>
              </button>
              <div className="collapse level-3" id="module1">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(1);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(2);
                    }}
                  >
                    Question 1.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(3);
                    }}
                  >
                    Question 1.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(4);
                    }}
                  >
                    Question 1.3
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(5);
                    }}
                  >
                    Question 1.4
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(6);
                    }}
                  >
                    Question 1.5
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(7);
                    }}
                  >
                    Question 1.6
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(8);
                    }}
                  >
                    Question 1.7
                  </li>
                  {this.props.startup ? (
                    <li
                      onClick={() => {
                        this.props.changeOption(9);
                      }}
                    >
                      Question 1.8
                    </li>
                  ) : null}
                  {this.props.startup ? (
                    <li
                      onClick={() => {
                        this.props.changeOption(10);
                      }}
                    >
                      Report
                    </li>
                  ) : (
                    <li
                      onClick={() => {
                        this.props.changeOption(9);
                      }}
                    >
                      Report
                    </li>
                  )}
                </ul>
              </div>

              <button
                id="module2c"
                className="accordion-btn optionHover"
                data-target="#module2"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_3}
                    alt="Eco System"
                    className="sidebar-icons"
                  />{' '}
                  2. Eco System Problems
                </p>
              </button>
              <div className="collapse level-3" id="module2">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(11);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(12);
                    }}
                  >
                    Question 2.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(13);
                    }}
                  >
                    Question 2.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(14);
                    }}
                  >
                    Question 2.3
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(15);
                    }}
                  >
                    Question 2.4
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(16);
                    }}
                  >
                    Question 2.5
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(17);
                    }}
                  >
                    Question 2.6
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(18);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                id="module3c"
                className="accordion-btn optionHover"
                data-target="#module3"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_4}
                    alt="Pain Points"
                    className="sidebar-icons"
                  />{' '}
                  3. Pain Points
                </p>
              </button>
              <div className="collapse level-3" id="module3">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(19);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(20);
                    }}
                  >
                    Question 3.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(21);
                    }}
                  >
                    Question 3.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(22);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                id="module4c"
                className="accordion-btn optionHover"
                data-target="#module4"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_5}
                    alt="A Typical Scenario"
                    className="sidebar-icons"
                  />{' '}
                  4. A Typical Scenario
                </p>
              </button>
              <div className="collapse level-3" id="module4">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(23);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(24);
                    }}
                  >
                    Question 4.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(25);
                    }}
                  >
                    Question 4.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(26);
                    }}
                  >
                    Question 4.3
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(27);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                id="module5c"
                className="accordion-btn optionHover"
                data-target="#module5"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_6}
                    alt="What if? Capability"
                    className="sidebar-icons"
                  />{' '}
                  5. What if? Capability
                </p>
              </button>
              <div className="collapse level-3" id="module5">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(28);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(29);
                    }}
                  >
                    Question 5.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(30);
                    }}
                  >
                    Question 5.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(31);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                id="module6c"
                className="accordion-btn optionHover"
                data-target="#module6"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_7}
                    alt="A Good Idea"
                    className="sidebar-icons"
                  />{' '}
                  6. A Good Idea
                </p>
              </button>
              <div className="collapse level-3" id="module6">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(32);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(33);
                    }}
                  >
                    Question 6.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(34);
                    }}
                  >
                    Question 6.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(35);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                id="module7c"
                className="accordion-btn text-wrap optionHover"
                data-target="#module7"
                type="button"
                data-toggle="collapse"
                aria-expanded="false"
                onClick={this.handleCollapse}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_8}
                    alt="Value Measurements"
                    className="sidebar-icons"
                  />{' '}
                  7. Value Measurements
                </p>
              </button>
              <div className="collapse level-3" id="module7">
                <ul>
                  <li
                    onClick={() => {
                      this.props.changeOption(36);
                    }}
                  >
                    Introduction
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(37);
                    }}
                  >
                    Question 7.1
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(38);
                    }}
                  >
                    Question 7.2
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(39);
                    }}
                  >
                    Question 7.3
                  </li>
                  <li
                    onClick={() => {
                      this.props.changeOption(40);
                    }}
                  >
                    Report
                  </li>
                </ul>
              </div>

              <button
                className="accordion-btn optionHover"
                type="button"
                onClick={() => {
                  this.props.changeOption(41);
                }}
              >
                <p className="m-0 level-2">
                  <img
                    src={icon_10}
                    alt="Value Proposition"
                    className="sidebar-icons"
                  />{' '}
                  Final Report
                </p>
              </button>

              {/* <button className="accordion-btn optionHover">
                <i className="fas fa-user-alt mr-2"></i> My Profile
              </button> */}

              <button className="accordion-btn optionHover" onClick={logout}>
                <i className="fas fa-sign-out-alt mr-2"></i> Log out
              </button>
            </div>
          </div>
        </Hidden>
      </>
    );
  }
}

export default Sidebar;
