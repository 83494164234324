import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    description: '',
    edit: false,
    root: '',
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(13);
  };

  handleNext = () => {
    this.props.next(15);
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
      [e.target.id]: e.target.value,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        description: this.state.description,
      };
      Axios.post('/api/app/deep_dive/modulo4/question1', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(15);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(15);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo4/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          description: response.data.data.description,
          root: response.data.prev,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 4.1</b>
              </p>
              <p className="mb-4">
                Thinking about your root problem, can you describe a typical day
                in the life of a customer scenario that is adversely impacted by
                the root problem and then identify where the most pain is
                experienced by each named actor. This will be used to measure
                the impact on each actor/role if the root problem was fixed.
                Write a short sentence to define this high level end-to-end
                process.
              </p>
              <p>
                <b>The Root Problem</b>
              </p>
              <p>"{this.state.root}"</p>

              <p className="mt-2 mb-0">
                <b>A Typical Scenario</b>
              </p>
              <textarea
                id="description"
                onChange={this.handleInputChange}
                defaultValue={this.state.description}
                className="description"
                placeholder="Describe a typical scenario in a paragraph"
              ></textarea>

              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
