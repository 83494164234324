import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import RadarChart from '../../../RadarChart/RadarChart';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    edit: false,
    pains: [],
    options: ['pain1', 'pain2', 'pain3', 'pain4', 'pain5'],
    add: false,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
    chart: {},
  };

  makeRadar = () => {
    let labels = [];
    let serie = [];
    this.state.pains.forEach((element) => {
      labels.push(element.pain);
      serie.push(element.score);
    });
    let series = [{ name: '', data: serie }];

    this.setState({
      chart: { labels, series },
    });
  };

  handleChange = (e, key) => {
    let v = this.state.pains;
    v[key][e.target.name] = parseInt(e.target.value);
    this.setState(
      {
        pains: v,
        edit: true,
      },
      () => {
        this.makeRadar();
      },
    );
  };

  handleAdd = () => {
    if (this.state.pains.length < 10) {
      this.setState({
        pains: [...this.state.pains, { pain: '', option: '' }],
        add: false,
        edit: true,
      });
    } else {
      toastr.remove();
      toastr.warning('10 pain criteria max each with 3 words max');
    }
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        pains: this.state.pains,
      };
      Axios.post('/api/app/quick_look/modulo3/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908877568?h=c2f812297d');
    Axios.get('/api/app/quick_look/modulo3/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.data.length === 0) {
          this.setState({
            pains: [{ pain: '', option: '' }],
            add: false,
          });
        } else {
          response.data.data.forEach((element) => {
            let addd = true;
            if (element.pain === '') {
              addd = false;
            }
            this.setState(
              {
                pains: [...this.state.pains, element],
                add: addd,
              },
              () => {
                this.makeRadar();
              },
            );
          });
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 3.2</b>
              </p>
              <p className="mb-4">
                Now you have identified all the pain criteria types, how
                confident are you that the pain is of sufficient magnitude to
                motivate the adoption of any solution to reduce that pain to an
                acceptable level from their perspective?
                <br />
                <br />
                Each would be rated on a score of 1-10 where 1= not confident at
                all and 10 = strongly confident
                <br />
                <br />
                Please consider if they are in enough pain to DO something to
                address it. Is their pain more uncomfortable than their concern
                about the risks of supporting your new idea?
              </p>
              <div className="row mx-auto" style={{ width: '80%' }}>
                <div className="text-left" style={{ width: '70%' }}>
                  <span className="font-bold">Pain</span>
                </div>
                <div className="text-center" style={{ width: '30%' }}>
                  <span className="font-bold">Score</span>
                </div>
              </div>
              <br />
              {this.state.pains.map((arg, index) => (
                <div
                  key={index}
                  className="row mx-auto"
                  style={{ width: '80%' }}
                >
                  <div className="text-left" style={{ width: '70%' }}>
                    {arg.pain}
                  </div>
                  <div className="superCenter" style={{ width: '30%' }}>
                    <select
                      className="form-control w-50"
                      value={arg.score}
                      name="score"
                      onChange={(e) => this.handleChange(e, index)}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </div>
                </div>
              ))}
              <hr />
              {this.state.chart !== '' ? (
                <>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    My Magnitude of Pain Assessment
                  </p>
                  {/* <p style={{fontSize:"13px", textAlign:"center"}}>Snap shot of the intensity to fix each problem</p> */}
                  <RadarChart val={this.state.chart} />
                  <hr />
                </>
              ) : null}
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
