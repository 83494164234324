import React, { Component } from 'react';
import question from '../../../../assets/img/icons/ICONO-23.png';
import idea from '../../../../assets/img/icons/ICONO-22.png';

export class Intro extends Component {
  handleNext = () => {
    this.props.next(1);
  };

  handleBack = () => {
    this.props.next(-1);
  };

  componentDidMount() {
    this.props.changeVideo('932370275?h=e1ae3d6c13');
  }

  render() {
    return (
      <>
        <p className="question-title text-center">
          <b>SECTION FOUR = A TYPICAL SCENARIO</b>
        </p>

        <p className="mb-4">
          By conversing with others familiar with the life circumstances of
          typical consumers, you can start to map how the pain points impact
          them. This will enable you to answer this question:
        </p>
        <div className="d-flex mb-4">
          <img
            src={question}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            How does this pain manifest itself in a typical day in the life
            scenario from a user (UX) experience perspective, and what pain
            reduction needs to be achieved to get adoption?
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can better understand the impact of the pain at an operational
            level and see the pain points for each actor or role as they
            experience them.
          </span>
        </div>
        <div className="d-flex mb-4">
          <img
            src={idea}
            alt="pregunta"
            style={{
              color: '#cea977',
              marginRight: '1.25rem',
              width: '25px',
              height: '22px',
              marginTop: '5px',
            }}
          />
          <span>
            Now I can establish where to prioritise any effort to reduce the
            critical pain by addressing the root problem.
          </span>
        </div>
        <hr />
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            display: 'flex',
          }}
        >
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleBack}
          >
            <i className="fas fa-arrow-left"></i> Back
          </button>
          <button
            className="btn my-4 p-3"
            style={{
              color: 'white',
              backgroundColor: '#cea977',
              borderRadius: '10px',
            }}
            onClick={this.handleNext}
          >
            Next <i className="fas fa-arrow-right"></i>
          </button>
        </div>
      </>
    );
  }
}

export default Intro;
