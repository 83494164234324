import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question2 extends Component {
  state = {
    question: '',
    edit: false,
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(27);
  };

  handleNext = () => {
    this.props.next(29);
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      edit: true,
      [e.target.name]: e.target.value,
    });
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        question: this.state.question,
      };
      Axios.post('/api/app/deep_dive/modulo8/question2', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(29);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(29);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo8/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          question: response.data.data,
          loading: false,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 8.2</b>
              </p>

              <p className="mb-4">
                If you wanted your target customer to find you using an AI
                assistant (such as Hey Google or Siri) or even a personal AI
                Bot, what question would they have to ask to find you?
              </p>
              <textarea
                name="question"
                onChange={this.handleChange}
                defaultValue={this.state.question}
                className="description"
                placeholder="Please enter the question"
              ></textarea>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
