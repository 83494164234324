import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import Axios from 'axios';
import { getToken, STRIPE_PUBLIC } from '../../config/index';
import './BuyModule.css';
import { loadStripe } from '@stripe/stripe-js';
import NavBar from '../../components/NavBar/NavBar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const stripePromise = loadStripe(STRIPE_PUBLIC);

export class BuyModule extends Component {
  state = {
    showPayModal: false,
    loadingStipe: false,
    moduleId: this.props.match.params.id,
  };

  handlePayModal = (value) => {
    this.setState({
      showPayModal: value,
    });
  };

  goBack = () => {
    return `/${this.state.moduleId}`;
  };

  payStripe = async () => {
    let stripe = await stripePromise;

    this.setState({ loadingStipe: true });
    Axios.get(`/api/pay-stripe/${this.state.moduleId}`, {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.ok) {
          stripe
            .redirectToCheckout({ sessionId: response.data.id })
            .then((result) => {
              console.log('result', result);
            });
        } else {
          this.setState({ loadingStipe: false });
          toastr.remove();
          toastr.warning(response.data.error);
        }
      })
      .catch((err) => {
        this.setState({ loadingStipe: false });
        toastr.remove();
        console.log('err', err);
        toastr.warning(err.response.data.error);
      });
  };

  render() {
    return (
      <>
        <NavBar auth={true} deepdive={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="row p-0 mx-auto buy-container">
          <div
            className="col-12 mx-auto"
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
            }}
          >
            <p className="font-bold" style={{ color: '#cea977' }}>
              In order to get the {this.state.moduleId.replace('_', ' ')} module
              please upgrade.
            </p>
          </div>
          <Link
            to={this.goBack}
            className="btn buy-button-problem ml-auto mr-3 superCenter"
          >
            Go back
          </Link>
          <button
            className="btn buy-button-deepdive mr-auto ml-3"
            onClick={() => this.handlePayModal(true)}
          >
            Upgrade and buy
            <br />
            {this.state.moduleId.replace('_', ' ')} Module now
          </button>
        </div>
        {/* PAY MODAL */}
        <Modal
          show={this.state.showPayModal}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => this.handlePayModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <p style={{ fontSize: '1.2rem' }}>Select Payment Method</p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 text-center my-3">
                <button
                  className="btn buy-stripe-button"
                  onClick={this.payStripe}
                  disabled={this.state.loadingStripe}
                >
                  {this.state.loadingStipe ? (
                    <Spinner animation="border" size="sm" variant="light" />
                  ) : (
                    ''
                  )}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* PAY MODAL */}
      </>
    );
  }
}

export default BuyModule;
