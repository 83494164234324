import React, { Component } from 'react';
import icon from '../../../../assets/img/icons/icon-21.png';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { selectStyle, getToken } from '../../../../config/index';

export class Question2 extends Component {
  state = {
    edit: false,
    problems: [],
    options: [{ value: 'Add one', label: 'Add one' }],
    ok: true,
    add: false,
    nextloading: false,
    loading: true,
    error: '',
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleRemove = (index) => {
    let v = this.state.problems;
    v.splice(index, 1);
    let addd = true;
    v.forEach((element) => {
      if (
        element.value === 'Add one' &&
        element.nuevo &&
        element.nuevo === ''
      ) {
        addd = false;
      }
    });
    this.setState({
      problems: v,
      add: addd,
      edit: true,
    });
  };

  handleChangeSelect = (selectedOption, index) => {
    let v = this.state.problems;
    if (selectedOption.value) v[index].value = selectedOption.value;
    if (selectedOption.label) v[index].label = selectedOption.label;
    if (selectedOption.nuevo) v[index].nuevo = selectedOption.nuevo;
    if (selectedOption.description)
      v[index].description = selectedOption.description;

    let add = false;
    if (selectedOption.value !== 'Add one') add = true;
    this.setState({
      problems: v,
      add,
      edit: true,
    });
  };

  handleChange = (e, key) => {
    let v = this.state.problems;
    toastr.remove();
    if (!(e.target.value.split(' ').length > 3)) {
      if (
        e.target.value &&
        e.target.value[0] &&
        !e.target.value[0].match(/[0-9]/)
      ) {
        v[key].nuevo = e.target.value;
      } else {
        v[key].nuevo = '';
      }
      let add = true;
      v.forEach((element) => {
        if (
          element.value === 'Add one' &&
          element.nuevo &&
          element.nuevo === ''
        ) {
          add = false;
        }
      });
      this.setState({
        problems: v,
        add,
        edit: true,
      });
    } else {
      toastr.warning('3 words max');
    }
  };

  handleAdd = () => {
    if (this.state.problems.length < 5) {
      this.setState({
        problems: [...this.state.problems, { value: '', label: '' }],
        add: false,
        edit: true,
      });
    } else {
      toastr.remove();
      toastr.warning('Max 5 problems');
    }
  };

  getOccurrence = (array, value) => {
    return array.filter(
      (v) =>
        (v.value === 'Add one' && v.nuevo.toLowerCase() === value) ||
        v.value.toLowerCase() === value,
    ).length;
  };

  handleSave = () => {
    if (this.state.edit) {
      let repetidos = false;
      this.state.problems.forEach((element) => {
        if (element.value === 'Add one') {
          if (
            this.getOccurrence(
              this.state.problems,
              element.nuevo.toLowerCase(),
            ) > 1
          ) {
            repetidos = true;
          }
        } else {
          if (
            this.getOccurrence(
              this.state.problems,
              element.value.toLowerCase(),
            ) > 1
          ) {
            repetidos = true;
          }
        }
      });
      if (!repetidos) {
        this.setState({
          nextloading: true,
        });
        const data = {
          id: this.props.id,
          problems: this.state.problems,
        };
        Axios.post('/api/app/quick_look/modulo1/question2', data, {
          headers: { Authorization: 'Bearer ' + getToken() },
        }).then((response) => {
          if (response.data.ok) {
            toastr.remove();
            toastr.success('Saved!');
            this.props.next(1);
          } else {
            toastr.remove();
            toastr.warning(response.data.error);
          }
          this.setState({
            nextloading: false,
          });
        });
      } else {
        toastr.remove();
        toastr.warning('Repeated elements are not allowed');
      }
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908480143?h=7f3414dcad');
    Axios.get('/api/app/quick_look/modulo1/question2?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          loading: false,
          options: this.state.options.concat(response.data.options),
          problems: response.data.data,
          add: true,
        });
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 1.2</b>
              </p>
              <p className="mb-4">
                What problems do you think your good idea can fix? (maximum of
                three words per problem and a maximum of five problems)
                <br />
                <br />
                What systems and processes are broken? What causes the most
                pain? What keeps someone (or you) awake and worrying at 3 am?
              </p>
              {this.state.problems.map((arg, index) => (
                <div key={index} className="row">
                  {arg.value !== 'Add one' ? (
                    <div className="col-10 superCenter">
                      <Select
                        value={arg.value === '' ? '' : arg}
                        onChange={(e) => this.handleChangeSelect(e, index)}
                        options={this.state.options}
                        styles={selectStyle}
                        placeholder="Choose title or add new"
                      />
                    </div>
                  ) : (
                    <>
                      <div className="col-2 superCenter">
                        <Select
                          value={arg.value === '' ? '' : arg}
                          onChange={(e) => this.handleChangeSelect(e, index)}
                          options={this.state.options}
                          styles={selectStyle}
                          placeholder="Choose title or add new"
                        />
                      </div>
                      <div className="col-8 superCenter">
                        <input
                          className="form-control"
                          name="nuevo"
                          value={arg.nuevo}
                          onChange={(e) => this.handleChange(e, index)}
                        ></input>
                        <small
                          style={{
                            position: 'absolute',
                            top: '80%',
                            left: '3%',
                          }}
                        >
                          3 words max
                        </small>
                      </div>
                    </>
                  )}
                  <div className="col-2 superCenter">
                    <span className="m-auto">
                      <img
                        style={{ width: '30px', cursor: 'pointer' }}
                        src={icon}
                        alt="delete"
                        onClick={() => this.handleRemove(index)}
                      />
                    </span>
                  </div>
                </div>
              ))}
              {this.state.problems.length < 5 ? (
                this.state.add ? (
                  <button
                    className="btn ml-3 mt-3"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      backgroundColor: '#cea977',
                      borderRadius: '10px',
                    }}
                    onClick={this.handleAdd}
                  >
                    <i
                      className="fas fa-plus mr-1"
                      style={{ fontSize: '13px' }}
                    ></i>
                    Add or Choose
                  </button>
                ) : (
                  <button
                    className="btn ml-3 mt-3"
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: 'white',
                      backgroundColor: '#cea977',
                      borderRadius: '10px',
                    }}
                    onClick={this.handleAdd}
                    disabled
                  >
                    <i
                      className="fas fa-plus mr-1"
                      style={{ fontSize: '13px' }}
                    ></i>
                    Add or Choose
                  </button>
                )
              ) : null}
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question2;
