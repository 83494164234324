import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import RadarChart from '../../../RadarChart/RadarChartReport';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    chart: {},
    loading: true,
    escenario: '',
    error: '',
  };

  handleBack = () => {
    this.props.next(16);
  };

  handleNext = () => {
    this.props.next(18);
  };

  graphic = (id, img) => {
    if (this.props.graphic) {
      this.props.graphic(id, img);
    }
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo4/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          escenario: response.data.escenario.description,
          loading: false,
        });
        let labels = [];
        let data = [];
        let dataprev = [];
        response.data.data.forEach((element) => {
          labels.push(element.role + ' - ' + element.pain);
          data.push(element.score);
          response.data.prev.forEach((e) => {
            if (element.role === e.role && element.pain === e.pain) {
              dataprev.push(e.score);
            }
          });
        });
        let series = [];
        series.push({ name: 'As is', data: dataprev });
        series.push({ name: 'Needs to be', data: data });

        this.setState(
          {
            chart: {
              labels,
              series,
              colors: ['#ed0000', '#00e676'],
              id: 'M4chart',
            },
          },
          () => {
            this.props.load();
          },
        );
      } else {
        this.setState(
          {
            error: response.data.error,
          },
          () => {
            this.props.load();
          },
        );
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>4. Report</b>
              </p>
            ) : null}

            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    The typical scenario description
                  </p>
                  {this.state.escenario}
                </div>

                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <p
                    style={{
                      fontSize: '20px',
                      textAlign: 'center',
                      marginBottom: '0px',
                    }}
                  >
                    Scenario Pain Points
                  </p>
                  <p style={{ fontSize: '13px', textAlign: 'center' }}>
                    Snap shot of the pain experienced by the key actors in the
                    scenario and what they need it to be at a minimum in order
                    to cope.
                  </p>
                  <RadarChart val={this.state.chart} graphic={this.graphic} />
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
