import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import toastr from 'toastr';
import { getToken } from '../../../../config';
import { Modal, Spinner } from 'react-bootstrap';

interface Logs {
  name: string;
  action: string;
  email: string;
  createdAt: string;
}

export function LogsModal({ display, setDisplay, showSuperModal }) {
  const [logs, setLogs] = useState<Logs[]>([]);
  const [filtro, setFiltro] = useState('');
  const [allLogs, setAllLogs] = useState<Logs[]>([]);
  const [loading, setLoading] = useState(false);

  const getLogs = () => {
    Axios.get('/api/app/admin/logs', {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        setAllLogs(response.data.logs);
        setLogs(response.data.logs);
      } else {
        toastr.remove();
        toastr.warning('Unknown error');
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (display) getLogs();
  }, [display]);

  const filtrar = (e) => {
    let value = e.target.value;
    if (value === '') {
      setFiltro(value);
      setLogs(allLogs);
    } else {
      let x: Logs[] = [];
      allLogs.forEach((element) => {
        if (
          element.name.toUpperCase().includes(value.toUpperCase()) ||
          element.email.toUpperCase().includes(value.toUpperCase()) ||
          element.action.toUpperCase().includes(value.toUpperCase())
        )
          x.push(element);
      });
      setFiltro(value);
      setLogs(x);
    }
  };

  return (
    <Modal
      show={display}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setDisplay(false);
        showSuperModal(true);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3>Logs</h3>
        </Modal.Title>
        <input
          type="text"
          className="filtroLogs"
          name="filtro"
          value={filtro}
          onChange={filtrar}
          placeholder="Search"
        />
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner
            animation="border"
            role="status"
            aria-hidden="true"
            style={{ display: 'flex', margin: 'auto' }}
          />
        ) : (
          <>
            <div className="row">
              <div className="col-3 text-center">
                <p className="font-bold">Name</p>
              </div>
              <div className="col-3 text-center">
                <p className="font-bold">Email</p>
              </div>
              <div className="col-3 text-center">
                <p className="font-bold">Action</p>
              </div>
              <div className="col-3 text-center">
                <p className="font-bold">Date</p>
              </div>
            </div>
            {logs.map((log, key) => (
              <>
                <hr className="m-0" />
                <div className="row" key={key}>
                  <div className="col-3 text-center">{log.name}</div>
                  <div className="col-3 text-center">{log.email}</div>
                  <div className="col-3 text-center">{log.action}</div>
                  <div className="col-3 text-center">{log.createdAt}</div>
                </div>
              </>
            ))}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
