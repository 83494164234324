import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';

export class Question1 extends Component {
  state = {
    edit: false,
    description: '',
    loading: true,
    nextloading: false,
    ok: true,
    error: '',
  };

  handleChange = (e) => {
    if (e.target.value.split(' ').length < 100) {
      this.setState({
        description: e.target.value,
        edit: true,
      });
    }
  };

  handleBack = () => {
    this.props.next(-1);
  };

  handleSave = () => {
    if (this.state.edit) {
      this.setState({
        nextloading: true,
      });
      const data = {
        id: this.props.id,
        description: this.state.description,
      };
      Axios.post('/api/app/quick_look/modulo4/question1', data, {
        headers: { Authorization: 'Bearer ' + getToken() },
      }).then((response) => {
        if (response.data.ok) {
          toastr.remove();
          toastr.success('Saved!');
          this.props.next(1);
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
        this.setState({
          nextloading: false,
        });
      });
    } else {
      this.props.next(1);
    }
  };

  componentDidMount() {
    this.props.changeVideo('908886184?h=02e5ca621e');
    Axios.get('/api/app/quick_look/modulo4/question1?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({ loading: false });
        if (response.data.ok) {
          this.setState({
            description: response.data.data.description,
          });
        }
      } else {
        this.setState({
          ok: false,
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.ok ? (
          this.state.loading ? (
            <div className="col-2 text-center mx-auto">
              <Spinner animation="border" variant="secondary" />
            </div>
          ) : (
            <>
              <p className="question-title">
                <b>Question 4.1</b>
              </p>
              <p className="mb-4">
                In one or two sentences, summarise a typical day in a typical
                consumer’s life that is contextual to the way that they
                experience and cope with the pain points. Define any
                concentrated hot spot of pain within the activities they perform
                and describe the customer experience and the impact on the
                business.
                <br />
                <br />
                What hurts, and why does it hurt? What does it stop them from
                doing/having/being? What do they want to do/have/be that they
                can’t? What does this root cause problem cost them?
                <br />
                <br />
                This format may be helpful to &lt;Customer description&gt; wants
                to &lt;do/have/be something&gt; but can’t because &lt;root cause
                problem&gt; prevents them. Because of that, the organisation
                cannot/does not &lt;impact description&gt;.
              </p>
              <div className="row mx-auto" style={{ width: '95%' }}>
                <textarea
                  onChange={this.handleChange}
                  defaultValue={this.state.description}
                  className="description"
                  name="description"
                ></textarea>
                {this.state.description.split(' ').length < 99 ? (
                  <small style={{ top: '80%', left: '3%' }}>
                    100 words max
                  </small>
                ) : (
                  <small style={{ top: '80%', left: '3%', color: 'red' }}>
                    100 words max
                  </small>
                )}
              </div>
              <hr />
              <div
                style={{
                  width: '100%',
                  justifyContent: 'space-between',
                  display: 'flex',
                }}
              >
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleBack}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </button>
                <button
                  className="btn my-4 p-3"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={this.handleSave}
                >
                  {this.state.nextloading ? (
                    <>
                      <Spinner animation="border" variant="light" size="sm" />{' '}
                      Saving...
                    </>
                  ) : (
                    <>
                      {this.state.edit ? 'Save & Next' : 'Next'}{' '}
                      <i className="fas fa-arrow-right"></i>{' '}
                    </>
                  )}
                </button>
              </div>
            </>
          )
        ) : (
          <>
            {this.state.error}
            <div
              style={{
                width: '100%',
                justifyContent: 'space-between',
                display: 'flex',
              }}
            >
              <button
                className="btn my-4 p-3"
                style={{
                  color: 'white',
                  backgroundColor: '#cea977',
                  borderRadius: '10px',
                }}
                onClick={this.handleBack}
              >
                <i className="fas fa-arrow-left"></i> Back
              </button>
            </div>
          </>
        )}
      </>
    );
  }
}

export default Question1;
