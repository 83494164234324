import React, { Component } from 'react';
import './BarChart.css';

export class BarChart extends Component {
  state = {
    10: 10.5,
    9: 18.8,
    8: 27.15,
    7: 35.48,
    6: 43.82,
    5: 52.15,
    4: 60.48,
    3: 68.8,
    2: 77.14,
    1: 85.4,
  };
  render() {
    return (
      <>
        <div
          className="containerBarChart row mx-auto"
          id={this.props.val.id}
          style={{ height: '132px', padding: '10px' }}
        >
          <div className="BarChartLabels p-0 text-center">
            <span
              className="span-flotante mx-auto text-left"
              style={{ width: '8.33%' }}
            >
              High Pain
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              10
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              9
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              8
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              7
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              6
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              5
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              4
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              3
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              2
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              1
            </span>
            <span
              className="span-flotante mx-auto text-left"
              style={{ width: '8.33%' }}
            >
              Low Pain
            </span>
          </div>
          <br />
          <div className="BarChartCustom col-12 p-0">
            <div className="BarChart-circle-start"></div>
            {this.props.val.data.map((arg, index) => (
              <div
                key={index}
                className="BarChart-circle"
                style={{
                  marginLeft: `${this.state[arg.score]}%`,
                  backgroundColor: arg.color,
                }}
              >
                <span
                  style={{
                    fontSize: '11px',
                    position: 'relative',
                    top: '-80%',
                    color: 'black',
                    minWidth: '100px',
                    textAlign: 'center',
                  }}
                >
                  {arg.label}
                </span>
              </div>
            ))}
            <div className="BarChart-circle-end"></div>
          </div>
          <br />
          <br />
          <br />
          <div className="BarChartLabels p-0 text-center">
            <span
              className="span-flotante mx-auto text-left"
              style={{ width: '8.33%' }}
            >
              Low Value
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              1
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              2
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              3
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              4
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              5
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              6
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              7
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              8
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              9
            </span>
            <span className="span-flotante mx-auto" style={{ width: '8.33%' }}>
              10
            </span>
            <span
              className="span-flotante mx-auto text-left"
              style={{ width: '8.33%' }}
            >
              High Value
            </span>
          </div>
        </div>
      </>
    );
  }
}

export default BarChart;
