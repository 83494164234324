import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../../components/NavBar/NavBar';
import './Quote.css';
import Footer from '../../components/Footer/Footer';
import icon1 from '../../assets/img/landing/vectores-17.png';
import icon2 from '../../assets/img/landing/vectores-18.png';
import icon3 from '../../assets/img/landing/vectores-19.png';
import icon4 from '../../assets/img/landing/vectores-20.png';
import icon5 from '../../assets/img/landing/vectores-21.png';
import icon6 from '../../assets/img/landing/vectores-22.png';

export class Quote extends Component {
  render() {
    return (
      <>
        <NavBar auth={this.props.auth} quote={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner text-light text-center superCenter">
            <h3 className="font-bold">Get a Quote</h3>
          </div>
        </div>
        <div className="row p-0 m-0 text-justify">
          <div className="col-10 mx-auto">
            <h3 className="text-center my-5">
              Ready to develop your good idea?{' '}
            </h3>
            <p>
              Then you will need to know how much it’s going to cost, how long
              it’s going to take and what’s is the best solution set to optimise
              the way we do it today, do it new, different and better and doing
              the equivariant of digital alchemy by creating high value tradable
              tokenised assets from low or no value data.
            </p>
            <p>
              You automatically get reports to download for your own use as well
              as an option to get a quote from any combination of{' '}
            </p>
          </div>
        </div>
        <div className="row p-0 mx-0 my-4 w-75 mx-auto">
          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon1}
                alt="icono1"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">Value Practitioners </p>
            </div>
            <div className="col-12">
              <p>Can do the hard work for you</p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon2}
                alt="icono2"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">Developers</p>
            </div>
            <div className="col-12">
              <p>
                Can advise and build whatever platform report indicates you need
              </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon3}
                alt="icono3"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">App design</p>
            </div>
            <div className="col-12">
              <p>Can advise your conceptual needs and create</p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon4}
                alt="icono4"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">Digital Governance Advisors</p>
            </div>
            <div className="col-12">
              <p>Can construct frameworks, Smart Contracts & more</p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon5}
                alt="icono5"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">Investors</p>
            </div>
            <div className="col-12">
              <p>Can leverage the risk mitigating work you have done </p>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 text-center">
            <div className="col-12">
              <img
                src={icon6}
                alt="icono6"
                className="mx-auto my-3"
                style={{ width: '25%' }}
              />
            </div>
            <div className="col-12">
              <p className="font-bold m-0 p-0">Analytics of what worked </p>
            </div>
            <div className="col-12">
              <p>Can compare your idea with the collective experience</p>
            </div>
          </div>
        </div>
        <div className="row p-0 mx-0 my-5 text-justify">
          <div className="col-10 my-3 mx-auto">
            <p>
              When a final report is generated for your own use at the end of
              each module just click the option you want, then we do the rest
              for you to get the response you need to get going
            </p>
          </div>
          {this.props.auth ? (
            <Link to="/send_quote" className="btn mx-auto quote-button">
              Send a Quote
            </Link>
          ) : null}
        </div>
        <Footer />
      </>
    );
  }
}

export default Quote;
