import React, { Component } from 'react';
import { getToken } from '../../../../config/index';
import Axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

export class Report extends Component {
  state = {
    actorsScenario: '',
    rolesEnterprise: '',
    rolesEcosystem: '',
    question: '',
    whoFund: '',
    loading: true,
    error: '',
  };

  componentDidMount() {
    Axios.get('/api/app/deep_dive/modulo8/report?id=' + this.props.id, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          actorsScenario: response.data.data.actorsScenario,
          rolesEnterprise: response.data.data.rolesEnterprise,
          rolesEcosystem: response.data.data.rolesEcosystem,
          whoFund: response.data.data.whoFund,
          question: response.data.data.question,
          loading: false,
        });
      } else {
        this.setState({
          error: response.data.error,
        });
      }
    });
  }

  render() {
    return (
      <>
        {this.state.error === '' ? (
          <>
            {this.props.nonext ? (
              <p className="question-title">
                <b>8. Report</b>
              </p>
            ) : null}
            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>For each of the actors from the scenario</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">{this.state.actorsScenario}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>For each of the roles of the enterprise</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">{this.state.rolesEnterprise}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>For each of the roles of the eco-system</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">{this.state.rolesEcosystem}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>For the people who will fund your good idea</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">{this.state.whoFund}</p>
                    </div>
                  </div>
                </div>
                <div className="dash-div my-3 p-4" style={{ width: '100%' }}>
                  <div className="row">
                    <div className="border col-6">
                      <p className="my-1">
                        <b>What question would they have to ask to find you?</b>
                      </p>
                    </div>
                    <div className="border col-6">
                      <p className="my-1">{this.state.question}</p>
                    </div>
                  </div>
                </div>
                {this.props.nonext ? (
                  <>
                    <hr />
                    <div
                      style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        display: 'flex',
                      }}
                    >
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleBack}
                      >
                        <i className="fas fa-arrow-left"></i> Back
                      </button>
                      <button
                        className="btn my-4 p-3"
                        style={{
                          color: 'white',
                          backgroundColor: '#cea977',
                          borderRadius: '10px',
                        }}
                        onClick={this.handleNext}
                      >
                        Next <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </>
        ) : (
          this.state.error
        )}
      </>
    );
  }
}

export default Report;
