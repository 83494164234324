import React, { Component } from 'react';
import { Nav, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getToken } from '../../config/index';
import Logo from '../../assets/img/landing/vectores-07.png';
import Axios from 'axios';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import './recoverPass.css';

export class RecoverPass extends Component {
  state = {
    loading: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    Axios.post(
      '/api/resetPass',
      { email: e.target.email.value },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    ).then((response) => {
      if (response.data.ok) {
        toastr.remove();
        toastr.success('Email sent, chek your inbox');
        this.setState({ loading: false });
      } else {
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  };

  render() {
    return (
      <div style={{ height: '100vh' }}>
        <div className="border-shadow" style={{ height: '70px' }}>
          <Nav
            className="justify-content-end align-items-center"
            style={{ height: '70px' }}
          >
            <Nav.Item className="ml-4 mr-auto">
              <img src={Logo} alt="" className="navbar-logo" />
            </Nav.Item>
            <Nav.Item>
              <Link
                className={`superCenter mr-5 text-center nav-link ${
                  this.props.home ? 'active' : ''
                }`}
                to="/"
                style={{ height: '70px' }}
              >
                Home
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="create2fa-section text-center">
          <h3 className="font-bold my-5" style={{ color: '#cea977' }}>
            Reset password
          </h3>
          <Form className="form" onSubmit={this.handleSubmit}>
            <Form.Group>
              <input
                className="form-control recover-input mx-auto"
                type="email"
                id="email"
                placeholder="E-mail"
              />
            </Form.Group>
            <div className="submit-button">
              <button className="btn recover-button" type="submit">
                {this.state.loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  'Recover'
                )}
              </button>
            </div>
          </Form>
          <br />
          <p>
            Don't have an account?{' '}
            <Link className="recover-link font-bold" to="/register">
              Register here
            </Link>
          </p>
          <p>
            You have an account?{' '}
            <Link className="recover-link font-bold" to="/login">
              Login here
            </Link>
          </p>
        </div>
      </div>
    );
  }
}

export default RecoverPass;
