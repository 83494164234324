import React, { Component } from 'react';
import { Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import NavBar from '../../../components/NavBar/NavBar.js';
import Project from './SingleProject.js';
import Axios from 'axios';
import './projects.css';
import toastr from 'toastr';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { selectStyle, getToken } from '../../../config/index';

import M1ReportE from '../../../components/Modules/QuickLook/Module1/enterprise/QuickReport.js';
import M1ReportS from '../../../components/Modules/QuickLook/Module1/startup/QuickReport.js';
import M2Report from '../../../components/Modules/QuickLook/Module2/QuickReport.js';
import M3Report from '../../../components/Modules/QuickLook/Module3/QuickReport.js';
import M4Report from '../../../components/Modules/QuickLook/Module4/QuickReport.js';
import M5Report from '../../../components/Modules/QuickLook/Module5/QuickReport.js';
import M6Report from '../../../components/Modules/QuickLook/Module6/QuickReport.js';
import M7Report from '../../../components/Modules/QuickLook/Module7/QuickReport.js';
import { GrantUserAccess } from '../../../components/GrantAccessModal.tsx';

export class Projects extends Component {
  state = {
    projects: [],
    allProjects: [],
    otherProjects: [],
    projectFilter: '',
    logs: [],
    allLogs: [],
    payments: [],
    paymentDetailed: {},
    allPayments: [],
    allOrganizations: [],
    organizations: [],
    quickProjects: [],
    quickUsers: [],
    filtro: '',
    filtroPayments: '',
    userSelect: '',
    projectSelect: '',
    moduleSelect: '',
    report: '',
    showNewProjectModal: false,
    showGrantAccessModal: false,
    showLogsModal: false,
    showPaymentsModal: false,
    showPaymentDetail: false,
    showQuickView: false,
    showOrganizationModal: false,
    showOrganizationCreateModal: false,
    showSuperModal: false,
    loadingLogs: true,
    loadingPayments: true,
    loadingOrganization: true,
    loadingOrganizationCreate: false,
    loadingQuick: true,
    loading: true,
    createLoading: false,
    authLoading: false,
    user: 'user',
  };

  handleSuperModal = (value) => {
    this.setState({
      showSuperModal: value,
    });
  };

  handleQuickView = (value) => {
    this.setState(
      {
        showQuickView: value,
        showSuperModal: !value,
      },
      () => {
        if (value) {
          this.setState({
            loadingQuick: false,
          });
        } else {
          this.setState({
            loadingQuick: true,
          });
        }
      },
    );
  };

  handleNewProjectModal = (value) => {
    this.setState({
      showNewProjectModal: value,
    });
  };

  handleSignUpModal = (value) => {
    this.setState({
      showGrantAccessModal: value,
      showSuperModal: !value,
    });
  };

  handlePaymentDetail = (value, payment) => {
    this.setState({
      showPaymentDetail: value,
      paymentDetailed: payment,
    });
  };

  handlePaymentsModal = (value) => {
    this.setState(
      {
        showPaymentsModal: value,
        showSuperModal: !value,
      },
      () => {
        if (value) {
          Axios.get('/api/app/admin/payments', {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                allpayments: response.data.payments,
                payments: response.data.payments,
                loadingPayments: false,
              });
            } else {
              toastr.remove();
              toastr.warning('Unknown error');
            }
          });
        } else {
          this.setState({
            loadingPayments: true,
            payments: [],
          });
        }
      },
    );
  };

  handleLogsModal = (value) => {
    this.setState(
      {
        showLogsModal: value,
        showSuperModal: !value,
      },
      () => {
        if (value) {
          Axios.get('/api/app/admin/logs', {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                allLogs: response.data.logs,
                logs: response.data.logs,
                loadingLogs: false,
              });
            } else {
              toastr.remove();
              toastr.warning('Unknown error');
            }
          });
        } else {
          this.setState({
            loadingLogs: true,
            logs: [],
          });
        }
      },
    );
  };

  handleOrganization = (value) => {
    this.setState(
      {
        showOrganizationModal: value,
        showSuperModal: !value,
      },
      () => {
        if (value) {
          Axios.get('/api/app/admin/getOrganizations', {
            headers: { Authorization: 'Bearer ' + getToken() },
          }).then((response) => {
            if (response.data.ok) {
              this.setState({
                allOrganizations: response.data.organizations,
                organizations: response.data.organizations,
                loadingOrganization: false,
              });
            } else {
              toastr.remove();
              toastr.warning('Unknown error');
            }
          });
        } else {
          this.setState({
            loadingOrganization: true,
            organizations: [],
          });
        }
      },
    );
  };

  handleOrganizationCreate = (value) => {
    this.setState({
      showOrganizationCreateModal: value,
      showOrganizationModal: !value,
    });
  };

  handleSubmitOrganizationCreate = (e) => {
    e.preventDefault();
    this.setState({
      loadingOrganizationCreate: true,
    });
    if (e.target.responsableName.value.includes(' ')) {
      toastr.remove();
      toastr.warning("Username can't contain blanks");
      this.setState({ loadingOrganizationCreate: false });
    } else {
      if (
        e.target.responsableName.value === '' ||
        e.target.responsableEmail.value === '' ||
        e.target.organizationName.value === ''
      ) {
        toastr.remove();
        toastr.warning('Please complete all the fields');
        this.setState({ loadingOrganizationCreate: false });
      } else {
        let data = {
          username: e.target.responsableName.value,
          email: e.target.responsableEmail.value,
          organizationName: e.target.organizationName.value,
          link: window.location.origin,
        };
        Axios.post('/api/app/admin/setOrganization', data, {
          headers: { Authorization: 'Bearer ' + getToken() },
        })
          .then((response) => {
            if (response.data.ok) {
              toastr.remove();
              toastr.success('Created!');
              this.setState({
                loadingOrganizationCreate: false,
                showOrganizationCreateModal: false,
              });
              this.handleOrganization(true);
            } else {
              toastr.remove();
              toastr.warning(response.data.error);
              this.setState({ loadingOrganizationCreate: false });
            }
          })
          .catch((err) => {
            this.setState({ loadingOrganizationCreate: false });
            toastr.remove();
            toastr.warning(err.response.data.error);
          });
      }
    }
  };

  handleActiveOrganization = (id, value) => {
    let data = {
      id,
      active: value,
    };
    Axios.post('/api/app/admin/activeOrganization', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    })
      .then((response) => {
        if (response.data.ok) {
          toastr.remove();
          if (value) toastr.success('Activated!');
          else toastr.success('Deactivated!');
          this.setState({
            allOrganizations: response.data.organizations,
            organizations: response.data.organizations,
          });
        } else {
          toastr.remove();
          toastr.warning(response.data.error);
        }
      })
      .catch((error) => {
        toastr.remove();
        toastr.warning(error.response.data.error);
      });
  };

  handleChangeSelectUser = (item) => {
    let quickProjects = [];
    this.state.allProjects.forEach((element) => {
      if (element.project.ownerID === item.value) {
        quickProjects.push({
          label: element.project.name,
          value: element.project._id,
          startup: element.project.startup,
        });
      }
    });
    this.setState({
      userSelect: item,
      projectSelect: '',
      moduleSelect: '',
      report: '',
      quickProjects,
    });
  };

  handleChangeSelectProject = (item) => {
    this.setState({
      projectSelect: item,
      moduleSelect: '',
      report: '',
    });
  };

  handleChangeSelectModule = (item) => {
    this.setState(
      {
        moduleSelect: item,
        report: '',
      },
      () => {
        if (item.value === 'Module 1') {
          if (this.state.projectSelect.startup) {
            this.setState({
              report: (
                <M1ReportS nonext={false} id={this.state.projectSelect.value} />
              ),
            });
          } else {
            this.setState({
              report: (
                <M1ReportE nonext={false} id={this.state.projectSelect.value} />
              ),
            });
          }
        }
        if (item.value === 'Module 2')
          this.setState({
            report: (
              <M2Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
        if (item.value === 'Module 3')
          this.setState({
            report: (
              <M3Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
        if (item.value === 'Module 4')
          this.setState({
            report: (
              <M4Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
        if (item.value === 'Module 5')
          this.setState({
            report: (
              <M5Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
        if (item.value === 'Module 6')
          this.setState({
            report: (
              <M6Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
        if (item.value === 'Module 7')
          this.setState({
            report: (
              <M7Report nonext={false} id={this.state.projectSelect.value} />
            ),
          });
      },
    );
  };

  filtrar = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        filtro: value,
        logs: this.state.allLogs,
      });
    } else {
      let x = [];
      this.state.allLogs.forEach((element) => {
        if (
          element.name.toUpperCase().includes(value.toUpperCase()) ||
          element.email.toUpperCase().includes(value.toUpperCase()) ||
          element.action.toUpperCase().includes(value.toUpperCase())
        )
          x.push(element);
      });
      this.setState({
        filtro: value,
        logs: x,
      });
    }
  };

  filtrarPayments = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        filtroPayments: value,
        payments: this.state.allPayments,
      });
    } else {
      let x = [];
      this.state.allPayments.forEach((element) => {
        if (
          element.name.toUpperCase().includes(value.toUpperCase()) ||
          element.email.toUpperCase().includes(value.toUpperCase()) ||
          element.action.toUpperCase().includes(value.toUpperCase())
        )
          x.push(element);
      });
      this.setState({
        filtroPayments: value,
        payments: x,
      });
    }
  };

  handleSubmitNewProject = (e) => {
    e.preventDefault();
    this.setState({
      createLoading: true,
    });
    const data = {
      title: e.target.title.value,
      description: e.target.description.value,
    };
    Axios.post('/api/app/quick_look/newProject', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          projects: response.data.projects,
          showNewProjectModal: false,
          createLoading: false,
        });
      } else {
        this.setState({ createLoading: false });
        toastr.remove();
        toastr.warning('Incomplete data');
      }
    });
  };

  handleSubmitSignUp = (e) => {
    e.preventDefault();
    this.setState({
      authLoading: true,
    });
    const data = {
      email: e.target.email.value,
    };
    Axios.post('/api/app/admin/grantUser', data, {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        this.setState({
          showGrantAccessModal: false,
          showSuperModal: true,
          authLoading: false,
        });
        toastr.remove();
        toastr.success('Permission Granted!');
      } else {
        this.setState({ authLoading: false });
        toastr.remove();
        toastr.warning(response.data.error);
      }
    });
  };

  handleRemove = (id) => {
    Axios.post(
      '/api/app/quick_look/deleteProject',
      { id },
      { headers: { Authorization: 'Bearer ' + getToken() } },
    ).then((response) => {
      if (response.data.ok) {
        this.setState({ projects: response.data.projects });
      } else {
        toastr.remove();
        toastr.warning('Unknown error');
      }
    });
  };

  projectFilter = (e) => {
    let filtro = e.target.value;
    this.setState(
      {
        [e.target.name]: filtro,
      },
      () => {
        let p = [];
        if (filtro !== '') {
          this.state.allProjects.forEach((element) => {
            if (
              element.project.description
                .toLowerCase()
                .includes(filtro.toLowerCase()) ||
              element.project.name
                .toLowerCase()
                .includes(filtro.toLowerCase()) ||
              element.user.name.toLowerCase().includes(filtro.toLowerCase()) ||
              element.user.email.toLowerCase().includes(filtro.toLowerCase())
            ) {
              p.push(element);
            }
          });
          this.setState({
            otherProjects: p,
          });
        } else {
          this.setState({
            otherProjects: this.state.allProjects,
          });
        }
      },
    );
  };

  componentDidMount() {
    Axios.get('/api/app/quick_look/myProjects', {
      headers: { Authorization: 'Bearer ' + getToken() },
    }).then((response) => {
      if (response.data.ok) {
        if (response.data.user === 'admin') {
          let quickUsers = [];
          let quickProjects = [];
          response.data.allProjects.forEach((element) => {
            quickProjects.push(element.project);
            let esta = false;
            quickUsers.forEach((e) => {
              if (e.value === element.user.id) {
                esta = true;
              }
            });
            if (!esta)
              quickUsers.push({
                label: `${element.user.username} / ${element.user.email}`,
                value: element.user.id,
              });
          });
          this.setState({
            projects: response.data.projects,
            allProjects: response.data.allProjects,
            otherProjects: response.data.allProjects,
            loading: false,
            user: response.data.user,
            quickProjects,
            quickUsers,
          });
        } else {
          this.setState({
            projects: response.data.projects,
            loading: false,
            user: response.data.user,
          });
        }
      } else {
        toastr.remove();
        toastr.warning('Error while getting projects');
      }
    });
  }

  render() {
    return (
      <>
        <NavBar auth={true} quicklook={true} />
        <div className="row p-0 m-0">
          <div className="mini-banner"></div>
        </div>
        <div className="problem-projects">
          <div className="problem-sidebar">
            <div className="font-bold superCenter problem-title">
              Quick Look
            </div>
            <div className="problem-options">
              <button className="accordion-btn" style={{ color: 'black' }}>
                <Link
                  to="/quick_look/projects"
                  className="problem-options-font"
                  style={{ color: 'black' }}
                >
                  <i className="fas fa-tasks mr-2"></i>Projects
                </Link>
              </button>
              <button className="accordion-btn">
                <Link
                  to="/quick_look/statistics"
                  className="problem-options-font"
                >
                  <i className="far fa-chart-bar mr-2"></i> Statistic
                </Link>
              </button>
              {/* <button className="accordion-btn problem-options-font">
                <Link to="" className="problem-options-font">
                  <i className="fas fa-user-alt mr-2"></i> My Profile
                </Link>
              </button> */}
            </div>
          </div>

          <div className="problem-main">
            <Row className="mb-3 mx-0">
              {window.innerWidth < 767 && (
                <p style={{ color: 'red', textAlign: 'center' }}>
                  Projects are not optimized to work properly on phones, use
                  this module on your desktop or tablet.
                </p>
              )}
              <Col md={6}>
                <p className="mb-1">
                  <b>Projects</b>
                </p>
                <p>Open an existing project or start a new one</p>
              </Col>
              <Col md={3} className="my-auto p-0 text-center">
                {this.state.user === 'admin' ? (
                  <>
                    <button
                      className="btn mx-auto"
                      style={{
                        color: 'white',
                        backgroundColor: '#555',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleSuperModal(true);
                      }}
                    >
                      <i
                        className="fas fa-clipboard-list mr-1"
                        style={{ fontSize: '13px' }}
                      ></i>
                      <b>Admin Area</b>
                    </button>
                  </>
                ) : null}
              </Col>
              <Col md={3} className="my-auto p-0">
                <span
                  className="btn mx-auto"
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    float: 'right',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'white',
                    backgroundColor: '#555',
                    borderRadius: '10px',
                  }}
                  onClick={() => {
                    this.handleNewProjectModal(true);
                  }}
                >
                  <i
                    className="fas fa-plus mr-1"
                    style={{ fontSize: '13px' }}
                  ></i>
                  <b>New Project</b>
                </span>
              </Col>
            </Row>
            {this.state.loading ? (
              <div className="col-2 text-center mx-auto">
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              <>
                <p>
                  <b>My Projects:</b>
                </p>
                {this.state.projects.map((project, key) => {
                  return (
                    <Project
                      remove={this.handleRemove}
                      id={project._id}
                      name={project.name}
                      description={project.description}
                      key={key}
                    />
                  );
                })}
                {this.state.user === 'admin' ? (
                  <>
                    <hr style={{ marginTop: '10px', marginBottom: '10px' }} />
                    <div className="row mx-0">
                      <div
                        className="col-3 p-0"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <p className="m-0">
                          <b>All Projects:</b>
                        </p>
                      </div>
                      <div className="col-6">
                        <input
                          placeholder="Search"
                          className="form-control mx-auto"
                          style={{ width: '50%' }}
                          name="projectFilter"
                          value={this.state.projectFilter}
                          onChange={this.projectFilter}
                        ></input>
                      </div>
                    </div>
                    {this.state.otherProjects.map((element, key) => {
                      return (
                        <Project
                          remove={this.handleRemove}
                          user={element.user}
                          id={element.project._id}
                          name={element.project.name}
                          description={element.project.description}
                          key={key}
                        />
                      );
                    })}
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>

        {/* NEW PROJECT */}
        <Modal
          show={this.state.showNewProjectModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => {
            this.handleNewProjectModal(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <h3>Create new project</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={this.handleSubmitNewProject}>
              <Form.Group>
                <input
                  className="form-control"
                  type="text"
                  name="title"
                  id="title"
                  placeholder="Title"
                />
                <input
                  className="form-control"
                  type="text"
                  name="description"
                  placeholder="Description"
                />
              </Form.Group>
              <div className="submit-button text-center">
                <Button type="submit" className="btn btn-primary btn-custom">
                  {this.state.createLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Create'
                  )}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        {/* NEW PROJECT */}

        {this.state.user === 'admin' ? (
          <>
            {/* SUPER MODAL */}
            <Modal
              show={this.state.showSuperModal}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handleSuperModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Admin Area</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-12 d-flex">
                    <button
                      className="btn ml-auto mr-1 my-2"
                      style={{
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleLogsModal(true);
                      }}
                    >
                      <i
                        className="fas fa-clipboard-list mr-1"
                        style={{ fontSize: '13px' }}
                      ></i>{' '}
                      <b>Logs</b>
                    </button>
                    <button
                      className="btn mr-auto ml-1 my-2"
                      style={{
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handlePaymentsModal(true);
                      }}
                    >
                      <i
                        className="fas fa-clipboard-list mr-1"
                        style={{ fontSize: '13px' }}
                      ></i>{' '}
                      <b>Payments</b>
                    </button>
                  </div>
                  <div className="col-12 d-flex">
                    <button
                      className="btn mx-auto my-2"
                      style={{
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleSignUpModal(true);
                      }}
                    >
                      <i
                        className="fas fa-user-lock mr-1"
                        style={{ fontSize: '13px' }}
                      ></i>
                      <b>Grant User Access</b>
                    </button>
                  </div>
                  <div className="col-12 d-flex">
                    <button
                      className="btn mx-auto my-2"
                      style={{
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleQuickView(true);
                      }}
                    >
                      <i
                        className="fas fa-tasks text-light"
                        style={{ fontSize: '13px' }}
                      ></i>{' '}
                      <b>Quick look to a project</b>
                    </button>
                  </div>
                  <div className="col-12 d-flex">
                    <button
                      className="btn mx-auto my-2"
                      style={{
                        color: 'white',
                        backgroundColor: '#cea977',
                        borderRadius: '10px',
                      }}
                      onClick={() => {
                        this.handleOrganization(true);
                      }}
                    >
                      <i
                        className="fas fa-building text-light"
                        style={{ fontSize: '13px' }}
                      ></i>{' '}
                      <b>Register an Organization</b>
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            {/* SUPER MODAL */}

            {/* QUICK VIEW */}
            <Modal
              show={this.state.showQuickView}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handleQuickView(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Take a look</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingQuick ? (
                  <Spinner
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={{ display: 'flex', margin: 'auto' }}
                  />
                ) : (
                  <>
                    <Select
                      value={
                        this.state.userSelect === ''
                          ? ''
                          : this.state.userSelect
                      }
                      onChange={(e) => this.handleChangeSelectUser(e)}
                      options={this.state.quickUsers}
                      styles={selectStyle}
                      placeholder="Select an user"
                    />
                    <Select
                      value={
                        this.state.projectSelect === ''
                          ? ''
                          : this.state.projectSelect
                      }
                      onChange={(e) => this.handleChangeSelectProject(e)}
                      options={this.state.quickProjects}
                      styles={selectStyle}
                      placeholder="Select project"
                    />
                    {this.state.projectSelect === '' ? null : (
                      <Select
                        value={
                          this.state.moduleSelect === ''
                            ? ''
                            : this.state.moduleSelect
                        }
                        onChange={(e) => this.handleChangeSelectModule(e)}
                        options={[
                          { label: 'Module 1', value: 'Module 1' },
                          { label: 'Module 2', value: 'Module 2' },
                          { label: 'Module 3', value: 'Module 3' },
                          { label: 'Module 4', value: 'Module 4' },
                          { label: 'Module 5', value: 'Module 5' },
                          { label: 'Module 6', value: 'Module 6' },
                          { label: 'Module 7', value: 'Module 7' },
                          { label: 'Module 8', value: 'Module 8' },
                        ]}
                        styles={selectStyle}
                        placeholder="Select module"
                      />
                    )}
                    {this.state.moduleSelect === '' ? null : this.state.report}
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* QUICK VIEW */}
            {/* GRANT USER ACCESS */}
            <GrantUserAccess
              display={this.state.showGrantAccessModal}
              setDisplay={(value) => this.handleSignUpModal(value)}
              showSuperModal={(value) => this.handleSuperModal(value)}
              module="quick_look"
            />
            {/* GRANT USER ACCESS */}

            {/* LOGS */}
            <Modal
              show={this.state.showLogsModal}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handleLogsModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Logs</h3>
                </Modal.Title>
                <input
                  type="text"
                  className="filtroLogs"
                  name="filtro"
                  value={this.state.filtro}
                  onChange={this.filtrar}
                  placeholder="Search"
                />
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingLogs ? (
                  <Spinner
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={{ display: 'flex', margin: 'auto' }}
                  />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-3 text-center">
                        <p className="font-bold">Name</p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="font-bold">Email</p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="font-bold">Action</p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="font-bold">Date</p>
                      </div>
                    </div>
                    {this.state.logs.map((log, key) => (
                      <>
                        <hr className="m-0" />
                        <div className="row" key={key}>
                          <div className="col-3 text-center">{log.name}</div>
                          <div className="col-3 text-center">{log.email}</div>
                          <div className="col-3 text-center">{log.action}</div>
                          <div className="col-3 text-center">
                            {log.createdAt}
                          </div>
                        </div>
                      </>
                    ))}
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* LOGS */}

            {/* PAYMENTS */}
            <Modal
              show={this.state.showPaymentsModal}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handlePaymentsModal(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Payments</h3>
                </Modal.Title>
                <input
                  type="text"
                  className="filtroLogs"
                  name="filtroPayments"
                  value={this.state.filtroPayments}
                  onChange={this.filtrarPayments}
                  placeholder="Search"
                />
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingPayments ? (
                  <Spinner
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={{ display: 'flex', margin: 'auto' }}
                  />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-2 text-center">
                        <p className="font-bold">Username</p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="font-bold">Email</p>
                      </div>
                      <div className="col-3 text-center">
                        <p className="font-bold">Transaction ID</p>
                      </div>
                      <div className="col-1 text-center">
                        <p className="font-bold">Method</p>
                      </div>
                      <div className="col-1 text-center">
                        <p className="font-bold">Status</p>
                      </div>
                      <div className="col-2 text-center">
                        <p className="font-bold">Date</p>
                      </div>
                    </div>
                    {this.state.payments.map((payment, key) => (
                      <React.Fragment key={key}>
                        <hr className="m-0" />
                        <div className="row" key={key}>
                          <div className="col-2 text-center">
                            {payment.user.username}
                          </div>
                          <div className="col-3 text-center">
                            {payment.user.email}
                          </div>
                          <div
                            className="col-3 text-center"
                            style={{
                              wordBreak: 'break-all',
                              textDecoration: 'underline',
                              color: 'blue',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              this.handlePaymentDetail(true, payment);
                            }}
                          >
                            {payment.transactionID}
                          </div>
                          <div className="col-1 text-center">
                            {payment.method}
                          </div>
                          <div className="col-1 text-center">
                            {payment.state}
                          </div>
                          <div className="col-2 text-center">
                            {payment.createdAt}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* PAYMENTS */}

            {/* PAYMENT DETAIL */}
            <Modal
              show={this.state.showPaymentDetail}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handlePaymentDetail(false, {});
              }}
            >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <span className="d-block">
                  <span className="font-bold">- Transaction ID:</span>{' '}
                  {this?.state?.paymentDetailed?.transactionID}
                </span>
                <span className="d-block">
                  <span className="font-bold">- Method:</span>{' '}
                  {this?.state?.paymentDetailed?.method}
                </span>
                <span className="d-block">
                  <span className="font-bold">- Status:</span>{' '}
                  {this?.state?.paymentDetailed?.state}
                </span>
                <span className="d-block">
                  <span className="font-bold">- Date:</span>{' '}
                  {this?.state?.paymentDetailed?.createdAt}
                </span>
                <span className="d-block">
                  <span className="font-bold">- Username:</span>{' '}
                  {this?.state?.paymentDetailed?.user?.username}
                </span>
                <span className="d-block">
                  <span className="font-bold">- Email:</span>{' '}
                  {this?.state?.paymentDetailed?.user?.email}
                </span>
                {this?.state?.paymentDetailed?.method === 'Stripe' ? (
                  <>
                    <span className="d-block">
                      <span className="font-bold">- Payer:</span>
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Payment Method:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.payer?.payment_method}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Payer ID:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.payer_id
                      }
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Email:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.payer?.payer_info?.email}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Country:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.country_code
                      }
                    </span>
                    <span className="d-block">
                      <span className="font-bold">- Card:</span>
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Brand:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.brand}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        CVC Check:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.checks?.cvc_check}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Country:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.country}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Exp Month:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.exp_month}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Exp Year:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.exp_year}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Last 4 digits:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.card?.last4}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="d-block">
                      <span className="font-bold">- Payer:</span>
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Payment Method:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.payer?.payment_method}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Status:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.payer?.status}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Payer ID:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.payer_id
                      }
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        First Name:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.first_name
                      }
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Last Name:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.last_name
                      }
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Email:
                      </span>{' '}
                      {this?.state?.paymentDetailed?.payer?.payer_info?.email}
                    </span>
                    <span className="d-block">
                      <span
                        className="font-bold"
                        style={{ marginLeft: '30px' }}
                      >
                        Country:
                      </span>{' '}
                      {
                        this?.state?.paymentDetailed?.payer?.payer_info
                          ?.country_code
                      }
                    </span>
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* PAYMENT DETAIL */}

            {/* ORGANIZATIONS */}
            <Modal
              show={this.state.showOrganizationModal}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handleOrganization(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Organizations</h3>
                </Modal.Title>
                {/* <input type="text" className='filtroLogs' name="filtro" value={this.state.filtro} onChange={this.filtrar} placeholder='Search'/>  */}
                <button
                  className="btn ml-auto"
                  style={{
                    color: 'white',
                    backgroundColor: '#cea977',
                    borderRadius: '10px',
                  }}
                  onClick={() => {
                    this.handleOrganizationCreate(true);
                  }}
                >
                  <i
                    className="fas fa-plus mr-1"
                    style={{ fontSize: '13px' }}
                  ></i>
                  <b>Add new</b>
                </button>
              </Modal.Header>
              <Modal.Body>
                {this.state.loadingOrganization ? (
                  <Spinner
                    animation="border"
                    role="status"
                    aria-hidden="true"
                    style={{ display: 'flex', margin: 'auto' }}
                  />
                ) : (
                  <>
                    <div className="row">
                      <div className="col-2 text-center">
                        <p className="font-bold" style={{ margin: '0px' }}>
                          Name
                        </p>
                      </div>
                      <div className="col-4 text-center">
                        <p className="font-bold" style={{ margin: '0px' }}>
                          Responsable
                        </p>
                      </div>
                      <div className="col-5 text-center">
                        <p className="font-bold" style={{ margin: '0px' }}>
                          Link
                        </p>
                      </div>
                      <div className="col-1 text-center">
                        <p className="font-bold" style={{ margin: '0px' }}>
                          Active
                        </p>
                      </div>
                    </div>
                    {this.state.organizations.map((org, key) => (
                      <React.Fragment key={key}>
                        <hr className="m-0" />
                        <div className="row my-2" key={key}>
                          <div
                            className="col-2 text-center superCenter"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {org.organizationName}
                          </div>
                          <div
                            className="col-4 text-center superCenter"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {org.username} / {org.email}
                          </div>
                          <div
                            className="col-5 text-center superCenter"
                            style={{
                              wordBreak: 'break-all',
                              color: 'blue',
                              textDecoration: 'underline',
                            }}
                          >
                            {org.link}
                          </div>
                          <div
                            className="col-1 text-center superCenter"
                            style={{ wordBreak: 'break-all' }}
                          >
                            <label className="switch mb-0">
                              <input
                                type="checkbox"
                                checked={org.active}
                                onChange={() =>
                                  this.handleActiveOrganization(
                                    org._id,
                                    !org.active,
                                  )
                                }
                              />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Modal.Body>
            </Modal>
            {/* ORGANIZATIONS */}
            {/* CREATE ORGANIZATIONS */}
            <Modal
              show={this.state.showOrganizationCreateModal}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={() => {
                this.handleOrganizationCreate(false);
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <h3>Add new Organization</h3>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={this.handleSubmitOrganizationCreate}>
                  <Form.Group>
                    <input
                      className="form-control"
                      type="text"
                      name="organizationName"
                      placeholder="Organization Name"
                    />
                    <input
                      className="form-control"
                      type="text"
                      name="responsableName"
                      placeholder="Responsable Username"
                    />
                    <input
                      className="form-control"
                      type="email"
                      name="responsableEmail"
                      placeholder="Responsable Email"
                    />
                  </Form.Group>
                  <div className="submit-button text-center">
                    <Button
                      type="submit"
                      className="btn btn-primary btn-custom"
                    >
                      {this.state.loadingOrganizationCreate ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Create'
                      )}
                    </Button>
                  </div>
                </Form>
              </Modal.Body>
            </Modal>
            {/* CREATE ORGANIZATIONS */}
          </>
        ) : null}
      </>
    );
  }
}

export default Projects;
